import { Component, OnInit, Input, OnChanges, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from 'rxjs';
import { ToastrService } from "ngx-toastr";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from "../../shared/service/common/common.service";
import * as _ from "underscore";
import { Common } from "../../shared/service/common/common";
import { DatePipe } from '@angular/common';
import { DataService } from '../../shared/service/data.service';
import { ReadingDataService } from '../../shared/service/reading-data.service';
import { AppHelper } from 'src/app/shared/helper/app.helper';
import { AppConstant } from 'src/app/shared/constant/app-constant';
import * as moment from 'moment';


@Component({
  selector: "app-final",
  templateUrl: "./final.component.html",
  styleUrls: ["./final.component.css"],
  providers: [NgbModalConfig, NgbModal, DatePipe]
})
export class FinalComponent implements OnInit {
  babyFinalForm: FormGroup;
  formRef: any;
  submitted = false;
  already_exist_status = 422;
  success_status = 200;
  isFinalEdit = true;
  responseArray = [];
  page: number = 1;
  chkBabyDischargeDate: boolean = true;
  chkDaysOfStayHospital: boolean = true;
  chkDischargeStatus: boolean = true;
  chkFinalDiagnosisMeningitis: boolean = true;
  chkFinalDiagnosisSepsis: boolean = true;
  chkFinalDiagnosisSeizures: boolean = true;
  chkFinalDiagnosisRds: boolean = true;
  chkFinalDiagnosisTtnb: boolean = true;
  chkFinalDiagnosisJaundice: boolean = true;
  chkFinalDiagnosisLBW: boolean = true;
  chkFinalLgaSgaAgaSuspect: boolean = true;
  chkFinalDiagnosisAnemia: boolean = true;
  chkFinalDiagnosisdextochordia: boolean = true;
  chkFinalDiagnosisHypoglycemia: boolean = true;
  chkFinalDiagnosisHypocalcemia: boolean = true;
  chkFinalDiagnosisGastroenteritis: boolean = true;
  chkFinalDiagnosisPerinatalRespiratoryDepression: boolean = true;
  chkFinalDiagnosisShock: boolean = true;
  chkFinalDiagnosisFeedingIntolerence: boolean = true;
  chkFinalDiagnosisSepticArthritis: boolean = true;
  chkFinalDiagnosisEndocarditis: boolean = true;
  chkFinalDiagnosisPeritonitis: boolean = true;
  chkFinalDiagnosisSoftTissueAbscess: boolean = true;
  chkFinalDiagnosisCoagulopathy: boolean = true;
  chkFinalDiagnosisUti: boolean = true;
  chkFinalDiagnosisUmblicalSepsis: boolean = true;
  chkFinalDiagnosisBleedingManifestation: boolean = true;
  chkFinalDiagnosisAsphyxia: boolean = true;
  chkFinalDiagnosisPneumonia: boolean = true;
  chkFinalDiagnosisCentralPeripheral: boolean = true;
  chkFinalDiagnosisHypoxia: boolean = true;
  chkFinalDiagnosisMetabolicAcidosis: boolean = true;
  chkFinalDiagnosisEosLOs: boolean = true;
  chkFinalDiagnosisPulmonaryHemorrhage: boolean = true;
  chkFinalDiagnosisThrombocytopenia: boolean = true;
  chkFinalDiagnosisSkinPustules: boolean = true;
  ifFinalDiagnosisOther: boolean = true;
  isEditClicked: boolean = false;
  invalidForm=false;
  readingData;
  allFormData:any;
  messageString:string;
  @Input() id;
  @Input() hospital_id;

  getDOA: any;
  getDOA1: any;
  todayDate: Date;

  temp_study_id = 0;
  subscription: Subscription;
  subscriptionForMR: Subscription;
  loggedInUserId:number;
  getMedicalRecordNumber: string;
  login_hospital: any = {};
  content:any;
  public dataServiceObj;
  public readingDataObj;
  phcUser=false;
  @ViewChild('saveReadingContent') saveReadingContent;

  public customPatterns = { 'S': { pattern: new RegExp('\[a-zA-Z,/\]') } };
  public specialCharPatterns = { 'S': { pattern: new RegExp('\[a-zA-Z, /\]') } };

  
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private common_api: CommonService,
    private modalService: NgbModal,
    private commonAsyn: Common,
    private datePipe: DatePipe,
    private dataService: DataService,
    public readingDataService:ReadingDataService,private helper:AppHelper,private constant:AppConstant
    
  ) {
    this.dataServiceObj = dataService.getOption();
    
    this.todayDate = new Date();
  }

  transformDate(date) {
    if (Object.prototype.toString.call(date['baby_discharge_date']) === "[object Date]") {
      date['baby_discharge_date'] = this.datePipe.transform(date['baby_discharge_date'], 'dd/MM/yyyy');
    }
  }

  ngOnInit() {
    const vim = this;
    vim.dataServiceObj = vim.dataService.getOption();
    vim.readingDataObj=vim.readingDataService.getReadingFormData('baby_final') ;
    vim.login_hospital = JSON.parse(localStorage.getItem("login_hospital"));
    vim.loggedInUserId=vim.login_hospital['user_id'];
    vim.checkUser();
    vim.id = vim.dataServiceObj.study_id;
    vim.createForm(vim.dataServiceObj.study_id);
    
    this.getDOA = vim.dataServiceObj.baby_date_of_admission
    this.getDOA1 = moment(vim.dataServiceObj.baby_date_of_admission).format('DD/MM/YYYY');
    this.getDOA1 = new Date(this.getDOA1);
    if(vim.readingDataObj!=undefined){
      
      //this.getDOA = (vim.dataServiceObj.baby_date_of_admission);
      vim.getMedicalRecordNumber=vim.dataServiceObj.baby_medical_record_number;
      vim.getReadingFormData(this.readingDataObj);
      //console.log(this.getDOA);
  }
  else{
    if ( vim.dataServiceObj.study_id != undefined) {
      //console.log(vim.dataServiceObj)
      //this.getDOA = vim.dataServiceObj.baby_date_of_admission
      vim.getMedicalRecordNumber=vim.dataServiceObj.baby_medical_record_number;
      vim.get_final(vim.dataServiceObj.study_id, vim.login_hospital['id'], vim.page, vim.readingDataService.reading);
      //console.log('>>>>>>>>>>>>>>>>', this.getDOA )
    }

}
    vim.temp_study_id = vim.id;
    vim.onChanges();
  }

  checkUser(){
    if(this.login_hospital['user_type']==this.constant.phc_worker){
      this.phcUser=true;
    }
  }

  createForm(id) {
    const vim = this;

    vim.chkBabyDischargeDate = true;
    vim.chkDaysOfStayHospital = true;
    vim.chkDischargeStatus = true;
    vim.chkFinalDiagnosisSepsis = true;
    vim.chkFinalDiagnosisMeningitis = true;
    vim.chkFinalDiagnosisSeizures = true;
    vim.chkFinalDiagnosisRds = true;
    vim.chkFinalDiagnosisTtnb = true;
    vim.chkFinalDiagnosisJaundice = true;
    vim.chkFinalDiagnosisLBW = true;
    vim.chkFinalLgaSgaAgaSuspect = true;
    vim.chkFinalDiagnosisAnemia = true;
    vim.chkFinalDiagnosisdextochordia = true;
    vim.chkFinalDiagnosisHypoglycemia = true;
    vim.chkFinalDiagnosisHypocalcemia = true;
    vim.chkFinalDiagnosisGastroenteritis = true;
    vim.chkFinalDiagnosisPerinatalRespiratoryDepression = true;
    vim.chkFinalDiagnosisShock = true;
    vim.chkFinalDiagnosisFeedingIntolerence = true;
    vim.chkFinalDiagnosisSepticArthritis = true;
    vim.chkFinalDiagnosisEndocarditis = true;
    vim.chkFinalDiagnosisPeritonitis = true;
    vim.chkFinalDiagnosisSoftTissueAbscess = true;
    vim.chkFinalDiagnosisCoagulopathy = true;
    vim.chkFinalDiagnosisUti = true;
    vim.chkFinalDiagnosisUmblicalSepsis = true;
    vim.chkFinalDiagnosisBleedingManifestation = true;
    vim.chkFinalDiagnosisAsphyxia = true;
    vim.chkFinalDiagnosisPneumonia = true;
    vim.chkFinalDiagnosisCentralPeripheral = true;
    vim.chkFinalDiagnosisHypoxia = true;
    vim.chkFinalDiagnosisMetabolicAcidosis = true;
    vim.chkFinalDiagnosisEosLOs = true;
    vim.chkFinalDiagnosisPulmonaryHemorrhage = true;
    vim.chkFinalDiagnosisThrombocytopenia = true;
    vim.chkFinalDiagnosisSkinPustules = true;

    this.babyFinalForm = this.formBuilder.group({
      study_id: id,
      days_of_stay_in_hospital: ["", Validators.required],
      final_diagnosis_sepsis: ["", Validators.required],
      final_diagnosis_rds: ["", Validators.required],
      final_diagnosis_ttnb: ["", Validators.required],
      final_diagnosis_jaundice: ["", Validators.required],
      final_diagnosis_lbw: ["", Validators.required],
      final_lga_sga_aga_suspect: ["", Validators.required],
      // final_diagnosis_aga: ["", Validators.required],
      final_diagnosis_anemia: ["", Validators.required],
      final_diagnosis_dextochordia: ["", Validators.required],
      final_diagnosis_hypoglycemia: ["", Validators.required],
      final_diagnosis_hypocalcemia: ["", Validators.required],
      final_diagnosis_gastroenteritis: ["", Validators.required],
      final_diagnosis_perinatal_respiratory_depression: [
        "",
        Validators.required
      ],
      final_diagnosis_shock: ["", Validators.required],
      final_diagnosis_feeding_intolerence: ["", Validators.required],
      baby_discharge_date: ["", Validators.required],
      // final_diagnosis_sga: ["", Validators.required],
      final_diagnosis_eos_los: ["", Validators.required],
      final_diagnosis_other: ["", Validators.required],
      discharge_status:["",Validators.required],
      final_diagnosis_meningitis:["",Validators.required],
      final_diagnosis_hypoxia:["",Validators.required],
      final_diagnosis_metabolic_acidosis:["",Validators.required],
      final_diagnosis_asphyxia:["", Validators.required],
      final_diagnosis_septic_arthritis:["", Validators.required],
      final_diagnosis_endocarditis:["", Validators.required],
      final_diagnosis_peritonitis:["", Validators.required],
      final_diagnosis_soft_tissue_abscess:["", Validators.required],
      final_diagnosis_coagulopathy:["", Validators.required],
      final_diagnosis_uti:["", Validators.required],
      final_diagnosis_umblical_sepsis:["", Validators.required],
      final_diagnosis_bleeding_manifestation:["", Validators.required],
      final_diagnosis_central_peripheral:["", Validators.required],
      final_diagnosis_seizures:["",Validators.required],
      final_diagnosis_skin_pustules:["",Validators.required],
      final_diagnosis_pulmonary_hemorrhage:["",Validators.required],
      final_diagnosis_thrombocytopenia:["",Validators.required],
      final_diagnosis_pneumonia:["",Validators.required]
    });
  }

  updateForm(obj) {
    const vim = this;
    if (obj["baby_discharge_date"] == 'NA') {
      vim.chkBabyDischargeDate = false;
    } else {

      vim.chkBabyDischargeDate = true;
    }

    if (obj["days_of_stay_in_hospital"] == 'NA') {
      vim.chkDaysOfStayHospital = false;
      vim.babyFinalForm.controls["days_of_stay_in_hospital"].clearValidators();
      vim.babyFinalForm.controls["days_of_stay_in_hospital"].updateValueAndValidity();
    } else {
      vim.chkDaysOfStayHospital = true;
      vim.babyFinalForm.controls["days_of_stay_in_hospital"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["days_of_stay_in_hospital"].updateValueAndValidity();
    }
    
    if (obj["discharge_status"] == 'NA') {
      vim.chkDischargeStatus = false;
      vim.babyFinalForm.controls["discharge_status"].clearValidators();
      vim.babyFinalForm.controls["discharge_status"].updateValueAndValidity();
    } else {
      vim.chkDischargeStatus = true;
      vim.babyFinalForm.controls["discharge_status"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["discharge_status"].updateValueAndValidity();
    }

    if (obj["final_diagnosis_sepsis"] == 'NA') {
      vim.chkFinalDiagnosisSepsis = false;
      vim.babyFinalForm.controls["final_diagnosis_sepsis"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_sepsis"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisSepsis = true;
      vim.babyFinalForm.controls["final_diagnosis_sepsis"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_sepsis"].updateValueAndValidity();
    }
    
    if (obj["final_diagnosis_meningitis"] == 'NA') {
      vim.chkFinalDiagnosisMeningitis = false;
      vim.babyFinalForm.controls["final_diagnosis_meningitis"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_meningitis"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisMeningitis = true;
      vim.babyFinalForm.controls["final_diagnosis_meningitis"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_meningitis"].updateValueAndValidity();
    }

    if (obj["final_diagnosis_seizures"] == 'NA') {
      vim.chkFinalDiagnosisSeizures = false;
      vim.babyFinalForm.controls["final_diagnosis_seizures"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_seizures"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisSeizures = true;
      vim.babyFinalForm.controls["final_diagnosis_seizures"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_seizures"].updateValueAndValidity();
    }
    
    if (obj["final_diagnosis_rds"] == 'NA') {
      vim.chkFinalDiagnosisRds = false;
      vim.babyFinalForm.controls["final_diagnosis_rds"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_rds"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisRds = true;
      vim.babyFinalForm.controls["final_diagnosis_rds"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_rds"].updateValueAndValidity();
    }
    
    if (obj["final_diagnosis_ttnb"] == 'NA') {
      vim.chkFinalDiagnosisTtnb = false;
      vim.babyFinalForm.controls["final_diagnosis_ttnb"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_ttnb"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisTtnb = true;
      vim.babyFinalForm.controls["final_diagnosis_ttnb"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_ttnb"].updateValueAndValidity();
    }
    
    if (obj["final_diagnosis_jaundice"] == 'NA') {
      vim.chkFinalDiagnosisJaundice = false;
      vim.babyFinalForm.controls["final_diagnosis_jaundice"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_jaundice"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisJaundice = true;
      vim.babyFinalForm.controls["final_diagnosis_jaundice"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_jaundice"].updateValueAndValidity();
    }
    
    if (obj["final_diagnosis_lbw"] == 'NA') {
      vim.chkFinalDiagnosisLBW = false;
      vim.babyFinalForm.controls["final_diagnosis_lbw"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_lbw"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisLBW = true;
      vim.babyFinalForm.controls["final_diagnosis_lbw"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_lbw"].updateValueAndValidity();
    }
    
    if (obj["final_lga_sga_aga_suspect"] == 'NA') {
      vim.chkFinalLgaSgaAgaSuspect = false;
      vim.babyFinalForm.controls["final_lga_sga_aga_suspect"].clearValidators();
      vim.babyFinalForm.controls["final_lga_sga_aga_suspect"].updateValueAndValidity();
    } else {
      vim.chkFinalLgaSgaAgaSuspect = true;
      vim.babyFinalForm.controls["final_lga_sga_aga_suspect"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_lga_sga_aga_suspect"].updateValueAndValidity();
    }
    
    if (obj["final_diagnosis_anemia"] == 'NA') {
      vim.chkFinalDiagnosisAnemia = false;
      vim.babyFinalForm.controls["final_diagnosis_anemia"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_anemia"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisAnemia = true;
      vim.babyFinalForm.controls["final_diagnosis_anemia"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_anemia"].updateValueAndValidity();
    }
    
    if (obj["final_diagnosis_dextochordia"] == 'NA') {
      vim.chkFinalDiagnosisdextochordia = false;
      vim.babyFinalForm.controls["final_diagnosis_dextochordia"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_dextochordia"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisdextochordia = true;
      vim.babyFinalForm.controls["final_diagnosis_dextochordia"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_dextochordia"].updateValueAndValidity();
    }
    
    if (obj["final_diagnosis_hypoglycemia"] == 'NA') {
      vim.chkFinalDiagnosisHypoglycemia = false;
      vim.babyFinalForm.controls["final_diagnosis_hypoglycemia"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_hypoglycemia"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisHypoglycemia = true;
      vim.babyFinalForm.controls["final_diagnosis_hypoglycemia"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_hypoglycemia"].updateValueAndValidity();
    }
    
    if (obj["final_diagnosis_hypocalcemia"] == 'NA') {
      vim.chkFinalDiagnosisHypocalcemia = false;
      vim.babyFinalForm.controls["final_diagnosis_hypocalcemia"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_hypocalcemia"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisHypocalcemia = true;
      vim.babyFinalForm.controls["final_diagnosis_hypocalcemia"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_hypocalcemia"].updateValueAndValidity();
    }
    if (obj["final_diagnosis_gastroenteritis"] == 'NA') {
      vim.chkFinalDiagnosisGastroenteritis = false;
      vim.babyFinalForm.controls["final_diagnosis_gastroenteritis"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_gastroenteritis"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisGastroenteritis = true;
      vim.babyFinalForm.controls["final_diagnosis_gastroenteritis"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_gastroenteritis"].updateValueAndValidity();
    }
    if (obj["final_diagnosis_perinatal_respiratory_depression"] == 'NA') {
      vim.chkFinalDiagnosisPerinatalRespiratoryDepression = false;
      vim.babyFinalForm.controls["final_diagnosis_perinatal_respiratory_depression"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_perinatal_respiratory_depression"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisPerinatalRespiratoryDepression = true;
      vim.babyFinalForm.controls["final_diagnosis_perinatal_respiratory_depression"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_perinatal_respiratory_depression"].updateValueAndValidity();
    }
    if (obj["final_diagnosis_shock"] == 'NA') {
      vim.chkFinalDiagnosisShock = false;
      vim.babyFinalForm.controls["final_diagnosis_shock"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_shock"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisShock = true;
      vim.babyFinalForm.controls["final_diagnosis_shock"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_shock"].updateValueAndValidity();
    }
    if (obj["final_diagnosis_feeding_intolerence"] == 'NA') {
      vim.chkFinalDiagnosisFeedingIntolerence = false;
      vim.babyFinalForm.controls["final_diagnosis_feeding_intolerence"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_feeding_intolerence"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisFeedingIntolerence = true;
      vim.babyFinalForm.controls["final_diagnosis_feeding_intolerence"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_feeding_intolerence"].updateValueAndValidity();
    }
    if (obj["final_diagnosis_septic_arthritis"] == 'NA') {
      vim.chkFinalDiagnosisSepticArthritis = false;
      vim.babyFinalForm.controls["final_diagnosis_septic_arthritis"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_septic_arthritis"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisSepticArthritis = true;
      vim.babyFinalForm.controls["final_diagnosis_septic_arthritis"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_septic_arthritis"].updateValueAndValidity();
    }
    if (obj["final_diagnosis_endocarditis"] == 'NA') {
      vim.chkFinalDiagnosisEndocarditis = false;
      vim.babyFinalForm.controls["final_diagnosis_endocarditis"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_endocarditis"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisEndocarditis = true;
      vim.babyFinalForm.controls["final_diagnosis_endocarditis"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_endocarditis"].updateValueAndValidity();
    }
    
    if (obj["final_diagnosis_peritonitis"] == 'NA') {
      vim.chkFinalDiagnosisPeritonitis = false;
      vim.babyFinalForm.controls["final_diagnosis_peritonitis"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_peritonitis"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisPeritonitis = true;
      vim.babyFinalForm.controls["final_diagnosis_peritonitis"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_peritonitis"].updateValueAndValidity();
    }
    
    if (obj["final_diagnosis_soft_tissue_abscess"] == 'NA') {
      vim.chkFinalDiagnosisSoftTissueAbscess = false;
      vim.babyFinalForm.controls["final_diagnosis_soft_tissue_abscess"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_soft_tissue_abscess"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisSoftTissueAbscess = true;
      vim.babyFinalForm.controls["final_diagnosis_soft_tissue_abscess"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_soft_tissue_abscess"].updateValueAndValidity();
    }
    
    if (obj["final_diagnosis_coagulopathy"] == 'NA') {
      vim.chkFinalDiagnosisCoagulopathy = false;
      vim.babyFinalForm.controls["final_diagnosis_coagulopathy"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_coagulopathy"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisCoagulopathy = true;
      vim.babyFinalForm.controls["final_diagnosis_coagulopathy"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_coagulopathy"].updateValueAndValidity();
    }
    
    if (obj["final_diagnosis_uti"] == 'NA') {
      vim.chkFinalDiagnosisUti = false;
      vim.babyFinalForm.controls["final_diagnosis_uti"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_uti"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisUti = true;
      vim.babyFinalForm.controls["final_diagnosis_uti"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_uti"].updateValueAndValidity();
    }
    
    if (obj["final_diagnosis_umblical_sepsis"] == 'NA') {
      vim.chkFinalDiagnosisUmblicalSepsis = false;
      vim.babyFinalForm.controls["final_diagnosis_umblical_sepsis"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_umblical_sepsis"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisUmblicalSepsis = true;
      vim.babyFinalForm.controls["final_diagnosis_umblical_sepsis"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_umblical_sepsis"].updateValueAndValidity();
    }
    
    if (obj["final_diagnosis_bleeding_manifestation"] == 'NA') {
      vim.chkFinalDiagnosisBleedingManifestation = false;
      vim.babyFinalForm.controls["final_diagnosis_bleeding_manifestation"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_bleeding_manifestation"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisBleedingManifestation = true;
      vim.babyFinalForm.controls["final_diagnosis_bleeding_manifestation"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_bleeding_manifestation"].updateValueAndValidity();
    }
    
    if (obj["final_diagnosis_asphyxia"] == 'NA') {
      vim.chkFinalDiagnosisAsphyxia = false;
      vim.babyFinalForm.controls["final_diagnosis_asphyxia"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_asphyxia"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisAsphyxia = true;
      vim.babyFinalForm.controls["final_diagnosis_asphyxia"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_asphyxia"].updateValueAndValidity();
    }

    if (obj["final_diagnosis_pneumonia"] == 'NA') {
      vim.chkFinalDiagnosisPneumonia = false;
      vim.babyFinalForm.controls["final_diagnosis_pneumonia"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_pneumonia"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisPneumonia = true;
      vim.babyFinalForm.controls["final_diagnosis_pneumonia"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_pneumonia"].updateValueAndValidity();
    }
    
    if (obj["final_diagnosis_central_peripheral"] == 'NA') {
      vim.chkFinalDiagnosisCentralPeripheral = false;
      vim.babyFinalForm.controls["final_diagnosis_central_peripheral"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_central_peripheral"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisCentralPeripheral = true;
      vim.babyFinalForm.controls["final_diagnosis_central_peripheral"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_central_peripheral"].updateValueAndValidity();
    }
    
    if (obj["final_diagnosis_hypoxia"] == 'NA') {
      vim.chkFinalDiagnosisHypoxia = false;
      vim.babyFinalForm.controls["final_diagnosis_hypoxia"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_hypoxia"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisHypoxia = true;
      vim.babyFinalForm.controls["final_diagnosis_hypoxia"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_hypoxia"].updateValueAndValidity();
    }
    
    if (obj["final_diagnosis_metabolic_acidosis"] == 'NA') {
      vim.chkFinalDiagnosisMetabolicAcidosis = false;
      vim.babyFinalForm.controls["final_diagnosis_metabolic_acidosis"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_metabolic_acidosis"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisMetabolicAcidosis = true;
      vim.babyFinalForm.controls["final_diagnosis_metabolic_acidosis"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_metabolic_acidosis"].updateValueAndValidity();
    }
    
    if (obj["final_diagnosis_eos_los"] == 'NA') {
      vim.chkFinalDiagnosisEosLOs = false;
      vim.babyFinalForm.controls["final_diagnosis_eos_los"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_eos_los"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisEosLOs = true;
      vim.babyFinalForm.controls["final_diagnosis_eos_los"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_eos_los"].updateValueAndValidity();
    }
    
    if (obj["final_diagnosis_pulmonary_hemorrhage"] == 'NA') {
      vim.chkFinalDiagnosisPulmonaryHemorrhage = false;
      vim.babyFinalForm.controls["final_diagnosis_pulmonary_hemorrhage"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_pulmonary_hemorrhage"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisPulmonaryHemorrhage = true;
      vim.babyFinalForm.controls["final_diagnosis_pulmonary_hemorrhage"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_pulmonary_hemorrhage"].updateValueAndValidity();
    }
    
    if (obj["final_diagnosis_thrombocytopenia"] == 'NA') {
      vim.chkFinalDiagnosisThrombocytopenia = false;
      vim.babyFinalForm.controls["final_diagnosis_thrombocytopenia"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_thrombocytopenia"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisThrombocytopenia = true;
      vim.babyFinalForm.controls["final_diagnosis_thrombocytopenia"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_thrombocytopenia"].updateValueAndValidity();
    }
    
    if (obj["final_diagnosis_skin_pustules"] == 'NA') {
      vim.chkFinalDiagnosisSkinPustules = false;
      vim.babyFinalForm.controls["final_diagnosis_skin_pustules"].clearValidators();
      vim.babyFinalForm.controls["final_diagnosis_skin_pustules"].updateValueAndValidity();
    } else {
      vim.chkFinalDiagnosisSkinPustules = true;
      vim.babyFinalForm.controls["final_diagnosis_skin_pustules"].setValidators([Validators.required]);
      vim.babyFinalForm.controls["final_diagnosis_skin_pustules"].updateValueAndValidity();
    }

    if (obj["final_diagnosis_other"] == 'NA') {
      vim.ifFinalDiagnosisOther = false;
    } else {
      vim.ifFinalDiagnosisOther = true;
    }

    vim.babyFinalForm.patchValue({
      study_id: vim.id,
      days_of_stay_in_hospital: obj["days_of_stay_in_hospital"],
      discharge_status: obj["discharge_status"],
      final_diagnosis_sepsis: obj["final_diagnosis_sepsis"],
      final_diagnosis_rds: obj["final_diagnosis_rds"],
      final_diagnosis_ttnb: obj["final_diagnosis_ttnb"],
      final_diagnosis_jaundice: obj["final_diagnosis_jaundice"],
      final_diagnosis_lbw: obj["final_diagnosis_lbw"],
      final_lga_sga_aga_suspect: obj["final_lga_sga_aga_suspect"],
      // final_diagnosis_aga: obj["final_diagnosis_aga"],
      final_diagnosis_anemia: obj["final_diagnosis_anemia"],
      final_diagnosis_dextochordia: obj["final_diagnosis_dextochordia"],
      final_diagnosis_hypoglycemia: obj["final_diagnosis_hypoglycemia"],
      final_diagnosis_hypocalcemia: obj["final_diagnosis_hypocalcemia"],
      final_diagnosis_gastroenteritis: obj["final_diagnosis_gastroenteritis"],
      final_diagnosis_perinatal_respiratory_depression:
        obj["final_diagnosis_perinatal_respiratory_depression"],
      final_diagnosis_shock: obj["final_diagnosis_shock"],
      final_diagnosis_feeding_intolerence:
        obj["final_diagnosis_feeding_intolerence"],
      baby_discharge_date: obj["baby_discharge_date"],
      // final_diagnosis_sga: obj["final_diagnosis_sga"],
      final_diagnosis_eos_los: obj["final_diagnosis_eos_los"],
      final_diagnosis_other: obj["final_diagnosis_other"],
      final_diagnosis_meningitis:obj["final_diagnosis_meningitis"],
      final_diagnosis_hypoxia:obj["final_diagnosis_hypoxia"],
      final_diagnosis_metabolic_acidosis:obj["final_diagnosis_metabolic_acidosis"],
      final_diagnosis_asphyxia:obj["final_diagnosis_asphyxia"],
      final_diagnosis_septic_arthritis:obj["final_diagnosis_septic_arthritis"],
      final_diagnosis_endocarditis:obj["final_diagnosis_endocarditis"],
      final_diagnosis_peritonitis:obj["final_diagnosis_peritonitis"],
      final_diagnosis_soft_tissue_abscess:obj["final_diagnosis_soft_tissue_abscess"],
      final_diagnosis_coagulopathy:obj["final_diagnosis_coagulopathy"],
      final_diagnosis_uti:obj["final_diagnosis_uti"],
      final_diagnosis_umblical_sepsis:obj["final_diagnosis_umblical_sepsis"],
      final_diagnosis_bleeding_manifestation:obj["final_diagnosis_bleeding_manifestation"],
      final_diagnosis_central_peripheral:obj["final_diagnosis_central_peripheral"],
      final_diagnosis_seizures:obj["final_diagnosis_seizures"],
      final_diagnosis_skin_pustules:obj["final_diagnosis_skin_pustules"],
      final_diagnosis_pulmonary_hemorrhage:obj["final_diagnosis_pulmonary_hemorrhage"],
      final_diagnosis_thrombocytopenia:obj["final_diagnosis_thrombocytopenia"],
      final_diagnosis_pneumonia:obj["final_diagnosis_pneumonia"]
    });
  }

  calculateDate() {
    var getDischargeDate = this.babyFinalForm.controls["baby_discharge_date"].value;
    var discharge_date = (getDischargeDate.getMonth() + 1) + '/' + getDischargeDate.getDate() + '/' + getDischargeDate.getFullYear();
    
    var chunks = this.getDOA.split('/');
    console.log(chunks);
    var formattedDate = [chunks[1], chunks[0], chunks[2]].join("/");
    var milliseconds = Date.parse(formattedDate);
    var milliseconds1 = Date.parse(discharge_date);

    var result = milliseconds1 - milliseconds;

    result = result / (24 * 60 * 60 * 1000);
    //console.log(result);
    this.babyFinalForm.patchValue({
      days_of_stay_in_hospital: result
    })
  }

  onInputChange(event) {
    var vim = this;
    var target = event.target || event.srcElement || event.currentTarget;

    if (target.name == 'babyDischargeDate') {
      if (target.value == '2') {
        vim.chkBabyDischargeDate = false;
        vim.babyFinalForm.patchValue({
          baby_discharge_date: 'NA'
        })
      } else {
        vim.chkBabyDischargeDate = true;
        vim.babyFinalForm.patchValue({
          baby_discharge_date: ''
        })
      }
    }

    if (target.name == 'daysOfStayHospital') {
      if (target.value == '2') {
        vim.chkDaysOfStayHospital = false;
        vim.babyFinalForm.patchValue({
          days_of_stay_in_hospital: 'NA'
        })
        vim.babyFinalForm.value["days_of_stay_in_hospital"] = 'NA';

        vim.babyFinalForm.controls["days_of_stay_in_hospital"].clearValidators();
        vim.babyFinalForm.controls["days_of_stay_in_hospital"].updateValueAndValidity();
      } else {
        vim.chkDaysOfStayHospital = true;
        vim.babyFinalForm.controls["days_of_stay_in_hospital"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["days_of_stay_in_hospital"].updateValueAndValidity();
      //     vim.babyFinalForm.patchValue({
      //    days_of_stay_in_hospital: ''
      //  })
      }
    }

    if (target.name == 'discharge_status') {
      if (target.value == '2') {
        vim.chkDischargeStatus = false;
        vim.babyFinalForm.patchValue({
          discharge_status: 'NA'
        })
        vim.babyFinalForm.value["discharge_status"] = 'NA';

        vim.babyFinalForm.controls["discharge_status"].clearValidators();
        vim.babyFinalForm.controls["discharge_status"].updateValueAndValidity();
      } else {
        vim.chkDischargeStatus = true;
        vim.babyFinalForm.controls["discharge_status"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["discharge_status"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          discharge_status: ''
        })
      }
    }

    if (target.name == 'final_diagnosis_sepsis') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisSepsis = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_sepsis: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_sepsis"] = 'NA';

        vim.babyFinalForm.controls["final_diagnosis_sepsis"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_sepsis"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisSepsis = true;
        vim.babyFinalForm.controls["final_diagnosis_sepsis"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_sepsis"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_sepsis: ''
        })
      }
    }
    
    if (target.name == 'final_diagnosis_meningitis') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisMeningitis = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_meningitis: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_meningitis"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_meningitis"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_meningitis"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisMeningitis = true;
        vim.babyFinalForm.controls["final_diagnosis_meningitis"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_meningitis"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_meningitis: ''
        })
      }
    }

    if (target.name == 'final_diagnosis_seizures') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisSeizures = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_seizures: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_seizures"] = 'NA';

        vim.babyFinalForm.controls["final_diagnosis_seizures"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_seizures"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisSeizures = true;
        vim.babyFinalForm.controls["final_diagnosis_seizures"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_seizures"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_seizures: ''
        })
      }
    }

    if (target.name == 'final_diagnosis_rds') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisRds = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_rds: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_rds"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_rds"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_rds"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisRds = true;
        vim.babyFinalForm.controls["final_diagnosis_rds"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_rds"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_rds: ''
        })
      }
    }
    
    if (target.name == 'final_diagnosis_ttnb') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisTtnb = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_ttnb: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_ttnb"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_ttnb"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_ttnb"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisTtnb = true;
        vim.babyFinalForm.controls["final_diagnosis_ttnb"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_ttnb"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_ttnb: ''
        })
      }
    }
    
    if (target.name == 'final_diagnosis_jaundice') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisJaundice = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_jaundice: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_jaundice"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_jaundice"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_jaundice"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisJaundice = true;
        vim.babyFinalForm.controls["final_diagnosis_jaundice"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_jaundice"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_jaundice: ''
        })
      }
    }
    
    if (target.name == 'final_diagnosis_lbw') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisLBW = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_lbw: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_lbw"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_lbw"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_lbw"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisLBW = true;
        vim.babyFinalForm.controls["final_diagnosis_lbw"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_lbw"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_lbw: ''
        })
      }
    }
    
    if (target.name == 'final_diagnosis_anemia') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisAnemia = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_anemia: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_anemia"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_anemia"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_anemia"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisAnemia = true;
        vim.babyFinalForm.controls["final_diagnosis_anemia"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_anemia"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_anemia: ''
        })
      }
    }
    
    if (target.name == 'final_diagnosis_dextochordia') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisdextochordia = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_dextochordia: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_dextochordia"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_dextochordia"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_dextochordia"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisdextochordia = true;
        vim.babyFinalForm.controls["final_diagnosis_dextochordia"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_dextochordia"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_dextochordia: ''
        })
      }
    }
    
    if (target.name == 'final_diagnosis_hypoglycemia') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisHypoglycemia = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_hypoglycemia: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_hypoglycemia"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_hypoglycemia"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_hypoglycemia"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisHypoglycemia = true;
        vim.babyFinalForm.controls["final_diagnosis_hypoglycemia"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_hypoglycemia"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_hypoglycemia: ''
        })
      }
    }
    
    if (target.name == 'final_diagnosis_hypocalcemia') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisHypocalcemia = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_hypocalcemia: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_hypocalcemia"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_hypocalcemia"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_hypocalcemia"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisHypocalcemia = true;
        vim.babyFinalForm.controls["final_diagnosis_hypocalcemia"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_hypocalcemia"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_hypocalcemia: ''
        })
      }
    }
    if (target.name == 'final_diagnosis_gastroenteritis') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisGastroenteritis = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_gastroenteritis: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_gastroenteritis"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_gastroenteritis"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_gastroenteritis"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisGastroenteritis = true;
        vim.babyFinalForm.controls["final_diagnosis_gastroenteritis"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_gastroenteritis"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_gastroenteritis: ''
        })
      }
    }
    if (target.name == 'final_diagnosis_perinatal_respiratory_depression') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisPerinatalRespiratoryDepression = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_perinatal_respiratory_depression: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_perinatal_respiratory_depression"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_perinatal_respiratory_depression"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_perinatal_respiratory_depression"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisPerinatalRespiratoryDepression = true;
        vim.babyFinalForm.controls["final_diagnosis_perinatal_respiratory_depression"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_perinatal_respiratory_depression"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_perinatal_respiratory_depression: ''
        })
      }
    }
    if (target.name == 'final_diagnosis_shock') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisShock = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_shock: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_shock"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_shock"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_shock"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisShock = true;
        vim.babyFinalForm.controls["final_diagnosis_shock"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_shock"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_shock: ''
        })
      }
    }
    
    if (target.name == 'final_diagnosis_feeding_intolerence') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisFeedingIntolerence = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_feeding_intolerence: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_feeding_intolerence"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_feeding_intolerence"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_feeding_intolerence"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisFeedingIntolerence = true;
        vim.babyFinalForm.controls["final_diagnosis_feeding_intolerence"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_feeding_intolerence"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_feeding_intolerence: ''
        })
      }
    }
    if (target.name == 'final_diagnosis_septic_arthritis') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisSepticArthritis = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_septic_arthritis: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_septic_arthritis"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_septic_arthritis"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_septic_arthritis"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisSepticArthritis = true;
        vim.babyFinalForm.controls["final_diagnosis_septic_arthritis"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_septic_arthritis"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_septic_arthritis: ''
        })
      }
    }
    if (target.name == 'final_diagnosis_endocarditis') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisEndocarditis = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_endocarditis: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_endocarditis"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_endocarditis"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_endocarditis"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisEndocarditis = true;
        vim.babyFinalForm.controls["final_diagnosis_endocarditis"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_endocarditis"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_endocarditis: ''
        })
      }
    }

    if (target.name == 'final_lga_sga_aga_suspect') {
      if (target.value == '2') {
        vim.chkFinalLgaSgaAgaSuspect = false;
        vim.babyFinalForm.patchValue({
          final_lga_sga_aga_suspect: 'NA'
        })
        vim.babyFinalForm.value["final_lga_sga_aga_suspect"] = 'NA';
        vim.babyFinalForm.controls["final_lga_sga_aga_suspect"].clearValidators();
        vim.babyFinalForm.controls["final_lga_sga_aga_suspect"].updateValueAndValidity();
      } else {
        vim.chkFinalLgaSgaAgaSuspect = true;
        vim.babyFinalForm.controls["final_lga_sga_aga_suspect"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_lga_sga_aga_suspect"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_lga_sga_aga_suspect: ''
        })
      }
    }
    
    if (target.name == 'final_diagnosis_peritonitis') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisPeritonitis = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_peritonitis: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_peritonitis"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_peritonitis"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_peritonitis"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisPeritonitis = true;
        vim.babyFinalForm.controls["final_diagnosis_peritonitis"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_peritonitis"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_peritonitis: ''
        })
      }
    }
    
    if (target.name == 'final_diagnosis_soft_tissue_abscess') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisSoftTissueAbscess = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_soft_tissue_abscess: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_soft_tissue_abscess"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_soft_tissue_abscess"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_soft_tissue_abscess"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisSoftTissueAbscess = true;
        vim.babyFinalForm.controls["final_diagnosis_soft_tissue_abscess"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_soft_tissue_abscess"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_soft_tissue_abscess: ''
        })
      }
    }
    
    if (target.name == 'final_diagnosis_coagulopathy') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisCoagulopathy = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_coagulopathy: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_coagulopathy"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_coagulopathy"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_coagulopathy"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisCoagulopathy = true;
        vim.babyFinalForm.controls["final_diagnosis_coagulopathy"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_coagulopathy"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_coagulopathy: ''
        })
      }
    }

    if (target.name == 'final_diagnosis_uti') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisUti = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_uti: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_uti"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_uti"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_uti"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisUti = true;
        vim.babyFinalForm.controls["final_diagnosis_uti"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_uti"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_uti: ''
        })
      }
    }

    if (target.name == 'final_diagnosis_umblical_sepsis') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisUmblicalSepsis = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_umblical_sepsis: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_umblical_sepsis"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_umblical_sepsis"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_umblical_sepsis"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisUmblicalSepsis = true;
        vim.babyFinalForm.controls["final_diagnosis_umblical_sepsis"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_umblical_sepsis"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_umblical_sepsis: ''
        })
      }
    }

    if (target.name == 'final_diagnosis_bleeding_manifestation') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisBleedingManifestation = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_bleeding_manifestation: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_bleeding_manifestation"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_bleeding_manifestation"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_bleeding_manifestation"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisBleedingManifestation = true;
        vim.babyFinalForm.controls["final_diagnosis_bleeding_manifestation"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_bleeding_manifestation"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_bleeding_manifestation: ''
        })
      }
    }

    if (target.name == 'final_diagnosis_asphyxia') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisAsphyxia = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_asphyxia: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_asphyxia"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_asphyxia"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_asphyxia"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisAsphyxia = true;
        vim.babyFinalForm.controls["final_diagnosis_asphyxia"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_asphyxia"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_asphyxia: ''
        })
      }
    }

    if (target.name == 'final_diagnosis_pneumonia') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisPneumonia = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_pneumonia: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_pneumonia"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_pneumonia"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_pneumonia"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisPneumonia = true;
        vim.babyFinalForm.controls["final_diagnosis_pneumonia"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_pneumonia"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_pneumonia: ''
        })
      }
    }
      
    if (target.name == 'final_diagnosis_central_peripheral') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisCentralPeripheral = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_central_peripheral: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_central_peripheral"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_central_peripheral"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_central_peripheral"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisCentralPeripheral = true;
        vim.babyFinalForm.controls["final_diagnosis_central_peripheral"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_central_peripheral"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_central_peripheral: ''
        })
      }
    }

    if (target.name == 'final_diagnosis_hypoxia') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisHypoxia = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_hypoxia: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_hypoxia"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_hypoxia"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_hypoxia"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisHypoxia = true;
        vim.babyFinalForm.controls["final_diagnosis_hypoxia"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_hypoxia"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_hypoxia: ''
        })
      }
    }
    
    if (target.name == 'final_diagnosis_metabolic_acidosis') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisMetabolicAcidosis = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_metabolic_acidosis: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_metabolic_acidosis"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_metabolic_acidosis"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_metabolic_acidosis"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisMetabolicAcidosis = true;
        vim.babyFinalForm.controls["final_diagnosis_metabolic_acidosis"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_metabolic_acidosis"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_metabolic_acidosis: ''
        })
      }
    }
    

    if (target.name == 'final_diagnosis_eos_los') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisEosLOs = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_eos_los: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_eos_los"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_eos_los"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_eos_los"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisEosLOs = true;
        vim.babyFinalForm.controls["final_diagnosis_eos_los"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_eos_los"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_eos_los: ''
        })
      }
    }
    
    if (target.name == 'final_diagnosis_pulmonary_hemorrhage') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisPulmonaryHemorrhage = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_pulmonary_hemorrhage: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_pulmonary_hemorrhage"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_pulmonary_hemorrhage"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_pulmonary_hemorrhage"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisPulmonaryHemorrhage = true;
        vim.babyFinalForm.controls["final_diagnosis_pulmonary_hemorrhage"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_pulmonary_hemorrhage"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_pulmonary_hemorrhage: ''
        })
      }
    }

    if (target.name == 'final_diagnosis_thrombocytopenia') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisThrombocytopenia = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_thrombocytopenia: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_thrombocytopenia"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_thrombocytopenia"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_thrombocytopenia"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisThrombocytopenia = true;
        vim.babyFinalForm.controls["final_diagnosis_thrombocytopenia"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_thrombocytopenia"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_thrombocytopenia: ''
        })
      }
    }
    
    if (target.name == 'final_diagnosis_skin_pustules') {
      if (target.value == '2') {
        vim.chkFinalDiagnosisSkinPustules = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_skin_pustules: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_skin_pustules"] = 'NA';
        vim.babyFinalForm.controls["final_diagnosis_skin_pustules"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_skin_pustules"].updateValueAndValidity();
      } else {
        vim.chkFinalDiagnosisSkinPustules = true;
        vim.babyFinalForm.controls["final_diagnosis_skin_pustules"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_skin_pustules"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_skin_pustules: ''
        })
      }
    }

    if (target.name == 'FinalDiagnosisOther') {
      if (target.value == '2') {
        vim.ifFinalDiagnosisOther = false;
        vim.babyFinalForm.patchValue({
          final_diagnosis_other: 'NA'
        })
        vim.babyFinalForm.value["final_diagnosis_other"] = 'NA';

        vim.babyFinalForm.controls["final_diagnosis_other"].clearValidators();
        vim.babyFinalForm.controls["final_diagnosis_other"].updateValueAndValidity();
      } else {
        vim.ifFinalDiagnosisOther = true;
        vim.babyFinalForm.controls["final_diagnosis_other"].setValidators([Validators.required]);
        vim.babyFinalForm.controls["final_diagnosis_other"].updateValueAndValidity();
        vim.babyFinalForm.patchValue({
          final_diagnosis_other: ''
        })
      }
    }
  }

  ngOnChanges() {
    this.createForm(this.id);
  }
  reset() {
    this.createForm(null);
  }

  open(content, obj) {
    this.submitted = false;
    if (!_.isEmpty(obj)) {
      this.isFinalEdit = true;
      this.isEditClicked = true;
      this.updateForm(obj);
    } else {
      this.isFinalEdit = true;
      this.createForm(this.id);
    }
  }

  finalFormSubmit() {
    const vim = this;
   // console.log(vim,"vim")
    vim.transformDate(vim.babyFinalForm.value);
    vim.submitted = true;
    if (vim.babyFinalForm.invalid) {
      return;
    }

    if (this.babyFinalForm.value["days_of_stay_in_hospital"] == '') {
      this.babyFinalForm.value["days_of_stay_in_hospital"] = 'NA';
    }

    if (this.babyFinalForm.value["discharge_status"] == '') {
      this.babyFinalForm.value["discharge_status"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_sepsis"] == '') {
      this.babyFinalForm.value["final_diagnosis_sepsis"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_meningitis"] == '') {
      this.babyFinalForm.value["final_diagnosis_meningitis"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_seizures"] == '') {
      this.babyFinalForm.value["final_diagnosis_seizures"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_rds"] == '') {
      this.babyFinalForm.value["final_diagnosis_rds"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_ttnb"] == '') {
      this.babyFinalForm.value["final_diagnosis_ttnb"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_jaundice"] == '') {
      this.babyFinalForm.value["final_diagnosis_jaundice"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_lbw"] == '') {
      this.babyFinalForm.value["final_diagnosis_lbw"] = 'NA';
    }
    if (this.babyFinalForm.value["final_lga_sga_aga_suspect"] == '') {
      this.babyFinalForm.value["final_lga_sga_aga_suspect"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_anemia"] == '') {
      this.babyFinalForm.value["final_diagnosis_anemia"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_dextochordia"] == '') {
      this.babyFinalForm.value["final_diagnosis_dextochordia"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_hypoglycemia"] == '') {
      this.babyFinalForm.value["final_diagnosis_hypoglycemia"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_hypocalcemia"] == '') {
      this.babyFinalForm.value["final_diagnosis_hypocalcemia"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_gastroenteritis"] == '') {
      this.babyFinalForm.value["final_diagnosis_gastroenteritis"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_perinatal_respiratory_depression"] == '') {
      this.babyFinalForm.value["final_diagnosis_perinatal_respiratory_depression"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_shock"] == '') {
      this.babyFinalForm.value["final_diagnosis_shock"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_feeding_intolerence"] == '') {
      this.babyFinalForm.value["final_diagnosis_feeding_intolerence"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_septic_arthritis"] == '') {
      this.babyFinalForm.value["final_diagnosis_septic_arthritis"] = 'NA';
    }
    
    if (this.babyFinalForm.value["final_diagnosis_endocarditis"] == '') {
      this.babyFinalForm.value["final_diagnosis_endocarditis"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_peritonitis"] == '') {
      this.babyFinalForm.value["final_diagnosis_peritonitis"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_soft_tissue_abscess"] == '') {
      this.babyFinalForm.value["final_diagnosis_soft_tissue_abscess"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_coagulopathy"] == '') {
      this.babyFinalForm.value["final_diagnosis_coagulopathy"] = 'NA';
    }
    
    if (this.babyFinalForm.value["final_diagnosis_uti"] == '') {
      this.babyFinalForm.value["final_diagnosis_uti"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_umblical_sepsis"] == '') {
      this.babyFinalForm.value["final_diagnosis_umblical_sepsis"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_bleeding_manifestation"] == '') {
      this.babyFinalForm.value["final_diagnosis_bleeding_manifestation"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_asphyxia"] == '') {
      this.babyFinalForm.value["final_diagnosis_asphyxia"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_pneumonia"] == '') {
      this.babyFinalForm.value["final_diagnosis_pneumonia"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_central_peripheral"] == '') {
      this.babyFinalForm.value["final_diagnosis_central_peripheral"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_hypoxia"] == '') {
      this.babyFinalForm.value["final_diagnosis_hypoxia"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_metabolic_acidosis"] == '') {
      this.babyFinalForm.value["final_diagnosis_metabolic_acidosis"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_eos_los"] == '') {
      this.babyFinalForm.value["final_diagnosis_eos_los"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_pulmonary_hemorrhage"] == '') {
      this.babyFinalForm.value["final_diagnosis_pulmonary_hemorrhage"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_thrombocytopenia"] == '') {
      this.babyFinalForm.value["final_diagnosis_thrombocytopenia"] = 'NA';
    }
    if (this.babyFinalForm.value["final_diagnosis_skin_pustules"] == '') {
      this.babyFinalForm.value["final_diagnosis_skin_pustules"] = 'NA';
    }

    if (this.babyFinalForm.value["final_diagnosis_other"] == '') {
      this.babyFinalForm.value["final_diagnosis_other"] = 'NA';
    }

    //vim.commonAsyn.showLoader();
    vim.babyFinalForm.value["tab_name"] = "genral";
    vim.babyFinalForm.value["reading"] = localStorage.getItem('reading');
    vim.saveReadingFormData(vim.babyFinalForm['value']);
    //vim.readingDataService.showSaveReadingButton=false;
    vim.openModal();
  }
  /**
   *
   * @param response
   * @param api_type
   * @method: success
   * @purpose :-  it is a common helper
   */
  success(response, api_type) {
    const vim = this;
    if (api_type == "finalFormSubmit") {
      if (vim.isSuccess(response)) {
        vim.toastr.success(
          "",
          "Information Updated succesfully"
        );
        
        vim.responseArray = [];
        this.page = 1;
        vim.dataServiceObj = vim.dataService.getOption();
        vim.get_final(vim.dataServiceObj.study_id, vim.login_hospital['id'], vim.page, vim.readingDataService.reading);
      } else {
        if (vim.isAlreadyExist(response)) {
          vim.toastr.warning("Already Exist!!", response["message"]);
        } else {
          vim.errorToasty(response);
        }
      }
    } else if (api_type == "get_final") {
      if (vim.isSuccess(response)) {
        if (this.page == 1) {
          vim.responseArray = [];
          vim.responseArray = response["response"];
          vim.isFinalEdit=false;
        } else {
          if (response["status"] == 404) {
          }
          else if (response["response"].length > 0) {
            vim.temp_study_id = response["response"][0].study_id;
            if (vim.temp_study_id == vim.id) {
            } else {
              vim.responseArray = [];
            }

            for (var i = 0; i < response["response"].length; i++) {
              vim.responseArray.push(response["response"][i]);
              vim.temp_study_id = vim.id;
            }
          }
        }
        vim.commonAsyn.isHide();
      } else {
        vim.responseArray = [];
        vim.commonAsyn.isHide();
        if (vim.isAlreadyExist(response)) {
        } else {
        }
      }
    }
  }

  /**
   *
   * @param error
   * @param api_type
   * @purpose :-  This is error handler method is called.
   * @method: errorHandler
   */
  errorHandler(error, api_type) {
    const vim = this;
    if (api_type == "finalFormSubmit") {
      vim.errorToasty(error);
    }
  }

  /**
   *
   * @param response
   * @method: it is a common herlper for check the status is 200 or not
   */
  isSuccess(response) {
    const vim = this;
    if (
      response.hasOwnProperty("status") &&
      response["status"] === vim.success_status
    ) {
      return true;
    } else if (response["status"] === 404) {
      return true;
    }
    return false;
  }
  /**
   *
   * @param response
   * @method :- isAlreadyExist
   * @purpose :- check if User Already Exist.
   */
  isAlreadyExist(response) {
    const vim = this;
    if (
      response.hasOwnProperty("status") &&
      response["status"] === vim.already_exist_status
    ) {
      return true;
    }
    return false;
  }
  /**
   * @method :- errorToasty
   */
  errorToasty(error) {
    const vim = this;
    if (error.hasOwnProperty("message")) {
      vim.toastr.error("Error!", error["message"]);
    } else {
      vim.toastr.error("Error!", "Somethink wrong!!!..");
    }
  }

  get_final(id, hospital_id, page, reading) {
    const vim = this;
    if (vim.temp_study_id == vim.id) {

    } else {
      vim.page = 1;
      vim.temp_study_id = vim.id;
    }
    const newdata = vim.common_api.get_tabs("patient/baby_final", id, hospital_id, page, reading);
    newdata.subscribe(
      response => {
        vim.success(response, "get_final");
        this.isFinalEdit = false;
      },
      error => {
        console.error("errro", error);
      }
    );
  }
  getReadingFormData(formData){
    this.responseArray[0]=formData;
    this.updateForm(this.responseArray[0]);
    this.isFinalEdit=true;
  }

  saveReadingFormData(formData){
    this.readingDataService.setReadingFormData('baby_final',formData);
  }

  onChanges(): void {
    this.babyFinalForm.statusChanges.subscribe(val => {
      if(val==='INVALID'){
        this.readingDataService.setFormValidationStatus('baby_final',false)
          if(this.readingDataObj!=undefined){
            this.babyFinalForm.value["reading"] = localStorage.getItem('reading');
            this.saveReadingFormData(this.babyFinalForm['value']);
          }
      }
      else{
        this.readingDataService.setFormValidationStatus('baby_final',true)
        if(this.readingDataObj!=undefined){
          this.babyFinalForm.value["reading"] = localStorage.getItem('reading');
          this.saveReadingFormData(this.babyFinalForm['value']);
        }
      }
    });
  }

  update_final_form() {
    var vim = this;
    vim.submitted = true;
    vim.transformDate(vim.babyFinalForm.value);
    if(vim.babyFinalForm.invalid) {
      return;
    } else {

      if (this.babyFinalForm.value["days_of_stay_in_hospital"] == '') {
        this.babyFinalForm.value["days_of_stay_in_hospital"] = 'NA';
      }
      
      if (this.babyFinalForm.value["discharge_status"] == '') {
        this.babyFinalForm.value["discharge_status"] = 'NA';
      }

      if (this.babyFinalForm.value["final_diagnosis_sepsis"] == '') {
        this.babyFinalForm.value["final_diagnosis_sepsis"] = 'NA';
      }

      if (this.babyFinalForm.value["final_diagnosis_meningitis"] == '') {
        this.babyFinalForm.value["final_diagnosis_meningitis"] = 'NA';
      }

      if (this.babyFinalForm.value["final_diagnosis_seizures"] == '') {
        this.babyFinalForm.value["final_diagnosis_seizures"] = 'NA';
      }

      if (this.babyFinalForm.value["final_diagnosis_rds"] == '') {
        this.babyFinalForm.value["final_diagnosis_rds"] = 'NA';
      }

      if (this.babyFinalForm.value["final_diagnosis_ttnb"] == '') {
        this.babyFinalForm.value["final_diagnosis_ttnb"] = 'NA';
      }

      if (this.babyFinalForm.value["final_diagnosis_jaundice"] == '') {
        this.babyFinalForm.value["final_diagnosis_jaundice"] = 'NA';
      }

      if (this.babyFinalForm.value["final_diagnosis_lbw"] == '') {
        this.babyFinalForm.value["final_diagnosis_lbw"] = 'NA';
      }

      if (this.babyFinalForm.value["final_lga_sga_aga_suspect"] == '') {
        this.babyFinalForm.value["final_lga_sga_aga_suspect"] = 'NA';
      }

      if (this.babyFinalForm.value["final_diagnosis_anemia"] == '') {
        this.babyFinalForm.value["final_diagnosis_anemia"] = 'NA';
      }

      if (this.babyFinalForm.value["final_diagnosis_dextochordia"] == '') {
        this.babyFinalForm.value["final_diagnosis_dextochordia"] = 'NA';
      }
      
      if (this.babyFinalForm.value["final_diagnosis_hypoglycemia"] == '') {
        this.babyFinalForm.value["final_diagnosis_hypoglycemia"] = 'NA';
      }
      
      if (this.babyFinalForm.value["final_diagnosis_hypocalcemia"] == '') {
        this.babyFinalForm.value["final_diagnosis_hypocalcemia"] = 'NA';
      }

      if (this.babyFinalForm.value["final_diagnosis_gastroenteritis"] == '') {
        this.babyFinalForm.value["final_diagnosis_gastroenteritis"] = 'NA';
      }
      
      if (this.babyFinalForm.value["final_diagnosis_perinatal_respiratory_depression"] == '') {
        this.babyFinalForm.value["final_diagnosis_perinatal_respiratory_depression"] = 'NA';
      }
      
      if (this.babyFinalForm.value["final_diagnosis_shock"] == '') {
        this.babyFinalForm.value["final_diagnosis_shock"] = 'NA';
      }
      if (this.babyFinalForm.value["final_diagnosis_feeding_intolerence"] == '') {
        this.babyFinalForm.value["final_diagnosis_feeding_intolerence"] = 'NA';
      }
      
      if (this.babyFinalForm.value["final_diagnosis_septic_arthritis"] == '') {
        this.babyFinalForm.value["final_diagnosis_septic_arthritis"] = 'NA';
      }
      
      if (this.babyFinalForm.value["final_diagnosis_endocarditis"] == '') {
        this.babyFinalForm.value["final_diagnosis_endocarditis"] = 'NA';
      }
      
      if (this.babyFinalForm.value["final_diagnosis_peritonitis"] == '') {
        this.babyFinalForm.value["final_diagnosis_peritonitis"] = 'NA';
      }
      
      if (this.babyFinalForm.value["final_diagnosis_soft_tissue_abscess"] == '') {
        this.babyFinalForm.value["final_diagnosis_soft_tissue_abscess"] = 'NA';
      }
      
      if (this.babyFinalForm.value["final_diagnosis_coagulopathy"] == '') {
        this.babyFinalForm.value["final_diagnosis_coagulopathy"] = 'NA';
      }
      
      if (this.babyFinalForm.value["final_diagnosis_uti"] == '') {
        this.babyFinalForm.value["final_diagnosis_uti"] = 'NA';
      }

      if (this.babyFinalForm.value["final_diagnosis_umblical_sepsis"] == '') {
        this.babyFinalForm.value["final_diagnosis_umblical_sepsis"] = 'NA';
      }

      if (this.babyFinalForm.value["final_diagnosis_bleeding_manifestation"] == '') {
        this.babyFinalForm.value["final_diagnosis_bleeding_manifestation"] = 'NA';
      }

      if (this.babyFinalForm.value["final_diagnosis_asphyxia"] == '') {
        this.babyFinalForm.value["final_diagnosis_asphyxia"] = 'NA';
      }

      if (this.babyFinalForm.value["final_diagnosis_pneumonia"] == '') {
        this.babyFinalForm.value["final_diagnosis_pneumonia"] = 'NA';
      }

      if (this.babyFinalForm.value["final_diagnosis_central_peripheral"] == '') {
        this.babyFinalForm.value["final_diagnosis_central_peripheral"] = 'NA';
      }

      if (this.babyFinalForm.value["final_diagnosis_hypoxia"] == '') {
        this.babyFinalForm.value["final_diagnosis_hypoxia"] = 'NA';
      }

      if (this.babyFinalForm.value["final_diagnosis_metabolic_acidosis"] == '') {
        this.babyFinalForm.value["final_diagnosis_metabolic_acidosis"] = 'NA';
      }

      if (this.babyFinalForm.value["final_diagnosis_eos_los"] == '') {
        this.babyFinalForm.value["final_diagnosis_eos_los"] = 'NA';
      }
      
      if (this.babyFinalForm.value["final_diagnosis_pulmonary_hemorrhage"] == '') {
        this.babyFinalForm.value["final_diagnosis_pulmonary_hemorrhage"] = 'NA';
      }

      if (this.babyFinalForm.value["final_diagnosis_thrombocytopenia"] == '') {
        this.babyFinalForm.value["final_diagnosis_thrombocytopenia"] = 'NA';
      }

      if (this.babyFinalForm.value["final_diagnosis_skin_pustules"] == '') {
        this.babyFinalForm.value["final_diagnosis_skin_pustules"] = 'NA';
      }

      if (this.babyFinalForm.value["final_diagnosis_other"] == '') {
        this.babyFinalForm.value["final_diagnosis_other"] = 'NA';
      }
      
    vim.common_api.updateFormData('patient/update/baby_final/', vim.id, vim.readingDataService.reading, vim.babyFinalForm.value,vim.loggedInUserId)
    .subscribe(result => {
      if(result['status'] != 200) {
        vim.toastr.error(result['message']);
      } else {
        vim.toastr.success(
          "",
          "Data Updated Succesfully"
        );
        vim.isEditClicked = false;
        vim.get_final(vim.dataServiceObj.study_id, vim.login_hospital['id'], this.page, vim.readingDataService.reading);
      }
    })
    }
  }
    openModal() {
    this.formRef = this.modalService.open(this.saveReadingContent, this.helper.ngbModalSmallOptions);
  }

  close() {
    this.formRef.close();
  }

  saveReading(){
    var vim = this;
   // console.log(this.readingDataService.getAllFormData(),'final forms save data')
    if(vim.validateAllFormData()){
      this.close();
      this.commonAsyn.showLoader();
      this.readingData=this.readingDataService.getAllFormData();
    //  console.log(this.readingData);
        const newUser = vim.common_api.create_new_reading(this.readingData,this.loggedInUserId);
        newUser.subscribe(
          response => {
           
          this.create_new_global_record(this.readingData); 
           if(response['status']!=200){
            vim.toastr.error('',response['message']);
            this.commonAsyn.isHide();
           }else{
             this.commonAsyn.isHide();
           vim.toastr.success('',response['message']);
           vim.readingDataService.clearReadingFormData();
          //  vim.readingDataService.reset();
          if(this.login_hospital['user_type']==this.constant.phc_worker){
            this.readingDataService.ashaPhcScore=true;
            this.readingDataService.reading=localStorage.getItem('reading');
          }
          this.readingDataService.showSaveReadingButton=true;
          vim.readingDataService.setActiveTab("baby-appearence")
           vim.router.navigate(['dashboard/baby-appearence']);
          }
          },
          error => {
            console.error("errro", error);
          }
        );
    }
  }
  validateAllFormData(){
    this.messageString='';
    this.invalidForm=false;
    this.allFormData=this.readingDataService.getFormValidationStatus();
    if( this.allFormData['baby_appears']==false){
      this.setMessage('Baby Appears')
         this.invalidForm=true;
    }
    if( this.allFormData['baby_antibiotic']==false){
     this.setMessage('Baby Antibiotic')
      this.invalidForm=true;
    }
    if( this.allFormData['baby_cns']==false){
     this.setMessage('Baby CNS')
     this.invalidForm=true;
   }
   if(this.allFormData['baby_cv']==false){
     this.setMessage('Baby Cardio Vascular')
     this.invalidForm=true;
   }
   if(this.allFormData['baby_git']==false){
     this.setMessage('Baby GIT')
     this.invalidForm=true;
   }
   if( this.allFormData['baby_investigation']==false){
     this.setMessage('Baby Investigation')
     this.invalidForm=true;
   }
   if( this.allFormData['baby_resp']==false){
     this.setMessage('Baby Respiratory')
     this.invalidForm=true;
   }
   if(this.allFormData['baby_final']==false){
     this.setMessage('Baby Final')
     this.invalidForm=true;
   }
   if(this.invalidForm){
     this.toastr.error('','You have some unfilled entries in ' + this.messageString +'.'+'Please check');
     return false;
   }
     return true;
  }

  setMessage(formName){
    if(this.messageString!==''){
          this.messageString=this.messageString+', '+formName;
    }
    else{
        this.messageString=formName;
    }
 }
 
 create_new_global_record(readData){
    
  this.common_api.create_new_global_record(readData).subscribe(res=>{
  });
 }

}
