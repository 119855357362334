import { Component, OnInit, Input, OnChanges,Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, NavigationEnd } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from "../../shared/service/common/common.service";
import { Common } from "../../shared/service/common/common";
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import * as _ from "underscore";
import { DataService } from '../../shared/service/data.service';
import {ReadingDataService} from '../../shared/service/reading-data.service';
import { AppConstant } from 'src/app/shared/constant/app-constant';
import * as moment from 'moment';
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";

@Component({
  selector: "app-baby-appear",
  templateUrl: "./baby-appear.component.html",
  styleUrls: ["./baby-appear.component.css"],
  providers: [NgbModalConfig, NgbModal, DatePipe]
})
export class BabyAppearComponent implements OnInit, OnChanges {
  babyApears: FormGroup;
  formRef: any;
  submitted = false;
  already_exist_status = 422;
  success_status = 200;
  page: number = 1;
  isBabyAppearEdit: boolean = true;
  isEditClicked: boolean = false;
  chkWeightAtBirth: boolean = true;
  chkBabyAppearance: boolean = true;
  chkBabySkinColour: boolean = true;
  chkBabyCry:boolean = true;
  chkHypotoniaAfter1MinFromBirth:boolean = true;
  chkHypotoniaAfter5MinFromBirth:boolean = true;
  chkExcessiveSleeping:boolean = true;
  chkHypothermiaPresent:boolean = true;
  chkFeedingStatus:boolean = true;
  chkPresenceOfConvulsions:boolean = true;
  chkJaundicePresent:boolean = true;
  chkBreastFeedingInitiation:boolean = true;
  chkKangarooMotherCare:boolean = true;
  chkUmbilicalRedness:boolean = true;
  chkUmbilicalEnduration:boolean = true;
  chkUmbilicalDischarge:boolean = true;
  chkSkinPustules:boolean = true;

  isCrySound: boolean = true;
  isHypothermiaUnit: boolean = true;
  //isTimeReading: boolean = true;
  //isDateReading: boolean = true;

  @Input() id;
  @Input() hospital_id;
  getDOA: any;
  todayDate: Date;
  
  @Output() callParent = new EventEmitter()
  subscription: Subscription;

  getMedicalRecordNumber: string;
  navigationSubscription;
loggedInUserId:number;
  temp_study_id = 0;
  login_hospital: any = {};
  responseArray = [];
  phcUser=false;
  public dataServiceObj;
  public readinDataObj; 
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private common_api: CommonService,
    private modalService: NgbModal,
    private commonAsyn: Common,
    private dataService: DataService,
    private datePipe: DatePipe,
    public readinDataService:ReadingDataService,private constant:AppConstant
  ) {
    this.dataServiceObj = dataService.getOption();
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.resetComponent();
      }
    });
  }
  ngOnDestroy() {
    if (this.navigationSubscription) {  
       this.navigationSubscription.unsubscribe();
    }
  }

  transformDate(date) {
    if (Object.prototype.toString.call(date['reading_date']) === "[object Date]") {
      date['reading_date'] = this.datePipe.transform(date['reading_date'], 'dd/MM/yyyy');
    }
  }

  ngOnInit() {
    //debugger;
    const vim = this;
    vim.dataServiceObj = vim.dataService.getOption();
    vim.readinDataObj=vim.readinDataService.getReadingFormData('baby_appears');
    vim.login_hospital = JSON.parse(localStorage.getItem("login_hospital"));
    vim.loggedInUserId=vim.login_hospital['user_id'];
    this.checkUser();
    vim.createForm(vim.dataServiceObj.study_id);
    vim.id = vim.dataServiceObj.study_id;
    this.getDOA = moment(vim.dataServiceObj.baby_date_of_admission).format('DD/MM/YYYY');
    this.getDOA = new Date(this.getDOA);
    
    this.todayDate = new Date();
    //this.get_final_result();
    // this.subscription = this.common_api.getMedicalRecordNumber().subscribe(message => {
    //   this.getMedicalRecordNumber = message.text;
    // });
    if(vim.readinDataObj!=undefined){
      vim.getMedicalRecordNumber=vim.dataServiceObj.baby_medical_record_number;
      vim.getReadingFormData(vim.readinDataObj);
    }
    if(vim.readinDataObj==undefined){
      if (vim.dataServiceObj.study_id != undefined) {
        vim.getMedicalRecordNumber=vim.dataServiceObj.baby_medical_record_number;
        vim.get_baby_apears(vim.dataServiceObj.study_id, vim.login_hospital['id'], vim.page, vim.readinDataService.reading);
      }
    }

    vim.temp_study_id = vim.id;
    
    vim. onChanges();
  }

  resetComponent(){
    const vim = this;
    vim.dataServiceObj = vim.dataService.getOption();
    vim.readinDataObj=vim.readinDataService.getReadingFormData('baby_appears');
    vim.login_hospital = JSON.parse(localStorage.getItem("login_hospital"));
    vim.loggedInUserId=vim.login_hospital['user_id'];
    this.checkUser();
    vim.createForm(vim.dataServiceObj.study_id);
    vim.id = vim.dataServiceObj.study_id;
    if(vim.readinDataObj!=undefined){
      vim.getMedicalRecordNumber=vim.dataServiceObj.baby_medical_record_number;
      vim.getReadingFormData(vim.readinDataObj);
    }
    if(vim.readinDataObj==undefined){
      if (vim.dataServiceObj.study_id != undefined) {
        vim.getMedicalRecordNumber=vim.dataServiceObj.baby_medical_record_number;
        vim.get_baby_apears(vim.dataServiceObj.study_id, vim.login_hospital['id'], vim.page, vim.readinDataService.reading);
      }
    }

    vim.temp_study_id = vim.id;
    vim. onChanges();
    this.isEditClicked=false;
  }

  checkUser(){
    if(this.login_hospital['user_type']==this.constant.phc_worker){
      this.phcUser=true;
    }
  }

  createForm(id) {
    const vim = this;
    vim.isCrySound = true;
    vim.chkBabyAppearance = true;
    vim.chkWeightAtBirth = true;
    vim.chkBabySkinColour = true;
    vim.chkBabyCry = true;
    vim.chkHypotoniaAfter1MinFromBirth = true;
    vim.chkHypotoniaAfter5MinFromBirth = true;
    vim.chkExcessiveSleeping = true;
    vim.chkHypothermiaPresent = true;
    vim.isHypothermiaUnit = true;
    vim.chkFeedingStatus = true;
    vim.chkPresenceOfConvulsions = true;
    vim.chkJaundicePresent = true;
    vim.chkBreastFeedingInitiation = true;
    vim.chkKangarooMotherCare = true;
    vim.chkUmbilicalRedness = true;
    vim.chkUmbilicalEnduration = true;
    vim.chkUmbilicalDischarge = true;
    vim.chkSkinPustules = true;

    vim.babyApears = vim.formBuilder.group({
      study_id: id,
      reading_date: ["", Validators.required],
      time_of_reading_hours: ["", Validators.required],
      time_of_reading_minute: ["", Validators.required],
      baby_weight_at_birth: ["", Validators.required],
      baby_weight_at_birth_unit: ["Kgs", Validators.required],
      baby_appearance: ["", Validators.required],
      baby_skin_colour: ["", [Validators.required]],
      baby_cry_sound: ["", Validators.required],
      baby_cry_sound_status: ["", Validators.required],
      hypotonia_muscular_response_one_min_after_birth: [
        "",
        Validators.required
      ],
      hypotonia_muscular_response_five_min_after_birth: [
        "",
        Validators.required
      ],
      excessive_sleeping: ["", Validators.required],
      hypothermia: ["", Validators.required],
      hypothermia_status: ["Centigrade", Validators.required],
      hypothermia_status_value: ["", Validators.required],
      baby_feeding_status: ["", Validators.required],
      baby_presence_of_convulsions: ["", Validators.required],
      baby_jaundice: ["", Validators.required],
      breast_feeding_initiation: ["", Validators.required],
      kangaroo_mother_care: ["", Validators.required],
      umbilical_discharge: ["", Validators.required],
      skin_pustules: ["", Validators.required],
      umbilical_redness:["",Validators.required],
      umbilical_enduration:["",Validators.required]
    });
  }

  updateForm(obj) {
    const vim = this;
    /* if (obj["reading_date"] == 'NA') {
      vim.isDateReading = false;
    } else {
      vim.isDateReading = true;
    } */

    //if (obj["time_of_reading_hours"] == 'NA' || obj["time_of_reading_minute"] == 'NA') {
     // vim.isTimeReading = false;
      //vim.babyApears.controls["time_of_reading_hours"].clearValidators();
     // vim.babyApears.controls["time_of_reading_hours"].updateValueAndValidity();
      //vim.babyApears.controls["time_of_reading_minute"].clearValidators();
      //vim.babyApears.controls["time_of_reading_minute"].updateValueAndValidity();
    //} else {
      //vim.isTimeReading = true;
    //}

    if (obj["baby_appearance"] == 'NA') {
      vim.chkBabyAppearance = false;
      vim.babyApears.controls["baby_appearance"].clearValidators();
      vim.babyApears.controls["baby_appearance"].updateValueAndValidity();
    } else {
      vim.chkBabyAppearance = true;
    }

    if (obj["baby_skin_colour"] == 'NA') {
      vim.chkBabySkinColour = false;
      vim.babyApears.controls["baby_skin_colour"].clearValidators();
      vim.babyApears.controls["baby_skin_colour"].updateValueAndValidity();
    } else {
      vim.chkBabySkinColour = true;
    }

    if (obj["baby_cry_sound"] == 'NA') {
      vim.chkBabyCry = false;
      vim.babyApears.controls["baby_cry_sound"].clearValidators();
      vim.babyApears.controls["baby_cry_sound"].updateValueAndValidity();
    } else {
      vim.chkBabyCry = true;
    }
       
    if (obj["baby_cry_sound_status"] == 'NA') {
      vim.isCrySound = false;
      vim.babyApears.controls["baby_cry_sound_status"].clearValidators();
      vim.babyApears.controls["baby_cry_sound_status"].updateValueAndValidity();
    } else {
      vim.isCrySound = true;
    }

    if (obj["hypotonia_muscular_response_one_min_after_birth"] == 'NA') {
      vim.chkHypotoniaAfter1MinFromBirth = false;
      vim.babyApears.controls["hypotonia_muscular_response_one_min_after_birth"].clearValidators();
      vim.babyApears.controls["hypotonia_muscular_response_one_min_after_birth"].updateValueAndValidity();
    } else {
      vim.chkHypotoniaAfter1MinFromBirth = true;
    }

    if (obj["hypotonia_muscular_response_five_min_after_birth"] == 'NA') {
      vim.chkHypotoniaAfter5MinFromBirth = false;
      vim.babyApears.controls["hypotonia_muscular_response_five_min_after_birth"].clearValidators();
      vim.babyApears.controls["hypotonia_muscular_response_five_min_after_birth"].updateValueAndValidity();
    } else {
      vim.chkHypotoniaAfter5MinFromBirth = true;
    }

    if (obj["excessive_sleeping"] == 'NA') {
      vim.chkExcessiveSleeping = false;
      vim.babyApears.controls["excessive_sleeping"].clearValidators();
      vim.babyApears.controls["excessive_sleeping"].updateValueAndValidity();
    } else {
      vim.chkExcessiveSleeping = true;
    }

    if (obj["hypothermia"] == 'NA') {
      vim.chkHypothermiaPresent = false;
      vim.babyApears.controls["hypothermia"].clearValidators();
      vim.babyApears.controls["hypothermia"].updateValueAndValidity();
    } else {
      vim.chkHypothermiaPresent = true;
    }

    if (obj["hypothermia_status_value"] == 'NA') {
      vim.babyApears.value["hypothermia_status_value"] = 'NA';
      this.isHypothermiaUnit = false;
      vim.babyApears.controls["hypothermia_status_value"].clearValidators();
      vim.babyApears.controls["hypothermia_status_value"].updateValueAndValidity();
      vim.babyApears.patchValue({
        hypothermia_status_value: 'NA'
      });
    } else {
      vim.babyApears.controls["hypothermia_status_value"].setValidators([Validators.required]);
      vim.babyApears.controls["hypothermia_status_value"].updateValueAndValidity();
      vim.babyApears.patchValue({
        hypothermia_status_value: ''
      })
      this.isHypothermiaUnit = true;

      vim.babyApears.patchValue({
        hypothermia_status_value: obj["hypothermia_status_value"]
      });
    }

    if (obj["baby_feeding_status"] == 'NA') {
      vim.chkFeedingStatus = false;
      vim.babyApears.controls["baby_feeding_status"].clearValidators();
      vim.babyApears.controls["baby_feeding_status"].updateValueAndValidity();
    } else {
      vim.chkFeedingStatus = true;
    }

    if (obj["baby_presence_of_convulsions"] == 'NA') {
      vim.chkPresenceOfConvulsions = false;
      vim.babyApears.controls["baby_presence_of_convulsions"].clearValidators();
      vim.babyApears.controls["baby_presence_of_convulsions"].updateValueAndValidity();
    } else {
      vim.chkPresenceOfConvulsions = true;
    }

    if (obj["baby_jaundice"] == 'NA') {
      vim.chkJaundicePresent = false;
      vim.babyApears.controls["baby_jaundice"].clearValidators();
      vim.babyApears.controls["baby_jaundice"].updateValueAndValidity();
    } else {
      vim.chkJaundicePresent = true;
    }

    if (obj["breast_feeding_initiation"] == 'NA') {
      vim.chkBreastFeedingInitiation = false;
      vim.babyApears.controls["breast_feeding_initiation"].clearValidators();
      vim.babyApears.controls["breast_feeding_initiation"].updateValueAndValidity();
    } else {
      vim.chkBreastFeedingInitiation = true;
    }

    if (obj["kangaroo_mother_care"] == 'NA') {
      vim.chkKangarooMotherCare = false;
      vim.babyApears.controls["kangaroo_mother_care"].clearValidators();
      vim.babyApears.controls["kangaroo_mother_care"].updateValueAndValidity();
    } else {
      vim.chkKangarooMotherCare = true;
    }

    if (obj["umbilical_redness"] == 'NA') {
      vim.chkUmbilicalRedness = false;
      vim.babyApears.controls["umbilical_redness"].clearValidators();
      vim.babyApears.controls["umbilical_redness"].updateValueAndValidity();
    } else {
      vim.chkUmbilicalRedness = true;
    }

    if (obj["umbilical_enduration"] == 'NA') {
      vim.chkUmbilicalEnduration = false;
      vim.babyApears.controls["umbilical_enduration"].clearValidators();
      vim.babyApears.controls["umbilical_enduration"].updateValueAndValidity();
    } else {
      vim.chkUmbilicalEnduration = true;
    }

    if (obj["skin_pustules"] == 'NA') {
      vim.chkSkinPustules = false;
      vim.babyApears.controls["skin_pustules"].clearValidators();
      vim.babyApears.controls["skin_pustules"].updateValueAndValidity();
    } else {
      vim.chkSkinPustules = true;
    }

    if (obj["umbilical_discharge"] == 'NA') {
      vim.chkUmbilicalDischarge = false;
      vim.babyApears.controls["umbilical_discharge"].clearValidators();
      vim.babyApears.controls["umbilical_discharge"].updateValueAndValidity();
    } else {
      vim.chkUmbilicalDischarge = true;
    }

    if(this.isEditClicked){
      obj["time_of_reading_hours"]= obj["time_of_reading_hours"];
      obj["time_of_reading_minute"]= obj["time_of_reading_minute"];
    }else{
      obj["time_of_reading_hours"]= "";
      obj["time_of_reading_minute"]= "";
    }
    vim.babyApears.patchValue({
      study_id: vim.id,
      reading_date: obj["reading_date"],
      time_of_reading_hours: obj["time_of_reading_hours"],
      time_of_reading_minute: obj["time_of_reading_minute"],
      baby_weight_at_birth: obj["baby_weight_at_birth"],
      baby_weight_at_birth_unit: obj["baby_weight_at_birth_unit"],
      baby_appearance: obj["baby_appearance"],
      baby_skin_colour: obj["baby_skin_colour"],
      baby_cry_sound: obj["baby_cry_sound"],
      baby_cry_sound_status: obj["baby_cry_sound_status"],
      hypotonia_muscular_response_one_min_after_birth:
        obj["hypotonia_muscular_response_one_min_after_birth"],
      hypotonia_muscular_response_five_min_after_birth:
        obj["hypotonia_muscular_response_five_min_after_birth"],
      excessive_sleeping: obj["excessive_sleeping"],
      hypothermia: obj["hypothermia"],
      hypothermia_status: obj["hypothermia_status"],
      baby_feeding_status: obj["baby_feeding_status"],
      baby_presence_of_convulsions: obj["baby_presence_of_convulsions"],
      baby_jaundice: obj["baby_jaundice"],
      breast_feeding_initiation: obj["breast_feeding_initiation"],
      kangaroo_mother_care: obj["kangaroo_mother_care"],
      umbilical_discharge: obj["umbilical_discharge"],
      hypothermia_status_value: obj["hypothermia_status_value"],
      skin_pustules: obj["skin_pustules"],
      umbilical_redness: obj["umbilical_redness"],
      umbilical_enduration: obj["umbilical_enduration"]
    });
  }

  onInputChange(event) {
    var vim = this;
    var target = event.target || event.srcElement || event.currentTarget;

    /* if (target.name == 'DateReading') {
      if (target.value == '2') {
        vim.isDateReading = false;
        vim.babyApears.patchValue({
          reading_date: 'NA'
        })
      } else {
        vim.babyApears.patchValue({
          reading_date: ''
        })
        vim.isDateReading = true;
      }
    } */

    if (target.name == 'baby_weight') {
      if (target.value == '2') {
        vim.chkWeightAtBirth = false;
        vim.babyApears.patchValue({
          baby_weight_at_birth: 'NA'
        })
        vim.babyApears.value["baby_weight_at_birth"] = 'NA';

        vim.babyApears.controls["baby_weight_at_birth"].clearValidators();
        vim.babyApears.controls["baby_weight_at_birth"].updateValueAndValidity();
      } else {
        vim.chkWeightAtBirth = true;
        vim.babyApears.controls["baby_weight_at_birth"].setValidators([Validators.required]);
        vim.babyApears.controls["baby_weight_at_birth"].updateValueAndValidity();
        vim.babyApears.patchValue({
          baby_weight_at_birth: ''
        })
      }
    }

    if (target.name == 'baby_appears') {
      if (target.value == '2') {
        vim.chkBabyAppearance = false;
        vim.babyApears.patchValue({
          baby_appearance: 'NA'
        })
        vim.babyApears.value["baby_appearance"] = 'NA';

        vim.babyApears.controls["baby_appearance"].clearValidators();
        vim.babyApears.controls["baby_appearance"].updateValueAndValidity();
      } else {
        vim.chkBabyAppearance = true;
        vim.babyApears.controls["baby_appearance"].setValidators([Validators.required]);
        vim.babyApears.controls["baby_appearance"].updateValueAndValidity();
        vim.babyApears.patchValue({
          baby_appearance: ''
        })
      }
    }

    if (target.name == 'baby_colour') {
      if (target.value == '2') {
        vim.chkBabySkinColour = false;
        vim.babyApears.patchValue({
          baby_skin_colour: 'NA'
        })
        vim.babyApears.value["baby_skin_colour"] = 'NA';

        vim.babyApears.controls["baby_skin_colour"].clearValidators();
        vim.babyApears.controls["baby_skin_colour"].updateValueAndValidity();
      } else {
        vim.chkBabySkinColour = true;
        vim.babyApears.controls["baby_skin_colour"].setValidators([Validators.required]);
        vim.babyApears.controls["baby_skin_colour"].updateValueAndValidity();
        vim.babyApears.patchValue({
          baby_skin_colour: ''
        })
      }
    }

    if (target.name == 'baby_cry') {
      if (target.value == '2') {
        vim.chkBabyCry = false;
        vim.babyApears.patchValue({
          baby_cry_sound: 'NA'
        })
        vim.babyApears.value["baby_cry_sound"] = 'NA';

        vim.babyApears.controls["baby_cry_sound"].clearValidators();
        vim.babyApears.controls["baby_cry_sound"].updateValueAndValidity();
      } else {
        vim.chkBabyCry = true;
        vim.babyApears.controls["baby_cry_sound"].setValidators([Validators.required]);
        vim.babyApears.controls["baby_cry_sound"].updateValueAndValidity();
        vim.babyApears.patchValue({
          baby_cry_sound: ''
        })
      }
    }

    if (target.name == 'cry_sound') {
      if (target.value == '2') {
        vim.isCrySound = false;
        vim.babyApears.patchValue({
          baby_cry_sound_status: 'NA'
        })
        vim.babyApears.value["baby_cry_sound_status"] = 'NA';

        vim.babyApears.controls["baby_cry_sound_status"].clearValidators();
        vim.babyApears.controls["baby_cry_sound_status"].updateValueAndValidity();
      } else {
        vim.isCrySound = true;
        vim.babyApears.controls["baby_cry_sound_status"].setValidators([Validators.required]);
        vim.babyApears.controls["baby_cry_sound_status"].updateValueAndValidity();
        vim.babyApears.patchValue({
          baby_cry_sound_status: ''
        })
      }
    }

    if (target.name == 'baby_hypotonia_muscular_response_one_min_after_birth') {
      if (target.value == '2') {
        vim.chkHypotoniaAfter1MinFromBirth = false;
        vim.babyApears.patchValue({
          hypotonia_muscular_response_one_min_after_birth: 'NA'
        })
        vim.babyApears.value["hypotonia_muscular_response_one_min_after_birth"] = 'NA';

        vim.babyApears.controls["hypotonia_muscular_response_one_min_after_birth"].clearValidators();
        vim.babyApears.controls["hypotonia_muscular_response_one_min_after_birth"].updateValueAndValidity();
      } else {
        vim.chkHypotoniaAfter1MinFromBirth = true;
        vim.babyApears.controls["hypotonia_muscular_response_one_min_after_birth"].setValidators([Validators.required]);
        vim.babyApears.controls["hypotonia_muscular_response_one_min_after_birth"].updateValueAndValidity();
        vim.babyApears.patchValue({
          hypotonia_muscular_response_one_min_after_birth: ''
        })
      }
    }

    if (target.name == 'baby_hypotonia_muscular_response_five_min_after_birth') {
      if (target.value == '2') {
        vim.chkHypotoniaAfter5MinFromBirth = false;
        vim.babyApears.patchValue({
          hypotonia_muscular_response_five_min_after_birth: 'NA'
        })
        vim.babyApears.value["hypotonia_muscular_response_five_min_after_birth"] = 'NA';

        vim.babyApears.controls["hypotonia_muscular_response_five_min_after_birth"].clearValidators();
        vim.babyApears.controls["hypotonia_muscular_response_five_min_after_birth"].updateValueAndValidity();
      } else {
        vim.chkHypotoniaAfter5MinFromBirth = true;
        vim.babyApears.controls["hypotonia_muscular_response_five_min_after_birth"].setValidators([Validators.required]);
        vim.babyApears.controls["hypotonia_muscular_response_five_min_after_birth"].updateValueAndValidity();
        vim.babyApears.patchValue({
          hypotonia_muscular_response_five_min_after_birth: ''
        })
      }
    }

    if (target.name == 'baby_excessive_sleeping') {
      if (target.value == '2') {
        vim.chkExcessiveSleeping = false;
        vim.babyApears.patchValue({
          excessive_sleeping: 'NA'
        })
        vim.babyApears.value["excessive_sleeping"] = 'NA';

        vim.babyApears.controls["excessive_sleeping"].clearValidators();
        vim.babyApears.controls["excessive_sleeping"].updateValueAndValidity();
      } else {
        vim.chkExcessiveSleeping = true;
        vim.babyApears.controls["excessive_sleeping"].setValidators([Validators.required]);
        vim.babyApears.controls["excessive_sleeping"].updateValueAndValidity();
        vim.babyApears.patchValue({
          excessive_sleeping: ''
        })
      }
    }

    if (target.name == 'baby_hypothermia') {
      if (target.value == '2') {
        vim.chkHypothermiaPresent = false;
        vim.babyApears.patchValue({
          hypothermia: 'NA'
        })
        vim.babyApears.value["hypothermia"] = 'NA';

        vim.babyApears.controls["hypothermia"].clearValidators();
        vim.babyApears.controls["hypothermia"].updateValueAndValidity();
      } else {
        vim.chkHypothermiaPresent = true;
        vim.babyApears.controls["hypothermia"].setValidators([Validators.required]);
        vim.babyApears.controls["hypothermia"].updateValueAndValidity();
        vim.babyApears.patchValue({
          hypothermia: ''
        })
      }
    }

    if (target.name == 'baby_hypothermia_status_value') {
      if (target.value == '2') {
        vim.isHypothermiaUnit = false;
        vim.babyApears.patchValue({
          hypothermia_status_value: 'NA'
        })
        vim.babyApears.value["hypothermia_status_value"] = 'NA';

        vim.babyApears.controls["hypothermia_status_value"].clearValidators();
        vim.babyApears.controls["hypothermia_status_value"].updateValueAndValidity();
      } else {
        vim.isHypothermiaUnit = true;
        vim.babyApears.controls["hypothermia_status_value"].setValidators([Validators.required]);
        vim.babyApears.controls["hypothermia_status_value"].updateValueAndValidity();
        vim.babyApears.patchValue({
          hypothermia_status_value: ''
        })
      }
    }

    if (target.name == 'baby_feeding') {
      if (target.value == '2') {
        vim.chkFeedingStatus = false;
        vim.babyApears.patchValue({
          baby_feeding_status: 'NA'
        })
        vim.babyApears.value["baby_feeding_status"] = 'NA';

        vim.babyApears.controls["baby_feeding_status"].clearValidators();
        vim.babyApears.controls["baby_feeding_status"].updateValueAndValidity();
      } else {
        vim.chkFeedingStatus = true;
        vim.babyApears.controls["baby_feeding_status"].setValidators([Validators.required]);
        vim.babyApears.controls["baby_feeding_status"].updateValueAndValidity();
        vim.babyApears.patchValue({
          baby_feeding_status: ''
        })
      }
    }

    if (target.name == 'Presence_of_convulsions') {
      if (target.value == '2') {
        vim.chkPresenceOfConvulsions = false;
        vim.babyApears.patchValue({
          baby_presence_of_convulsions: 'NA'
        })
        vim.babyApears.value["baby_presence_of_convulsions"] = 'NA';

        vim.babyApears.controls["baby_presence_of_convulsions"].clearValidators();
        vim.babyApears.controls["baby_presence_of_convulsions"].updateValueAndValidity();
      } else {
        vim.chkPresenceOfConvulsions = true;
        vim.babyApears.controls["baby_presence_of_convulsions"].setValidators([Validators.required]);
        vim.babyApears.controls["baby_presence_of_convulsions"].updateValueAndValidity();
        vim.babyApears.patchValue({
          baby_presence_of_convulsions: ''
        })
      }
    }

    if (target.name == 'Jaundice_Present') {
      if (target.value == '2') {
        vim.chkJaundicePresent = false;
        vim.babyApears.patchValue({
          baby_jaundice: 'NA'
        })
        vim.babyApears.value["baby_jaundice"] = 'NA';

        vim.babyApears.controls["baby_jaundice"].clearValidators();
        vim.babyApears.controls["baby_jaundice"].updateValueAndValidity();
      } else {
        vim.chkJaundicePresent = true;
        vim.babyApears.controls["baby_jaundice"].setValidators([Validators.required]);
        vim.babyApears.controls["baby_jaundice"].updateValueAndValidity();
        vim.babyApears.patchValue({
          baby_jaundice: ''
        })
      }
    }

    if (target.name == 'BreastFeedingInitiation') {
      if (target.value == '2') {
        vim.chkBreastFeedingInitiation = false;
        vim.babyApears.patchValue({
          breast_feeding_initiation: 'NA'
        })
        vim.babyApears.value["breast_feeding_initiation"] = 'NA';

        vim.babyApears.controls["breast_feeding_initiation"].clearValidators();
        vim.babyApears.controls["breast_feeding_initiation"].updateValueAndValidity();
      } else {
        vim.chkBreastFeedingInitiation = true;
        vim.babyApears.controls["breast_feeding_initiation"].setValidators([Validators.required]);
        vim.babyApears.controls["breast_feeding_initiation"].updateValueAndValidity();
        vim.babyApears.patchValue({
          breast_feeding_initiation: ''
        })
      }
    }

    if (target.name == 'Baby_kangaroo_mother_care') {
      if (target.value == '2') {
        vim.chkKangarooMotherCare = false;
        vim.babyApears.patchValue({
          kangaroo_mother_care: 'NA'
        })
        vim.babyApears.value["kangaroo_mother_care"] = 'NA';

        vim.babyApears.controls["kangaroo_mother_care"].clearValidators();
        vim.babyApears.controls["kangaroo_mother_care"].updateValueAndValidity();
      } else {
        vim.chkKangarooMotherCare = true;
        vim.babyApears.controls["kangaroo_mother_care"].setValidators([Validators.required]);
        vim.babyApears.controls["kangaroo_mother_care"].updateValueAndValidity();
        vim.babyApears.patchValue({
          kangaroo_mother_care: ''
        })
      }
    }

    if (target.name == 'Baby_umbilical_redness') {
      if (target.value == '2') {
        vim.chkUmbilicalRedness = false;
        vim.babyApears.patchValue({
          umbilical_redness: 'NA'
        })
        vim.babyApears.value["umbilical_redness"] = 'NA';

        vim.babyApears.controls["umbilical_redness"].clearValidators();
        vim.babyApears.controls["umbilical_redness"].updateValueAndValidity();
      } else {
        vim.chkUmbilicalRedness = true;
        vim.babyApears.controls["umbilical_redness"].setValidators([Validators.required]);
        vim.babyApears.controls["umbilical_redness"].updateValueAndValidity();
        vim.babyApears.patchValue({
          umbilical_redness: ''
        })
      }
    }

    if (target.name == 'Baby_umbilical_enduration') {
      if (target.value == '2') {
        vim.chkUmbilicalEnduration = false;
        vim.babyApears.patchValue({
          umbilical_enduration: 'NA'
        })
        vim.babyApears.value["umbilical_enduration"] = 'NA';

        vim.babyApears.controls["umbilical_enduration"].clearValidators();
        vim.babyApears.controls["umbilical_enduration"].updateValueAndValidity();
      } else {
        vim.chkUmbilicalEnduration = true;
        vim.babyApears.controls["umbilical_enduration"].setValidators([Validators.required]);
        vim.babyApears.controls["umbilical_enduration"].updateValueAndValidity();
        vim.babyApears.patchValue({
          umbilical_enduration: ''
        })
      }
    }

    if (target.name == 'Baby_umbilical_discharge') {
      if (target.value == '2') {
        vim.chkUmbilicalDischarge = false;
        vim.babyApears.patchValue({
          umbilical_discharge: 'NA'
        })
        vim.babyApears.value["umbilical_discharge"] = 'NA';

        vim.babyApears.controls["umbilical_discharge"].clearValidators();
        vim.babyApears.controls["umbilical_discharge"].updateValueAndValidity();
      } else {
        vim.chkUmbilicalDischarge = true;
        vim.babyApears.controls["umbilical_discharge"].setValidators([Validators.required]);
        vim.babyApears.controls["umbilical_discharge"].updateValueAndValidity();
        vim.babyApears.patchValue({
          umbilical_discharge: ''
        })
      }
    }

    if (target.name == 'Baby_skin_pustules') {
      if (target.value == '2') {
        vim.chkSkinPustules = false;
        vim.babyApears.patchValue({
          skin_pustules: 'NA'
        })
        vim.babyApears.value["skin_pustules"] = 'NA';

        vim.babyApears.controls["skin_pustules"].clearValidators();
        vim.babyApears.controls["skin_pustules"].updateValueAndValidity();
      } else {
        vim.chkSkinPustules = true;
        vim.babyApears.controls["skin_pustules"].setValidators([Validators.required]);
        vim.babyApears.controls["skin_pustules"].updateValueAndValidity();
        vim.babyApears.patchValue({
          skin_pustules: ''
        })
      }
    }

    /* if (target.name == 'TimeReading') {
      if (target.value == '2') {
        vim.isTimeReading = false;
        vim.babyApears.patchValue({
          time_of_reading_hours: 'NA',
          time_of_reading_minute: 'NA'
        })
        vim.babyApears.value["time_of_reading_hours"] = 'NA';
        vim.babyApears.value["time_of_reading_minute"] = 'NA';

        vim.babyApears.controls["time_of_reading_hours"].clearValidators();
        vim.babyApears.controls["time_of_reading_hours"].updateValueAndValidity();
        vim.babyApears.controls["time_of_reading_minute"].clearValidators();
        vim.babyApears.controls["time_of_reading_minute"].updateValueAndValidity();
      } else {
        vim.babyApears.controls["time_of_reading_hours"].setValidators([Validators.required]);
        vim.babyApears.controls["time_of_reading_hours"].updateValueAndValidity();
        vim.babyApears.controls["time_of_reading_minute"].setValidators([Validators.required]);
        vim.babyApears.controls["time_of_reading_minute"].updateValueAndValidity();
        vim.babyApears.patchValue({
          time_of_reading_hours: '',
          time_of_reading_minute: ''
        })
        vim.isTimeReading = true;
      }
    }*/
  } 

  // When scroll down the screen  
  onScroll() {
    const vim = this;
    this.page = this.page + 5;
    //this.get_baby_apears(vim.id, vim.hospital_id, vim.page);
  }

  ngOnChanges() {
    this.createForm(this.id);
  }
  reset() {
    this.createForm(null);
  }

  open(content, obj) {
    const vim = this;
    vim.submitted = false;
    if (!_.isEmpty(obj)) {
      this.isBabyAppearEdit = true;
      this.isEditClicked = true;
      vim.updateForm(obj);
    } else {
      this.babyApears.reset();
      this.isBabyAppearEdit = true;
      vim.createForm(this.id);
    }
  }
  close() {
  }

  babyApearsFormSubmit() {
    this.transformDate(this.babyApears.value);
    const vim = this;
    vim.submitted = true;
    
    if (vim.babyApears.invalid) {
      return;
    }

    if (this.babyApears.value["baby_appearance"] == '') {
      this.babyApears.value["baby_appearance"] = 'NA';
    }

    if (this.babyApears.value["baby_skin_colour"] == '') {
      this.babyApears.value["baby_skin_colour"] = 'NA';
    }

    if (this.babyApears.value["baby_cry_sound"] == '') {
      this.babyApears.value["baby_cry_sound"] = 'NA';
    }

    if (this.babyApears.value["baby_cry_sound_status"] == '') {
      this.babyApears.value["baby_cry_sound_status"] = 'NA';
    }

    if (this.babyApears.value["hypotonia_muscular_response_one_min_after_birth"] == '') {
      this.babyApears.value["hypotonia_muscular_response_one_min_after_birth"] = 'NA';
    }

    if (this.babyApears.value["hypotonia_muscular_response_five_min_after_birth"] == '') {
      this.babyApears.value["hypotonia_muscular_response_five_min_after_birth"] = 'NA';
    }

    if (this.babyApears.value["excessive_sleeping"] == '') {
      this.babyApears.value["excessive_sleeping"] = 'NA';
    }

    if (this.babyApears.value["hypothermia"] == '') {
      this.babyApears.value["hypothermia"] = 'NA';
    }

    if (this.babyApears.value["hypothermia_status_value"] == '') {
      this.babyApears.value["hypothermia_status_value"] = 'NA';
    }

    if (this.babyApears.value["baby_feeding_status"] == '') {
      this.babyApears.value["baby_feeding_status"] = 'NA';
    }

    if (this.babyApears.value["baby_presence_of_convulsions"] == '') {
      this.babyApears.value["baby_presence_of_convulsions"] = 'NA';
    }

    if (this.babyApears.value["baby_jaundice"] == '') {
      this.babyApears.value["baby_jaundice"] = 'NA';
    }

    if (this.babyApears.value["breast_feeding_initiation"] == '') {
      this.babyApears.value["breast_feeding_initiation"] = 'NA';
    }

    if (this.babyApears.value["kangaroo_mother_care"] == '') {
      this.babyApears.value["kangaroo_mother_care"] = 'NA';
    }

    if (this.babyApears.value["umbilical_redness"] == '') {
      this.babyApears.value["umbilical_redness"] = 'NA';
    }

    if (this.babyApears.value["umbilical_enduration"] == '') {
      this.babyApears.value["umbilical_enduration"] = 'NA';
    }

    if (this.babyApears.value["umbilical_discharge"] == '') {
      this.babyApears.value["umbilical_discharge"] = 'NA';
    }

    if (this.babyApears.value["skin_pustules"] == '') {
      this.babyApears.value["skin_pustules"] = 'NA';
    }

     /* if (this.babyApears.value["time_of_reading_hours"] == '') {
       this.babyApears.value["time_of_reading_hours"] = 'NA';
     } 

    if (this.babyApears.value["time_of_reading_minute"] == '') {
      this.babyApears.value["time_of_reading_minute"] = 'NA';
    }*/

    if (this.babyApears.value["baby_weight_at_birth"] == '') {
      this.babyApears.value["baby_weight_at_birth"] = 'NA';
    }
    // const newUser = vim.common_api.baby_appear_add(vim.babyApears.value);
    // newUser.subscribe(
    //   response => {
    //     vim.reset();
    //     vim.success(response, "babyApearsFormSubmit");
    //     vim.isBabyAppearEdit = false;
    //   },
    //   error => {
    //     console.error("errro", error);
    //   }
    // );
    this.babyApears.value["reading"] = localStorage.getItem('reading');
    vim.goToNextReadingForm();
  }

  /**
   *
   * @param response
   * @param api_type
   * @method: success
   * @purpose :-  it is a common helper
   */
  success(response, api_type) {
    const vim = this;
    if (api_type == "babyApearsFormSubmit") {
      if (vim.isSuccess(response)) {
        vim.toastr.success(
          "",
          "Information Updated succesfully"
        );
        vim.responseArray = [];
        this.page = 1;
        vim.dataServiceObj = vim.dataService.getOption();
        vim.get_baby_apears(vim.dataServiceObj.study_id, vim.login_hospital['id'], vim.page, vim.readinDataService.reading);
      } else {
        if (vim.isAlreadyExist(response)) {
          vim.toastr.warning("Already Exist!!", response["message"]);
        } else {
          vim.errorToasty(response);
        }
      }
    } else if (api_type == "get_baby_appears") {
      if (vim.isSuccess(response)) {
        if (this.page == 1) {
          vim.responseArray = [];
          vim.responseArray = response["response"];
          vim.isBabyAppearEdit=false;
        } else {
          if (response["status"] == 404) {
          }
          else if (response["response"].length > 0) {
            vim.temp_study_id = response["response"][0].study_id;
            if (vim.temp_study_id == vim.id) {
            } else {
              vim.responseArray = [];
            }

            for (var i = 0; i < response["response"].length; i++) {
              vim.responseArray.push(response["response"][i]);
              vim.temp_study_id = vim.id;
            }
          }
        }
        vim.commonAsyn.isHide();
      } else {
        vim.responseArray = [];
        vim.commonAsyn.isHide();
        if (vim.isAlreadyExist(response)) {
        } else {
        }
      }
    }
  }

  /**
   *
   * @param error
   * @param api_type
   * @purpose :-  This is error handler method is called.
   * @method: errorHandler
   */
  errorHandler(error, api_type) {
    const vim = this;
    if (api_type == "babyApearsFormSubmit") {
      vim.errorToasty(error);
    }
  }

  /**
   *
   * @param response
   * @method: it is a common herlper for check the status is 200 or not
   */
  isSuccess(response) {
    const vim = this;
    if (
      response.hasOwnProperty("status") &&
      response["status"] === vim.success_status
    ) {
      return true;
    } else if (response["status"] === 404) {
      return true;
    }
    return false;
  }
  /**
   *
   * @param response
   * @method :- isAlreadyExist
   * @purpose :- check if User Already Exist.
   */
  isAlreadyExist(response) {
    const vim = this;
    if (
      response.hasOwnProperty("status") &&
      response["status"] === vim.already_exist_status
    ) {
      return true;
    }
    return false;
  }
  /**
   * @method :- errorToasty
   */
  errorToasty(error) {
    const vim = this;
    if (error.hasOwnProperty("message")) {
      vim.toastr.error("Error!", error["message"]);
    } else {
      vim.toastr.error("Error!", "Somethink wrong!!!..");
    }
  }
  /**
   *
   * @param id
   */

  get_baby_apears(id, hospital_id, page, reading) {
    debugger
    const vim = this;
    if (vim.temp_study_id == vim.id) {

    } else {
      vim.page = 1;
      vim.temp_study_id = vim.id;
    }
    const newdata = vim.common_api.get_tabs("patient/baby_appears", id, hospital_id, page, reading);
    newdata.subscribe(
      response => {
        //this.get_final_result(vim.temp_study_id);
        vim.success(response, "get_baby_appears");
      },
      error => {
        console.error("errro", error);
      }
    );
  }


  changeDropdown(dropdownVal, dropdownId) {
    var vim = this;

    if (dropdownId == 'hypothermia_status_value_id') {
      if (dropdownVal == 'NA') {
        vim.isHypothermiaUnit = false;
        vim.babyApears.value["hypothermia_status_value"] = 'NA';
        vim.babyApears.controls["hypothermia_status_value"].clearValidators();
        vim.babyApears.controls["hypothermia_status_value"].updateValueAndValidity();
        vim.babyApears.patchValue({
          hypothermia_status_value: 'NA'
        });

      } else {
        vim.babyApears.controls["hypothermia_status_value"].setValidators([Validators.required]);
        vim.babyApears.controls["hypothermia_status_value"].updateValueAndValidity();
        vim.babyApears.patchValue({
          hypothermia_status_value: ''
        })
        vim.isHypothermiaUnit = true;
      }
    }

    if (dropdownId == 'babyWeightAtBirthId') {
      if (dropdownVal == 'NA') {
        vim.chkWeightAtBirth = false;
        vim.babyApears.patchValue({
          baby_weight_at_birth: 'NA'
        });

        vim.babyApears.value["baby_weight_at_birth"] = 'NA';
        vim.babyApears.controls["baby_weight_at_birth"].clearValidators();
        vim.babyApears.controls["baby_weight_at_birth"].updateValueAndValidity();
      } else {
        vim.chkWeightAtBirth = true;
        vim.babyApears.patchValue({
          baby_weight_at_birth: ''
        })
        vim.babyApears.controls["baby_weight_at_birth"].setValidators([Validators.required]);
        vim.babyApears.controls["baby_weight_at_birth"].updateValueAndValidity();
      }
    }
  }

  getReadingFormData(formData){
    this.responseArray[0]=formData;
    this.updateForm(this.responseArray[0]);
    this.isBabyAppearEdit=true;
    //console.log(this.responseArray[0],'baby_appears')
  }

  saveReadingFormData(formData){
    this.readinDataService.setReadingFormData('baby_appears',formData);
  }

  goToNextReadingForm(){
    let vim=this;
    vim.saveReadingFormData(this.babyApears['value']);
    vim.readinDataService.setComponentFlag('baby-resp')
    vim.readinDataService.setActiveTab("baby-respiratory")
    vim.router.navigate(["dashboard/baby-respiratory"]);
  }

  onChanges(): void {
    this.babyApears.statusChanges.subscribe(val => {
      if(val==='INVALID'){
        this.readinDataService.setFormValidationStatus('baby_appears',false)
          if(this.readinDataObj!=undefined){
            this.babyApears.value["reading"] = localStorage.getItem('reading');
            this.saveReadingFormData(this.babyApears['value']);
          }
      }
      else{
        this.readinDataService.setFormValidationStatus('baby_appears',true)
        if(this.readinDataObj!=undefined){
          this.babyApears.value["reading"] = localStorage.getItem('reading');
          this.saveReadingFormData(this.babyApears['value']);
        }
      }
    });
  }

  update_appears_form() {
    this.transformDate(this.babyApears.value);
    var vim = this;
    vim.submitted = true;
    if(vim.babyApears.invalid) {
      return;
    } else {

      if (this.babyApears.value["baby_appearance"] == '') {
        this.babyApears.value["baby_appearance"] = 'NA';
      }

      if (this.babyApears.value["baby_skin_colour"] == '') {
        this.babyApears.value["baby_skin_colour"] = 'NA';
      }

      if (this.babyApears.value["baby_cry_sound"] == '') {
        this.babyApears.value["baby_cry_sound"] = 'NA';
      }

      if (this.babyApears.value["baby_cry_sound_status"] == '') {
        this.babyApears.value["baby_cry_sound_status"] = 'NA';
      }

      if (this.babyApears.value["hypotonia_muscular_response_one_min_after_birth"] == '') {
        this.babyApears.value["hypotonia_muscular_response_one_min_after_birth"] = 'NA';
      }

      if (this.babyApears.value["hypotonia_muscular_response_five_min_after_birth"] == '') {
        this.babyApears.value["hypotonia_muscular_response_five_min_after_birth"] = 'NA';
      }

      if (this.babyApears.value["excessive_sleeping"] == '') {
        this.babyApears.value["excessive_sleeping"] = 'NA';
      }

      if (this.babyApears.value["hypothermia"] == '') {
        this.babyApears.value["hypothermia"] = 'NA';
      }

      if (this.babyApears.value["baby_feeding_status"] == '') {
        this.babyApears.value["baby_feeding_status"] = 'NA';
      }

      if (this.babyApears.value["baby_presence_of_convulsions"] == '') {
        this.babyApears.value["baby_presence_of_convulsions"] = 'NA';
      }
     
      if (this.babyApears.value["baby_jaundice"] == '') {
        this.babyApears.value["baby_jaundice"] = 'NA';
      }

      if (this.babyApears.value["breast_feeding_initiation"] == '') {
        this.babyApears.value["breast_feeding_initiation"] = 'NA';
      }

      if (this.babyApears.value["kangaroo_mother_care"] == '') {
        this.babyApears.value["kangaroo_mother_care"] = 'NA';
      }

      if (this.babyApears.value["umbilical_redness"] == '') {
        this.babyApears.value["umbilical_redness"] = 'NA';
      }

      if (this.babyApears.value["umbilical_enduration"] == '') {
        this.babyApears.value["umbilical_enduration"] = 'NA';
      }

      if (this.babyApears.value["umbilical_discharge"] == '') {
        this.babyApears.value["umbilical_discharge"] = 'NA';
      }

      if (this.babyApears.value["skin_pustules"] == '') {
        this.babyApears.value["skin_pustules"] = 'NA';
      }

     /*  if (this.babyApears.value["time_of_reading_hours"] == '') {
        this.babyApears.value["time_of_reading_hours"] = 'NA';
      } 
  
      if (this.babyApears.value["time_of_reading_minute"] == '') {
        this.babyApears.value["time_of_reading_minute"] = 'NA';
      }*/
  
      if (this.babyApears.value["hypothermia_status_value"] == '') {
        this.babyApears.value["hypothermia_status_value"] = 'NA';
      }

      if (this.babyApears.value["baby_weight_at_birth"] == '') {
        this.babyApears.value["baby_weight_at_birth"] = 'NA';
      }
      
    vim.common_api.updateFormData('patient/update/baby_appears/', vim.id, vim.readinDataService.reading, vim.babyApears.value,vim.loggedInUserId)
    .subscribe(result => {
      if(result['status'] != 200) {
        vim.toastr.error(result['message']);
      } else {
        vim.toastr.success(
          "",
          "Data Updated Succesfully"
        );
        vim.isEditClicked = false;
        vim.get_baby_apears(vim.dataServiceObj.study_id, vim.login_hospital['id'], this.page, vim.readinDataService.reading);
      }
    })
    }
  } 
}
