import { Component, OnInit, Input, OnChanges, AfterViewInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "../../shared/service/common/common.service";
import * as _ from "underscore";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { MalihuScrollbarService } from "ngx-malihu-scrollbar";
import { Common } from "../../shared/service/common/common";
import { Subscription } from 'rxjs';
declare var $: any;
import { NgxSpinnerService } from "ngx-spinner";
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from "ngx-perfect-scrollbar";
import { DatePipe } from '@angular/common';
import { AppConstant } from 'src/app/shared/constant/app-constant';
import { DataService } from '../../shared/service/data.service';
import { ReadingDataService } from '../../shared/service/reading-data.service';
import { AppHelper } from 'src/app/shared/helper/app.helper';
import { isGeneratedFile } from '@angular/compiler/src/aot/util';

@Component({
  selector: "app-general",
  templateUrl: "./general.component.html",
  styleUrls: ["./general.component.css"],
  providers: [NgbModalConfig, NgbModal, DatePipe]
})
export class GeneralComponent implements OnInit {
  generalForm: FormGroup;
  config1: PerfectScrollbarConfigInterface = {};
  submitted = false;
  already_exist_status = 422;
  success_status = 200;
  page: number = 1;

  @Input() id;
  hospital_id: number;
  subscription: Subscription;
  temp_study_id = 0;
  getMedicalRecordNumber: string;
  showMrNumber=false;
  login_hospital: any = {};
  hospital_name = '';
  hospital_branch_name = '';

  responseArray = [];
  formRef: any;
  pin_code: any = "";
  is_limit = false;
  is_api_call = true;

  chkBabyPlaceBirthPin: boolean = true;
  chkBabyPlaceBirthName: boolean = true;
  chkBabyDOB: boolean = true;
  chkadmissiontype: boolean = true;
  chkbabygender: boolean = true;
  chkBabyTimeOfBirth: boolean = true;
  chkBabyAgeAdmission: boolean = true;
  chkBabyApgarSc1: boolean = true;
  chkBabyApgarSc5: boolean = true;
  chkBabyApgarSc10: boolean = true;
  chkPlaceofdelivery: boolean = true;
  chkBabyGestationalAge: boolean = true;
  chkWeightAtBirth: boolean = true;
  chkBabyDayEvent: boolean = true;
  chkbabyconditionsuspect: boolean = true;
  chkbabyconditionyeseoslos: boolean = true;
  chkmeningitis: boolean = true;
  chkumblicalsepsis: boolean = true;
  chkskinpustules: boolean = true;
  chkbabyconditionrds: boolean = true;
  chkconditionjaundice: boolean = true;
  chkconditionyessuspect: boolean = true;
  chkconditionttnbsuspect: boolean = true;
  chkbabylgasgaagasuspect: boolean = true;
  chkbabyshockagasuspect: boolean = true;
  chkbabyconditiondextrocordiasuspect: boolean = true;
  chkbabyconditionanemiasuspect: boolean = true;
  chkprelimdiagnosisperinatal: boolean = true;
  chkprelimdiagnosishypoglycemia: boolean = true;
  chkprelimdiagnosishypocalcemia: boolean = true;
  chkbabyprelimdiagnosisfeedingintolerence: boolean = true;
  chkthrombocytopenia: boolean = true;
  chkseizures: boolean = true;
  chkprelimdiagnosisgastroenteritis: boolean = true;
  chkasphyxia: boolean = true;
  chkpneumonia: boolean = true;
  chksepticarthritis: boolean = true;
  chkendocarditis: boolean = true;
  chkperitonitis: boolean = true;
  chksofttissueabscess: boolean = true;
  chkcoagulopathy: boolean = true;
  chkuti: boolean = true;
  chkpulmonaryhemorrhage: boolean = true;
  chkbleedingmanifestation: boolean = true;
  chkcentralperipheral: boolean = true;
  chkhypoxia: boolean = true;
  chkmetabolicacidosis: boolean = true;
  chkBabyDateAdmission: boolean = true;
  chkBabyCondOnSuspectOtherIfAny: boolean = true;
  chkWeightAtAdmission: boolean = true;
  is_update: boolean = false;
  isDisableDiv = false;
  motherMRNo=true;
  radiochecked: boolean = true;
  radiochecked1: boolean = false;
  chkBabyName:boolean=true;
  chkMotherName:boolean=true;
  pretermArr = ['Yes', 'No'];
  content:any;
  navigationSubscription;
  model: NgbDateStruct;
  isHide = true;
  custom_date: { year: number; month: number };
  isBabyEditGeneral: boolean = false;
  isBabyCreateGeneral: boolean = false;
  public dataServiceObj;
  public scrollbarOptions = { axis: "yx", theme: "minimal-dark" };
  updateFlag=false;
  studyId:number;
  public babyReadingData;
  public customPatterns = { 'S': { pattern: new RegExp('\[a-zA-Z,/\]') } };
  public specialCharPatterns = { 'S': { pattern: new RegExp('\[a-zA-Z, /\]') } };
  public onlyNumbersWithComma = { 'S': { pattern: new RegExp('\[0-9,\]') } };

  babyMedicalRecordNumber:string;
  motherMedicalRecordNumber:string;loggedInUserId:number;
  bmrEdit=true;
  createPatient={};
  ashaUser=false;
  phcUser=false;
  todayDate: Date;
  @Output() tabsEvent: EventEmitter<any> = new EventEmitter();
  
  constructor(private formBuilder: FormBuilder, private router: Router, private toastr: ToastrService,
    private common_api: CommonService, public config: NgbModalConfig, private modalService: NgbModal,
    private commonAsyn: Common, private spinner: NgxSpinnerService, private datePipe: DatePipe, private appConstant: AppConstant,
    private dataService: DataService,
    public readingDataService: ReadingDataService, private route: ActivatedRoute,private helper:AppHelper) {
    this.dataServiceObj = dataService.getOption();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.resetComponent();
      }
    });
    this.todayDate = new Date();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {  
       this.navigationSubscription.unsubscribe();
    }
  }

  resetComponent(){
    const vim = this;
    vim.dataServiceObj = vim.dataService.getOption();
    vim.babyReadingData=JSON.parse(localStorage.getItem('staffMedicalRecord'));

    if (!( _.isEmpty(vim.babyReadingData)) && ( _.isEmpty(vim.dataServiceObj))) {
      vim.id=vim.babyReadingData['study_id'];
      vim.hospital_id=vim.babyReadingData['hospital_id']
      vim.babyMedicalRecordNumber=vim.babyReadingData['baby_medical_record_number']
      vim.motherMedicalRecordNumber=vim.babyReadingData['baby_mother_medical_record_number'];
      vim.dataServiceObj=vim.babyReadingData;
    }
    vim.isBabyEditGeneral=false;
    vim.is_api_call = true;
    vim.login_hospital = JSON.parse(localStorage.getItem("login_hospital"));
    vim.loggedInUserId=vim.login_hospital['user_id'];
    vim.hospital_name = vim.login_hospital.hospital_name;
    vim.hospital_branch_name = vim.login_hospital.hospital_branch_name;

    //for asha/PHC worker requirement
    if(vim.login_hospital['user_type'] == vim.appConstant.asha_worker){
      vim.bmrEdit=false;
      vim.ashaUser=true;
      vim.login_hospital['id']=vim.login_hospital['hospital_id'];
    }
    else if(vim.login_hospital['user_type'] == vim.appConstant.phc_worker){
      vim.bmrEdit=false;
      vim.phcUser=true;
      vim.login_hospital['id']=vim.login_hospital['hospital_id'];
    }
    else{
      vim.ashaUser=false;
      vim.bmrEdit=true;
    }


    if (vim.dataServiceObj != undefined || vim.dataServiceObj.study_id != undefined) {
      vim.showMrNumber=true;
      vim.getMedicalRecordNumber=vim.dataServiceObj.baby_medical_record_number;
      vim.get_general(vim.dataServiceObj.study_id,  vim.login_hospital['id'], vim.page);
    }

    vim.readingDataService.openForm.subscribe(message => {
     if(message==="openBabyProfileForm")
      vim.open(vim.content,{});
    })

    $('body').on('paste', 'input, textarea', function (e) {
      e.preventDefault()
    });
  }

  transformDate(date) {
    if (Object.prototype.toString.call(date['baby_birth_date']) === "[object Date]") {
      date['baby_birth_date'] = this.datePipe.transform(date['baby_birth_date'], 'dd/MM/yyyy');
    }
    if (Object.prototype.toString.call(date['baby_date_of_admission']) === "[object Date]") {
      date['baby_date_of_admission'] = this.datePipe.transform(date['baby_date_of_admission'], 'dd/MM/yyyy');
    }
  }

  ngOnInit() {
    const vim = this;
    vim.dataServiceObj = vim.dataService.getOption();
    vim.babyReadingData=JSON.parse(localStorage.getItem('staffMedicalRecord'));
    if (!( _.isEmpty(vim.babyReadingData)) && ( _.isEmpty(vim.dataServiceObj))) {
      vim.id=vim.babyReadingData['study_id'];
      vim.hospital_id=vim.babyReadingData['hospital_id']
      vim.babyMedicalRecordNumber=vim.babyReadingData['baby_medical_record_number']
      vim.motherMedicalRecordNumber=vim.babyReadingData['baby_mother_medical_record_number'];
      vim.dataServiceObj=vim.babyReadingData;
    }
    vim.is_api_call = true;
    vim.isBabyEditGeneral=false;
    vim.login_hospital = JSON.parse(localStorage.getItem("login_hospital"));
    vim.loggedInUserId=vim.login_hospital['user_id'];
    vim.hospital_name = vim.login_hospital.hospital_name;
    vim.hospital_branch_name = vim.login_hospital.hospital_branch_name;
    //for asha/PHC worker requirement
    if(vim.login_hospital['user_type'] == vim.appConstant.asha_worker){
      vim.bmrEdit=false;
      vim.ashaUser=true;
      vim.login_hospital['id']=vim.login_hospital['hospital_id'];
    }
    else if(vim.login_hospital['user_type'] == vim.appConstant.phc_worker){
      vim.bmrEdit=false;
      vim.phcUser=true;
      vim.login_hospital['id']=vim.login_hospital['hospital_id'];
    }
    else{
      vim.ashaUser=false;
      vim.bmrEdit=true;
    }
    if (vim.dataServiceObj != undefined || vim.dataServiceObj.study_id != undefined) {

      vim.get_general(vim.dataServiceObj.study_id, vim.login_hospital['id'], vim.page);
    }

    vim.readingDataService.openForm.subscribe(message => {
      console.log(message);
      if(message==="openBabyProfileForm")
       vim.open(vim.content,{});
     })

    $('body').on('paste', 'input, textarea', function (e) {
      e.preventDefault()
    });
  }

  ngOnChanges() {
    this.page = 1;
    this.is_api_call = true;
    this.createForm(this.id);
    this.hospital_id = this.hospital_id;
    this.isBabyEditGeneral = false;
    this.isBabyCreateGeneral = false;
  }

  checkLength(val) {
    const numbers = /^[0-9]+$/;
    if (numbers.test(val)) {
      const vim = this;
      if (val.length > 6) {
        vim.is_limit = true;
      } else {
        vim.is_limit = false;
      }
    } else {
      this.generalForm["control"]["baby_place_of_birth_pin_code"]["value"] = "";
      this.generalForm["control"]["baby_place_of_birth_pin_code"]["errors"][
        "pattern"
      ] = false;
    }
    // return true;
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.generalForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  createForm(id) {
    const vim = this;
    const numbers = /^[0-9]+$/;

    vim.chkBabyPlaceBirthPin = true;
    vim.chkBabyPlaceBirthName = true;
    vim.chkBabyDOB = true;
    vim.chkadmissiontype = true;
    vim.chkbabygender = true;
    vim.chkBabyTimeOfBirth = true;
    vim.chkBabyAgeAdmission = true;
    vim.chkBabyApgarSc1 = true;
    vim.chkBabyApgarSc5 = true;
    vim.chkBabyApgarSc10 = true;
    vim.chkPlaceofdelivery = true;
    vim.chkBabyGestationalAge = true;
    vim.chkWeightAtBirth = true;
    vim.chkWeightAtAdmission = true;
    vim.chkBabyDayEvent = true;
    vim.chkbabyconditionsuspect = true;
    vim.chkbabyconditionyeseoslos = true;
    vim.chkmeningitis = true;
    vim.chkumblicalsepsis = true;
    vim.chkskinpustules = true;
    vim.chkbabyconditionrds = true;
    vim.chkconditionjaundice = true;
    vim.chkconditionyessuspect = true;
    vim.chkconditionttnbsuspect = true;
    vim.chkbabylgasgaagasuspect = true;
    vim.chkbabyshockagasuspect = true;
    vim.chkbabyconditiondextrocordiasuspect = true;
    vim.chkbabyconditionanemiasuspect = true;
    vim.chkprelimdiagnosisperinatal = true;
    vim.chkprelimdiagnosishypoglycemia = true;
    vim.chkprelimdiagnosishypocalcemia = true;
    
    vim.chkbabyprelimdiagnosisfeedingintolerence = true;
    vim.chkthrombocytopenia = true;
    vim.chkseizures = true;
    vim.chkprelimdiagnosisgastroenteritis = true;
    vim.chkasphyxia = true;
    vim.chkpneumonia = true;
    vim.chksepticarthritis = true;
    vim.chkendocarditis = true;
    vim.chkperitonitis = true;
    vim.chksofttissueabscess = true;
    vim.chkcoagulopathy = true;

    vim.chkuti = true;
    vim.chkpulmonaryhemorrhage = true;
    vim.chkbleedingmanifestation = true;
    vim.chkcentralperipheral = true;
    vim.chkhypoxia = true;
    vim.chkmetabolicacidosis = true;

    vim.chkBabyDateAdmission = true;
    vim.chkBabyCondOnSuspectOtherIfAny = true;
    vim.motherMRNo=true;
    vim.chkBabyName=true;
    vim.chkMotherName=true;

    vim.generalForm = vim.formBuilder.group({
      hospital_id: vim.hospital_id,
      hospital_name: vim.hospital_name,
      hospital_branch_name: vim.hospital_branch_name,
      babyMedicalRecord: [vim.babyMedicalRecordNumber, Validators.required],
      babyMotherMedicalRecord: [vim.motherMedicalRecordNumber, Validators.required],
      record_type: ["", Validators.required],
      baby_admission_type: ["", Validators.required],
      baby_birth_date: ["", Validators.required],
      study_id: [vim.id],
      baby_place_of_birth_pin_code: [
        vim.pin_code,
        [Validators.required, Validators.minLength(6)]
      ],

      baby_place_of_birth_name: ["", Validators.required],
      baby_birth_time_hours: ["", Validators.required],
      baby_birth_time_minit: ["", Validators.required],
      baby_age_of_admission: ["", Validators.required],
      baby_apgar_score_one_min: ["", Validators.required],
      baby_apgar_score_five_min: ["", Validators.required],
      baby_apgar_score_ten_min: ["", Validators.required],
      baby_preterm: ["", Validators.required],
      baby_condition_yes_eos_los: ["", Validators.required],
      baby_condition_rds_yes_no: ["", Validators.required],
      baby_gender: ["", Validators.required],
      baby_condition_jaundice_suspect: ["", Validators.required],
      baby_condition_ttnb_suspect: ["", Validators.required],
      baby_lga_sga_aga_suspect: ["", Validators.required],
      baby_shock_aga_suspect: ["", Validators.required],
      baby_condition_dextrocordia_suspect: ["", Validators.required],
      baby_condition_anemia_suspect: ["", Validators.required],
      baby_condition_lbw_suspect: ["", Validators.required],
      place_of_delivery: ["", Validators.required],
      birth_facility: [""],
      baby_gestational_age: ["", Validators.required],
      baby_gestational_age_unit: ["week"],
      baby_weight_at_birth: ["", Validators.required],
      baby_weight_at_birth_unit: ["Kgs", Validators.required],
      baby_condition_suspect: ["", Validators.required],
      baby_day_of_event: ["", Validators.required],
      baby_weight_at_admission: ["", Validators.required],
      baby_condition_other_if_suspect: ["", Validators.required],
      prelim_diagnosis_perinatal: ["", Validators.required],
      prelim_diagnosis_hypoglycemia: ["", Validators.required],
      prelim_diagnosis_hypocalcemia: ["", Validators.required],
      prelim_diagnosis_feeding_intolerence: ["", Validators.required],
      prelim_diagnosis_gastroenteritis: ["", Validators.required],
      baby_weight_at_admission_unit: ["Kgs", Validators.required],
      baby_date_of_admission: ["", Validators.required],
      baby_name: [""],
      baby_mother_name: [""],
 	    meningitis:["", Validators.required],
      asphyxia:["", Validators.required],
      septic_arthritis:["", Validators.required],
      endocarditis:["", Validators.required],
      peritonitis:["", Validators.required],
      soft_tissue_abscess:["", Validators.required],
      coagulopathy:["", Validators.required],
      uti:["", Validators.required],
      umblical_sepsis:["", Validators.required],
      bleeding_manifestation:["", Validators.required],
      central_peripheral:["", Validators.required],
      hypoxia:["", Validators.required],
      metabolic_acidosis:["", Validators.required],
      skin_pustules:["",[Validators.required]],
      seizures:["",Validators.required],
      pulmonary_hemorrhage:["",Validators.required],
      thrombocytopenia:["",Validators.required],
      pneumonia:["",Validators.required]
    });

  }

  updateForm(obj) {
    const vim = this;

    if (obj["baby_weight_at_admission"] == 'NA') {
      vim.generalForm.value["baby_weight_at_admission"] = 'NA';
      this.chkWeightAtAdmission = false;

      vim.generalForm.controls["baby_weight_at_admission"].clearValidators();
      vim.generalForm.controls["baby_weight_at_admission"].updateValueAndValidity();
      vim.generalForm.patchValue({
        baby_weight_at_admission: 'NA'
      });
    } else {
      vim.generalForm.controls["baby_weight_at_admission"].clearValidators();
      vim.generalForm.controls["baby_weight_at_admission"].updateValueAndValidity();
      vim.generalForm.patchValue({
        baby_weight_at_admission: ''
      })
      this.chkWeightAtAdmission = true;

      vim.generalForm.patchValue({
        baby_weight_at_admission: obj["baby_weight_at_admission"]
      })
    }

    if (obj["baby_weight_at_birth"] == 'NA') {

      vim.generalForm.value["baby_weight_at_birth"] = 'NA';
      this.chkWeightAtBirth = false;

      vim.generalForm.controls["baby_weight_at_birth"].clearValidators();
      vim.generalForm.controls["baby_weight_at_birth"].updateValueAndValidity();

      vim.generalForm.patchValue({
        baby_weight_at_birth: 'NA'
      });
    } else {
      vim.generalForm.controls["baby_weight_at_birth"].setValidators([Validators.required]);
      vim.generalForm.controls["baby_weight_at_birth"].updateValueAndValidity();
      vim.generalForm.patchValue({
        baby_weight_at_birth: ''
      })

      this.chkWeightAtBirth = true;
      vim.generalForm.patchValue({
        baby_weight_at_birth: obj["baby_weight_at_birth"]
      })

    }

    if (obj["baby_place_of_birth_pin_code"] == 'NA') {
      vim.chkBabyPlaceBirthPin = false;
      vim.generalForm.controls["baby_place_of_birth_pin_code"].clearValidators();
      vim.generalForm.controls["baby_place_of_birth_pin_code"].updateValueAndValidity();
    } else {
      vim.chkBabyPlaceBirthPin = true;
      vim.generalForm.controls["baby_place_of_birth_pin_code"].setValidators([Validators.required, Validators.minLength(6)]);
      vim.generalForm.controls["baby_place_of_birth_pin_code"].updateValueAndValidity();
    }

    if (obj["baby_place_of_birth_name"] == 'NA') {
      vim.chkBabyPlaceBirthName = false;
    } else {
      vim.chkBabyPlaceBirthName = true;
    }

    if (obj["baby_birth_date"] == 'NA') {
      vim.chkBabyDOB = false;
    } else {
      vim.chkBabyDOB = true;
    }
    
    if (obj["baby_admission_type"] == 'NA') {
      vim.chkadmissiontype = false;
    } else {
      vim.chkadmissiontype = true;
    }
    
    if (obj["baby_gender"] == 'NA') {
      vim.chkbabygender = false;
    } else {
      vim.chkbabygender = true;
    }

    if (obj["baby_birth_time_hours"] == 'NA') {
      vim.chkBabyTimeOfBirth = false;
    } else {
      vim.chkBabyTimeOfBirth = true;
    }

    if (obj["baby_age_of_admission"] == 'NA') {
      vim.chkBabyAgeAdmission = false;
    } else {
      vim.chkBabyAgeAdmission = true;
    }

    if (obj["baby_apgar_score_one_min"] == 'NA') {
      vim.chkBabyApgarSc1 = false;
    } else {
      vim.chkBabyApgarSc1 = true;
    }

    if (obj["baby_apgar_score_five_min"] == 'NA') {
      vim.chkBabyApgarSc5 = false;
    } else {
      vim.chkBabyApgarSc5 = true;
    }

    if (obj["baby_apgar_score_ten_min"] == 'NA') {
      vim.chkBabyApgarSc10 = false;
    } else {
      vim.chkBabyApgarSc10 = true;
    }
    
    if (obj["place_of_delivery"] == 'NA') {
      vim.chkPlaceofdelivery = false;
    } else {
      vim.chkPlaceofdelivery = true;
    }

    if (obj["baby_gestational_age"] == 'NA') {
      vim.chkBabyGestationalAge = false;
    } else {
      vim.chkBabyGestationalAge = true;
    }

    
    if (obj["baby_weight_at_birth"] == 'NA') {
      vim.chkWeightAtBirth = false;
    } else {
      vim.chkWeightAtBirth = true;
    }

    if (obj["baby_weight_at_admission"] == 'NA') {
      vim.chkWeightAtAdmission = false;
    } else {
      vim.chkWeightAtAdmission = true;
    }

    if (obj["baby_day_of_event"] == 'NA') {
      vim.chkBabyDayEvent = false;
    } else {
      vim.chkBabyDayEvent = true;
    }

    if (obj["baby_condition_suspect"] == 'NA') {
      vim.chkbabyconditionsuspect = false;
      vim.generalForm.controls["baby_condition_suspect"].clearValidators();
      vim.generalForm.controls["baby_condition_suspect"].updateValueAndValidity();
    } else {
      vim.chkbabyconditionsuspect = true;
      vim.generalForm.controls["baby_condition_suspect"].setValidators([Validators.required]);
      vim.generalForm.controls["baby_condition_suspect"].updateValueAndValidity();
    }
    
    if (obj["baby_condition_yes_eos_los"] == 'NA') {
      vim.chkbabyconditionyeseoslos = false;
      vim.generalForm.controls["baby_condition_yes_eos_los"].clearValidators();
      vim.generalForm.controls["baby_condition_yes_eos_los"].updateValueAndValidity();
    } else {
      vim.chkbabyconditionyeseoslos = true;
      vim.generalForm.controls["baby_condition_yes_eos_los"].setValidators([Validators.required]);
      vim.generalForm.controls["baby_condition_yes_eos_los"].updateValueAndValidity();
    }

    if (obj["meningitis"] == 'NA') {
      vim.chkmeningitis = false;
      vim.generalForm.controls["meningitis"].clearValidators();
      vim.generalForm.controls["meningitis"].updateValueAndValidity();
    } else {
      vim.chkmeningitis = true;
      vim.generalForm.controls["meningitis"].setValidators([Validators.required]);
      vim.generalForm.controls["meningitis"].updateValueAndValidity();
    }

    if (obj["umblical_sepsis"] == 'NA') {
      vim.chkumblicalsepsis = false;
      vim.generalForm.controls["umblical_sepsis"].clearValidators();
      vim.generalForm.controls["umblical_sepsis"].updateValueAndValidity();
    } else {
      vim.chkumblicalsepsis = true;
      vim.generalForm.controls["umblical_sepsis"].setValidators([Validators.required]);
      vim.generalForm.controls["umblical_sepsis"].updateValueAndValidity();
    }
    
    if (obj["skin_pustules"] == 'NA') {
      vim.chkskinpustules = false;
      vim.generalForm.controls["skin_pustules"].clearValidators();
      vim.generalForm.controls["skin_pustules"].updateValueAndValidity();
    } else {
      vim.chkskinpustules = true;
      vim.generalForm.controls["skin_pustules"].setValidators([Validators.required]);
      vim.generalForm.controls["skin_pustules"].updateValueAndValidity();
    }
    
    if (obj["baby_condition_jaundice_suspect"] == 'NA') {
      vim.chkconditionjaundice = false;
      vim.generalForm.controls["baby_condition_jaundice_suspect"].clearValidators();
      vim.generalForm.controls["baby_condition_jaundice_suspect"].updateValueAndValidity();
    } else {
      vim.chkconditionjaundice = true;
      vim.generalForm.controls["baby_condition_jaundice_suspect"].setValidators([Validators.required]);
      vim.generalForm.controls["baby_condition_jaundice_suspect"].updateValueAndValidity();
    }
    
    if (obj["baby_condition_lbw_suspect"] == 'NA') {
      vim.chkconditionyessuspect = false;
      vim.generalForm.controls["baby_condition_lbw_suspect"].clearValidators();
      vim.generalForm.controls["baby_condition_lbw_suspect"].updateValueAndValidity();
    } else {
      vim.chkconditionyessuspect = true;
      vim.generalForm.controls["baby_condition_lbw_suspect"].setValidators([Validators.required]);
      vim.generalForm.controls["baby_condition_lbw_suspect"].updateValueAndValidity();
    }

    if (obj["baby_condition_ttnb_suspect"] == 'NA') {
      vim.chkconditionttnbsuspect = false;
      vim.generalForm.controls["baby_condition_ttnb_suspect"].clearValidators();
      vim.generalForm.controls["baby_condition_ttnb_suspect"].updateValueAndValidity();
    } else {
      vim.chkconditionttnbsuspect = true;
      vim.generalForm.controls["baby_condition_ttnb_suspect"].setValidators([Validators.required]);
      vim.generalForm.controls["baby_condition_ttnb_suspect"].updateValueAndValidity();
    }
    
    if (obj["baby_lga_sga_aga_suspect"] == 'NA') {
      vim.chkbabylgasgaagasuspect = false;
      vim.generalForm.controls["baby_lga_sga_aga_suspect"].clearValidators();
      vim.generalForm.controls["baby_lga_sga_aga_suspect"].updateValueAndValidity();
    } else {
      vim.chkbabylgasgaagasuspect = true;
      vim.generalForm.controls["baby_lga_sga_aga_suspect"].setValidators([Validators.required]);
      vim.generalForm.controls["baby_lga_sga_aga_suspect"].updateValueAndValidity();
    }
    
    if (obj["baby_condition_rds_yes_no"] == 'NA') {
      vim.chkbabyconditionrds = false;
      vim.generalForm.controls["baby_condition_rds_yes_no"].clearValidators();
      vim.generalForm.controls["baby_condition_rds_yes_no"].updateValueAndValidity();
    } else {
      vim.chkbabyconditionrds = true;
      vim.generalForm.controls["baby_condition_rds_yes_no"].setValidators([Validators.required]);
      vim.generalForm.controls["baby_condition_rds_yes_no"].updateValueAndValidity();
    }
    
    if (obj["baby_shock_aga_suspect"] == 'NA') {
      vim.chkbabyshockagasuspect = false;
      vim.generalForm.controls["baby_shock_aga_suspect"].clearValidators();
      vim.generalForm.controls["baby_shock_aga_suspect"].updateValueAndValidity();
    } else {
      vim.chkbabyshockagasuspect = true;
      vim.generalForm.controls["baby_shock_aga_suspect"].setValidators([Validators.required]);
      vim.generalForm.controls["baby_shock_aga_suspect"].updateValueAndValidity();
    }
    
    if (obj["baby_condition_dextrocordia_suspect"] == 'NA') {
      vim.chkbabyconditiondextrocordiasuspect = false;
      vim.generalForm.controls["baby_condition_dextrocordia_suspect"].clearValidators();
      vim.generalForm.controls["baby_condition_dextrocordia_suspect"].updateValueAndValidity();
    } else {
      vim.chkbabyconditiondextrocordiasuspect = true;
      vim.generalForm.controls["baby_condition_dextrocordia_suspect"].setValidators([Validators.required]);
      vim.generalForm.controls["baby_condition_dextrocordia_suspect"].updateValueAndValidity();
    }
    
    if (obj["baby_condition_anemia_suspect"] == 'NA') {
      vim.chkbabyconditionanemiasuspect = false;
      
      vim.generalForm.controls["baby_condition_anemia_suspect"].clearValidators();
      vim.generalForm.controls["baby_condition_anemia_suspect"].updateValueAndValidity();
    } else {
      vim.chkbabyconditionanemiasuspect = true;
      vim.generalForm.controls["baby_condition_anemia_suspect"].setValidators([Validators.required]);
      vim.generalForm.controls["baby_condition_anemia_suspect"].updateValueAndValidity();
    }
    
    if (obj["prelim_diagnosis_perinatal"] == 'NA') {
      vim.chkprelimdiagnosisperinatal = false;
      vim.generalForm.controls["prelim_diagnosis_perinatal"].clearValidators();
      vim.generalForm.controls["prelim_diagnosis_perinatal"].updateValueAndValidity();
    } else {
      vim.chkprelimdiagnosisperinatal = true;
      vim.generalForm.controls["prelim_diagnosis_perinatal"].setValidators([Validators.required]);
      vim.generalForm.controls["prelim_diagnosis_perinatal"].updateValueAndValidity();
    }
    
    if (obj["prelim_diagnosis_hypoglycemia"] == 'NA') {
      vim.chkprelimdiagnosishypoglycemia = false;
      vim.generalForm.controls["prelim_diagnosis_hypoglycemia"].clearValidators();
      vim.generalForm.controls["prelim_diagnosis_hypoglycemia"].updateValueAndValidity();
    } else {
      vim.chkprelimdiagnosishypoglycemia = true;
      vim.generalForm.controls["prelim_diagnosis_hypoglycemia"].setValidators([Validators.required]);
      vim.generalForm.controls["prelim_diagnosis_hypoglycemia"].updateValueAndValidity();
    }
    
    if (obj["prelim_diagnosis_hypocalcemia"] == 'NA') {
      vim.chkprelimdiagnosishypocalcemia = false;
      vim.generalForm.controls["prelim_diagnosis_hypocalcemia"].clearValidators();
      vim.generalForm.controls["prelim_diagnosis_hypocalcemia"].updateValueAndValidity();
    } else {
      vim.chkprelimdiagnosishypocalcemia = true;
      vim.generalForm.controls["prelim_diagnosis_hypocalcemia"].setValidators([Validators.required]);
      vim.generalForm.controls["prelim_diagnosis_hypocalcemia"].updateValueAndValidity();
    }
    
    if (obj["prelim_diagnosis_feeding_intolerence"] == 'NA') {
      vim.chkbabyprelimdiagnosisfeedingintolerence = false;
      vim.generalForm.controls["prelim_diagnosis_feeding_intolerence"].clearValidators();
      vim.generalForm.controls["prelim_diagnosis_feeding_intolerence"].updateValueAndValidity();
    } else {
      vim.chkbabyprelimdiagnosisfeedingintolerence = true;
      vim.generalForm.controls["prelim_diagnosis_feeding_intolerence"].setValidators([Validators.required]);
      vim.generalForm.controls["prelim_diagnosis_feeding_intolerence"].updateValueAndValidity();
    }
    
    if (obj["thrombocytopenia"] == 'NA') {
      vim.chkthrombocytopenia = false;
      vim.generalForm.controls["thrombocytopenia"].clearValidators();
      vim.generalForm.controls["thrombocytopenia"].updateValueAndValidity();
    } else {
      vim.chkthrombocytopenia = true;
      vim.generalForm.controls["thrombocytopenia"].setValidators([Validators.required]);
      vim.generalForm.controls["thrombocytopenia"].updateValueAndValidity();
    }
    
    if (obj["seizures"] == 'NA') {
      vim.chkseizures = false;
      vim.generalForm.controls["seizures"].clearValidators();
      vim.generalForm.controls["seizures"].updateValueAndValidity();
    } else {
      vim.chkseizures = true;
      vim.generalForm.controls["seizures"].setValidators([Validators.required]);
      vim.generalForm.controls["seizures"].updateValueAndValidity();
    }
    
    if (obj["prelim_diagnosis_gastroenteritis"] == 'NA') {
      vim.chkprelimdiagnosisgastroenteritis = false;
      vim.generalForm.controls["prelim_diagnosis_gastroenteritis"].clearValidators();
      vim.generalForm.controls["prelim_diagnosis_gastroenteritis"].updateValueAndValidity();
    } else {
      vim.chkprelimdiagnosisgastroenteritis = true;
      vim.generalForm.controls["prelim_diagnosis_gastroenteritis"].setValidators([Validators.required]);
      vim.generalForm.controls["prelim_diagnosis_gastroenteritis"].updateValueAndValidity();
    }
    
    if (obj["asphyxia"] == 'NA') {
      vim.chkasphyxia = false;
      vim.generalForm.controls["asphyxia"].clearValidators();
      vim.generalForm.controls["asphyxia"].updateValueAndValidity();
    } else {
      vim.chkasphyxia = true;
      vim.generalForm.controls["asphyxia"].setValidators([Validators.required]);
      vim.generalForm.controls["asphyxia"].updateValueAndValidity();
    }
    
    if (obj["pneumonia"] == 'NA') {
      vim.chkpneumonia = false;
      vim.generalForm.controls["pneumonia"].clearValidators();
      vim.generalForm.controls["pneumonia"].updateValueAndValidity();
    } else {
      vim.chkpneumonia = true;
      vim.generalForm.controls["pneumonia"].setValidators([Validators.required]);
      vim.generalForm.controls["pneumonia"].updateValueAndValidity();
    }
    
    if (obj["septic_arthritis"] == 'NA') {
      vim.chksepticarthritis = false;
      vim.generalForm.controls["septic_arthritis"].clearValidators();
      vim.generalForm.controls["septic_arthritis"].updateValueAndValidity();
    } else {
      vim.chksepticarthritis = true;
      vim.generalForm.controls["septic_arthritis"].setValidators([Validators.required]);
      vim.generalForm.controls["septic_arthritis"].updateValueAndValidity();
    }
    
    if (obj["endocarditis"] == 'NA') {
      vim.chkendocarditis = false;
      vim.generalForm.controls["endocarditis"].clearValidators();
      vim.generalForm.controls["endocarditis"].updateValueAndValidity();
    } else {
      vim.chkendocarditis = true;
      vim.generalForm.controls["endocarditis"].setValidators([Validators.required]);
      vim.generalForm.controls["endocarditis"].updateValueAndValidity();
    }
    
    if (obj["peritonitis"] == 'NA') {
      vim.chkperitonitis = false;
      vim.generalForm.controls["peritonitis"].clearValidators();
      vim.generalForm.controls["peritonitis"].updateValueAndValidity();
    } else {
      vim.chkperitonitis = true;
      vim.generalForm.controls["peritonitis"].setValidators([Validators.required]);
      vim.generalForm.controls["peritonitis"].updateValueAndValidity();
    }
    
    if (obj["soft_tissue_abscess"] == 'NA') {
      vim.chksofttissueabscess = false;
      vim.generalForm.controls["soft_tissue_abscess"].clearValidators();
      vim.generalForm.controls["soft_tissue_abscess"].updateValueAndValidity();
    } else {
      vim.chksofttissueabscess = true;
      vim.generalForm.controls["soft_tissue_abscess"].setValidators([Validators.required]);
      vim.generalForm.controls["soft_tissue_abscess"].updateValueAndValidity();
    }
    
    if (obj["coagulopathy"] == 'NA') {
      vim.chkcoagulopathy = false;
      vim.generalForm.controls["coagulopathy"].clearValidators();
      vim.generalForm.controls["coagulopathy"].updateValueAndValidity();
    } else {
      vim.chkcoagulopathy = true;
      vim.generalForm.controls["coagulopathy"].setValidators([Validators.required]);
      vim.generalForm.controls["coagulopathy"].updateValueAndValidity();
    }

    if (obj["uti"] == 'NA') {
      vim.chkuti = false;
      vim.generalForm.controls["uti"].clearValidators();
      vim.generalForm.controls["uti"].updateValueAndValidity();
    } else {
      vim.chkuti = true;
      vim.generalForm.controls["uti"].setValidators([Validators.required]);
      vim.generalForm.controls["uti"].updateValueAndValidity();
    }
    
    if (obj["pulmonary_hemorrhage"] == 'NA') {
      vim.chkpulmonaryhemorrhage = false;
      vim.generalForm.controls["pulmonary_hemorrhage"].clearValidators();
      vim.generalForm.controls["pulmonary_hemorrhage"].updateValueAndValidity();
    } else {
      vim.chkpulmonaryhemorrhage = true;
      vim.generalForm.controls["pulmonary_hemorrhage"].setValidators([Validators.required]);
      vim.generalForm.controls["pulmonary_hemorrhage"].updateValueAndValidity();
    }
    
    if (obj["bleeding_manifestation"] == 'NA') {
      vim.chkbleedingmanifestation = false;
      vim.generalForm.controls["bleeding_manifestation"].clearValidators();
      vim.generalForm.controls["bleeding_manifestation"].updateValueAndValidity();
    } else {
      vim.chkbleedingmanifestation = true;
      vim.generalForm.controls["bleeding_manifestation"].setValidators([Validators.required]);
      vim.generalForm.controls["bleeding_manifestation"].updateValueAndValidity();
    }
    
    if (obj["central_peripheral"] == 'NA') {
      vim.chkcentralperipheral = false;
      vim.generalForm.controls["central_peripheral"].clearValidators();
      vim.generalForm.controls["central_peripheral"].updateValueAndValidity();
    } else {
      vim.chkcentralperipheral = true;
      vim.generalForm.controls["central_peripheral"].setValidators([Validators.required]);
      vim.generalForm.controls["central_peripheral"].updateValueAndValidity();
    }
    
    if (obj["hypoxia"] == 'NA') {
      vim.chkhypoxia = false;
      vim.generalForm.controls["hypoxia"].clearValidators();
      vim.generalForm.controls["hypoxia"].updateValueAndValidity();
    } else {
      vim.chkhypoxia = true;
      vim.generalForm.controls["hypoxia"].setValidators([Validators.required]);
      vim.generalForm.controls["hypoxia"].updateValueAndValidity();
    }
    
    if (obj["babymetabolicacidosis"] == 'NA') {
      vim.chkmetabolicacidosis = false;
      vim.generalForm.controls["metabolic_acidosis"].clearValidators();
      vim.generalForm.controls["metabolic_acidosis"].updateValueAndValidity();
    } else {
      vim.chkmetabolicacidosis = true;
      vim.generalForm.controls["metabolic_acidosis"].setValidators([Validators.required]);
      vim.generalForm.controls["metabolic_acidosis"].updateValueAndValidity();
    }

    if (obj["baby_date_of_admission"] == 'NA') {
      vim.chkBabyDateAdmission = false;
      vim.generalForm.controls["baby_date_of_admission"].clearValidators();
      vim.generalForm.controls["baby_date_of_admission"].updateValueAndValidity();
    } else {
      vim.chkBabyDateAdmission = true;
      vim.generalForm.controls["baby_date_of_admission"].setValidators([Validators.required]);
      vim.generalForm.controls["baby_date_of_admission"].updateValueAndValidity();
    }

    if (obj["baby_condition_other_if_suspect"] == 'NA') {
      vim.chkBabyCondOnSuspectOtherIfAny = false;
    } else {
      vim.chkBabyCondOnSuspectOtherIfAny = true;
    }
    if (obj["baby_mother_medical_record_number"] == 'NA') {
      vim.motherMRNo = false;
      vim.generalForm.controls["baby_mother_medical_record_number"].clearValidators();
      vim.generalForm.controls["baby_mother_medical_record_number"].updateValueAndValidity();
    } else {
      vim.motherMRNo = true;
      //vim.generalForm.controls["baby_mother_medical_record_number"].setValidators([Validators.required]);
     // vim.generalForm.controls["baby_mother_medical_record_number"].updateValueAndValidity();
    }

    if (obj['baby_gestational_age'] > 36) {
      vim.pretermArr = ['No', 'NA'];
      vim.generalForm.controls["baby_preterm"].setValidators([Validators.required]);
      vim.generalForm.controls["baby_preterm"].updateValueAndValidity();
    } if(obj['baby_gestational_age'] < 37){
      vim.pretermArr = ['Yes', 'NA'];
      vim.generalForm.controls["baby_preterm"].setValidators([Validators.required]);
      vim.generalForm.controls["baby_preterm"].updateValueAndValidity();
    } 
    if(obj['baby_gestational_age'] == 'NA') {
      vim.pretermArr = ['Yes', 'No', 'NA'];
      vim.generalForm.controls["baby_preterm"].setValidators([Validators.required]);
      vim.generalForm.controls["baby_preterm"].updateValueAndValidity();
    }
      vim.studyId=obj['study_id'];
    vim.generalForm.patchValue({
      study_id: obj['study_id'],
      hospital_id: vim.hospital_id,
      hospital_name: vim.hospital_name,
      hospital_branch_name: vim.hospital_branch_name,
      isCreateForm: vim.isBabyCreateGeneral,
      record_type: obj["record_type"],
      babyMedicalRecord: obj["baby_medical_record_number"],
      babyMotherMedicalRecord: obj["baby_mother_medical_record_number"],
      baby_admission_type: obj["baby_admission_type"],
      baby_place_of_birth_pin_code: obj["baby_place_of_birth_pin_code"],
      patient_admission_type: obj["patient_admission_type"],
      baby_place_of_birth_name: obj["baby_place_of_birth_name"],
      baby_birth_date: obj["baby_birth_date"],
      baby_birth_time_hours: obj["baby_birth_time_hours"],
      baby_birth_time_minit: obj["baby_birth_time_minit"],
      baby_apgar_score_one_min: obj["baby_apgar_score_one_min"],
      baby_apgar_score_five_min: obj["baby_apgar_score_five_min"],
      baby_apgar_score_ten_min: obj["baby_apgar_score_ten_min"],
      baby_age_of_admission: obj["baby_age_of_admission"],
      baby_gender: obj["baby_gender"],
      baby_preterm: obj["baby_preterm"],
      place_of_delivery: obj["place_of_delivery"],
      birth_facility: obj["birth_facility"],
      baby_gestational_age: obj["baby_gestational_age"],
      baby_gestational_age_unit: obj["baby_gestational_age_unit"],
      baby_weight_at_birth: obj["baby_weight_at_birth"],
      baby_condition_suspect: obj["baby_condition_suspect"],
      baby_day_of_event: obj["baby_day_of_event"],
      patient_region: obj["patient_region"],
      baby_weight_at_admission: obj["baby_weight_at_admission"],
      baby_condition_yes_eos_los: obj["baby_condition_yes_eos_los"],
      baby_condition_rds_yes_no: obj["baby_condition_rds_yes_no"],
      baby_condition_jaundice_suspect: obj["baby_condition_jaundice_suspect"],
      baby_condition_lbw_suspect: obj["baby_condition_lbw_suspect"],
      baby_condition_ttnb_suspect: obj["baby_condition_ttnb_suspect"],
      baby_lga_sga_aga_suspect: obj["baby_lga_sga_aga_suspect"],
      baby_condition_anemia_suspect: obj["baby_condition_anemia_suspect"],
      baby_condition_other_if_suspect: obj["baby_condition_other_if_suspect"],
      prelim_diagnosis_perinatal: obj["prelim_diagnosis_perinatal"],
      prelim_diagnosis_hypoglycemia: obj["prelim_diagnosis_hypoglycemia"],
      prelim_diagnosis_hypocalcemia: obj["prelim_diagnosis_hypocalcemia"],
      prelim_diagnosis_feeding_intolerence: obj["prelim_diagnosis_feeding_intolerence"],
      prelim_diagnosis_gastroenteritis: obj["prelim_diagnosis_gastroenteritis"],
      baby_shock_aga_suspect: obj["baby_shock_aga_suspect"],
      baby_condition_dextrocordia_suspect:
        obj["baby_condition_dextrocordia_suspect"],
      baby_weight_at_birth_unit: obj["baby_weight_at_birth_unit"],
      baby_weight_at_admission_unit: obj["baby_weight_at_admission_unit"],
      baby_date_of_admission: obj["baby_date_of_admission"],
      baby_name: obj["baby_name"],
      baby_mother_name: obj["baby_mother_name"],
      meningitis: obj["meningitis"],
      asphyxia:obj["asphyxia"],
      septic_arthritis:obj["septic_arthritis"],
      endocarditis:obj["endocarditis"],
      peritonitis:obj["peritonitis"],
      soft_tissue_abscess:obj["soft_tissue_abscess"],
      coagulopathy:obj["coagulopathy"],
      uti:obj["uti"],
      umblical_sepsis:obj["umblical_sepsis"],
      bleeding_manifestation:obj["bleeding_manifestation"],
      central_peripheral:obj["central_peripheral"],
      hypoxia:obj["hypoxia"],
      metabolic_acidosis:obj["metabolic_acidosis"],
      skin_pustules:obj['skin_pustules'],
      seizures:obj['seizures'],
      pulmonary_hemorrhage:obj['pulmonary_hemorrhage'],
      thrombocytopenia:obj['thrombocytopenia'],
      pneumonia:obj['pneumonia'],
    });
  }


  onInputChange(event) {
    var vim = this;
    var target = event.target || event.srcElement || event.currentTarget;

    if (target.name == 'babyPlaceBirthPin') {
      if (target.value == '2') {
        vim.chkBabyPlaceBirthPin = false;
        vim.generalForm.patchValue({
          baby_place_of_birth_pin_code: 'NA'
        })
        vim.generalForm.value["baby_place_of_birth_pin_code"] = 'NA';
        vim.generalForm.controls["baby_place_of_birth_pin_code"].clearValidators();
        vim.generalForm.controls["baby_place_of_birth_pin_code"].updateValueAndValidity();
      } else {
        vim.chkBabyPlaceBirthPin = true;

        vim.generalForm.patchValue({
          baby_place_of_birth_pin_code: ''
        })
        vim.generalForm.controls["baby_place_of_birth_pin_code"].setValidators([Validators.required, Validators.minLength(6)]);
        vim.generalForm.controls["baby_place_of_birth_pin_code"].updateValueAndValidity();
      }
    }

    if (target.name == 'babyPlaceBirthName') {
      if (target.value == '2') {
        vim.chkBabyPlaceBirthName = false;
        vim.generalForm.patchValue({
          baby_place_of_birth_name: 'NA'
        })
        vim.generalForm.value["baby_place_of_birth_name"] = 'NA';
        vim.generalForm.controls["baby_place_of_birth_name"].clearValidators();
        vim.generalForm.controls["baby_place_of_birth_name"].updateValueAndValidity();
      } else {
        vim.chkBabyPlaceBirthName = true;
        vim.generalForm.patchValue({
          baby_place_of_birth_name: ''
        })
        vim.generalForm.controls["baby_place_of_birth_name"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_place_of_birth_name"].updateValueAndValidity();
      }
    }

    if (target.name == 'babyDOB') {
      if (target.value == '2') {
        vim.chkBabyDOB = false;
        vim.generalForm.patchValue({
          baby_birth_date: 'NA'
        })
      } else {
        vim.chkBabyDOB = true;
        vim.generalForm.patchValue({
          baby_birth_date: ''
        })
      }
    }
    
    

    if (target.name == 'babyTimeOfBirth') {
      if (target.value == '2') {
        vim.chkBabyTimeOfBirth = false;
        vim.generalForm.patchValue({
          baby_birth_time_hours: 'NA'
        })
        vim.generalForm.patchValue({
          baby_birth_time_minit: 'NA'
        })
        vim.generalForm.value["baby_birth_time_hours"] = 'NA';
        vim.generalForm.value["baby_birth_time_minit"] = 'NA';

        vim.generalForm.controls["baby_birth_time_hours"].clearValidators();
        vim.generalForm.controls["baby_birth_time_hours"].updateValueAndValidity();
        vim.generalForm.controls["baby_birth_time_minit"].clearValidators();
        vim.generalForm.controls["baby_birth_time_minit"].updateValueAndValidity();
      } else {
        vim.generalForm.controls["baby_birth_time_hours"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_birth_time_hours"].updateValueAndValidity();
        vim.generalForm.controls["baby_birth_time_minit"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_birth_time_minit"].updateValueAndValidity();
        vim.chkBabyTimeOfBirth = true;
        vim.generalForm.patchValue({
          baby_birth_time_hours: ''
        })
        vim.generalForm.patchValue({
          baby_birth_time_minit: ''
        })
      }
    }
    
    if (target.name == 'babyadmissiontype') {
      if (target.value == '2') {
        vim.chkadmissiontype = false;
        vim.generalForm.patchValue({
          baby_admission_type: 'NA'
        })
        vim.generalForm.value["baby_admission_type"] = 'NA';
        vim.generalForm.controls["baby_admission_type"].clearValidators();
        vim.generalForm.controls["baby_admission_type"].updateValueAndValidity();
      } else {
        vim.chkadmissiontype = true;
        vim.generalForm.patchValue({
          baby_admission_type: ''
        })
        vim.generalForm.controls["baby_admission_type"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_admission_type"].updateValueAndValidity();
      }
    }
    
    if (target.name == 'babygender') {
      if (target.value == '2') {
        vim.chkbabygender = false;
        vim.generalForm.patchValue({
          baby_gender: 'NA'
        })
        vim.generalForm.value["baby_gender"] = 'NA';
        vim.generalForm.controls["baby_gender"].clearValidators();
        vim.generalForm.controls["baby_gender"].updateValueAndValidity();
      } else {
        vim.chkbabygender = true;
        vim.generalForm.patchValue({
          baby_gender: ''
        })
        vim.generalForm.controls["baby_gender"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_gender"].updateValueAndValidity();
      }
    }

    if (target.name == 'babyAgeAdmission') {
      if (target.value == '2') {
        vim.chkBabyAgeAdmission = false;
        vim.generalForm.patchValue({
          baby_age_of_admission: 'NA'
        })
        vim.generalForm.value["baby_age_of_admission"] = 'NA';
        vim.generalForm.controls["baby_age_of_admission"].clearValidators();
        vim.generalForm.controls["baby_age_of_admission"].updateValueAndValidity();
      } else {
        vim.chkBabyAgeAdmission = true;
        vim.generalForm.patchValue({
          baby_age_of_admission: ''
        })
        vim.generalForm.controls["baby_age_of_admission"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_age_of_admission"].updateValueAndValidity();
      }
    }

    if (target.name == 'babyApgarSc1') {
      if (target.value == '2') {
        vim.chkBabyApgarSc1 = false;
        vim.generalForm.patchValue({
          baby_apgar_score_one_min: 'NA'
        })
        vim.generalForm.value["baby_apgar_score_one_min"] = 'NA';
        vim.generalForm.controls["baby_apgar_score_one_min"].clearValidators();
        vim.generalForm.controls["baby_apgar_score_one_min"].updateValueAndValidity();
      } else {
        vim.chkBabyApgarSc1 = true;
        vim.generalForm.patchValue({
          baby_apgar_score_one_min: ''
        })
        vim.generalForm.controls["baby_apgar_score_one_min"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_apgar_score_one_min"].updateValueAndValidity();
      }
    }

    if (target.name == 'babyApgarSc5') {
      if (target.value == '2') {
        vim.chkBabyApgarSc5 = false;
        vim.generalForm.patchValue({
          baby_apgar_score_five_min: 'NA'
        })
        vim.generalForm.value["baby_apgar_score_five_min"] = 'NA';
        vim.generalForm.controls["baby_apgar_score_five_min"].clearValidators();
        vim.generalForm.controls["baby_apgar_score_five_min"].updateValueAndValidity();
      } else {
        vim.chkBabyApgarSc5 = true;
        vim.generalForm.patchValue({
          baby_apgar_score_five_min: ''
        })
        vim.generalForm.controls["baby_apgar_score_five_min"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_apgar_score_five_min"].updateValueAndValidity();
      }
    }

    if (target.name == 'babyApgarSc10') {
      if (target.value == '2') {
        vim.chkBabyApgarSc10 = false;
        vim.generalForm.patchValue({
          baby_apgar_score_ten_min: 'NA'
        })
        vim.generalForm.value["baby_apgar_score_ten_min"] = 'NA';
        vim.generalForm.controls["baby_apgar_score_ten_min"].clearValidators();
        vim.generalForm.controls["baby_apgar_score_ten_min"].updateValueAndValidity();
      } else {
        vim.chkBabyApgarSc10 = true;
        vim.generalForm.patchValue({
          baby_apgar_score_ten_min: ''
        })
        vim.generalForm.controls["baby_apgar_score_ten_min"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_apgar_score_ten_min"].updateValueAndValidity();
      }
    }

    
    if (target.name == 'Placeofdelivery') {
      if (target.value == '2') {
        vim.chkPlaceofdelivery = false;
        vim.generalForm.patchValue({
          place_of_delivery: 'NA'
        })
        vim.generalForm.value["place_of_delivery"] = 'NA';
        vim.generalForm.controls["place_of_delivery"].clearValidators();
        vim.generalForm.controls["place_of_delivery"].updateValueAndValidity();
      } else {
        vim.chkPlaceofdelivery = true;
        vim.generalForm.patchValue({
          place_of_delivery: ''
        })
        vim.generalForm.controls["place_of_delivery"].setValidators([Validators.required]);
        vim.generalForm.controls["place_of_delivery"].updateValueAndValidity();
      }
    }

    if (target.name == 'babyGestationalAge') {
      if (target.value == '2') {
        vim.chkBabyGestationalAge = false;
        vim.generalForm.patchValue({
          baby_gestational_age: 'NA'
        })
        vim.generalForm.value["baby_gestational_age"] = 'NA';
        vim.generalForm.controls["baby_gestational_age"].clearValidators();
        vim.generalForm.controls["baby_gestational_age"].updateValueAndValidity();

        this.pretermArr = ['Yes', 'No', 'NA'];
      this.generalForm.controls["baby_preterm"].setValidators([Validators.required]);
      this.generalForm.controls["baby_preterm"].updateValueAndValidity();
      // this.generalForm.controls["baby_preterm"].setValue('');
      } else {
        vim.chkBabyGestationalAge = true;
        vim.generalForm.patchValue({
          baby_gestational_age: ''
        })
        vim.generalForm.controls["baby_gestational_age"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_gestational_age"].updateValueAndValidity();
      }
    }

    if (target.name == 'babyWeightAtBirth') {
      if (target.value == '2') {
        vim.chkWeightAtBirth = false;
        vim.generalForm.patchValue({
          baby_weight_at_birth: 'NA',baby_weight_at_birth_unit:''
        })
        vim.generalForm.value["baby_weight_at_birth"] = 'NA';
        vim.generalForm.value["baby_weight_at_birth_unit"] = 'NA';
        vim.generalForm.controls["baby_weight_at_birth"].clearValidators();
        vim.generalForm.controls["baby_weight_at_birth_unit"].clearValidators();
        vim.generalForm.controls["baby_weight_at_birth"].updateValueAndValidity();
        vim.generalForm.controls["baby_weight_at_birth_unit"].updateValueAndValidity();
      } else {
        vim.chkWeightAtBirth = true;
        vim.generalForm.patchValue({
          baby_weight_at_birth: '',
          baby_weight_at_birth_unit: ''
        })
        vim.generalForm.controls["baby_weight_at_birth"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_weight_at_birth"].updateValueAndValidity();
        vim.generalForm.controls["baby_weight_at_birth_unit"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_weight_at_birth_unit"].updateValueAndValidity();
      }
    }
    
    if (target.name == 'babyWeightAtAdmission') {
      if (target.value == '2') {
        vim.chkWeightAtAdmission = false;
        vim.generalForm.patchValue({
          baby_weight_at_admission: 'NA',baby_weight_at_admission_unit:''
        })
        vim.generalForm.value["baby_weight_at_admission"] = 'NA';
        vim.generalForm.value["baby_weight_at_admission_unit"] = 'NA';
        vim.generalForm.controls["baby_weight_at_admission"].clearValidators();
        vim.generalForm.controls["baby_weight_at_admission_unit"].clearValidators();
        vim.generalForm.controls["baby_weight_at_admission"].updateValueAndValidity();
        vim.generalForm.controls["baby_weight_at_admission_unit"].updateValueAndValidity();
      } else {
        vim.chkWeightAtAdmission = true;
        vim.generalForm.patchValue({
          baby_weight_at_admission: '',
          baby_weight_at_admission_unit: ''
        })
        vim.generalForm.controls["baby_weight_at_admission"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_weight_at_admission"].updateValueAndValidity();
        vim.generalForm.controls["baby_weight_at_admission_unit"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_weight_at_admission_unit"].updateValueAndValidity();
      }
    }

    if (target.name == 'babyDayEvent') {
      if (target.value == '2') {
        vim.chkBabyDayEvent = false;
        vim.generalForm.patchValue({
          baby_day_of_event: 'NA'
        })
        vim.generalForm.value["baby_day_of_event"] = 'NA';
        vim.generalForm.controls["baby_day_of_event"].clearValidators();
        vim.generalForm.controls["baby_day_of_event"].updateValueAndValidity();
      } else {
        vim.chkBabyDayEvent = true;
        vim.generalForm.patchValue({
          baby_day_of_event: ''
        })
        vim.generalForm.controls["baby_day_of_event"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_day_of_event"].updateValueAndValidity();
      }
    }

    if (target.name == 'babyumblicalsepsis') {
      if (target.value == '2') {
        vim.chkumblicalsepsis = false;
        vim.generalForm.patchValue({
          umblical_sepsis: 'NA'
        })
        vim.generalForm.value["umblical_sepsis"] = 'NA';
        vim.generalForm.controls["umblical_sepsis"].clearValidators();
        vim.generalForm.controls["umblical_sepsis"].updateValueAndValidity();
      } else {
        vim.chkumblicalsepsis = true;
        vim.generalForm.patchValue({
          umblical_sepsis: ''
        })
        vim.generalForm.controls["umblical_sepsis"].setValidators([Validators.required]);
        vim.generalForm.controls["umblical_sepsis"].updateValueAndValidity();
      }
    }

    if (target.name == 'babyconditionsuspect') {
      if (target.value == '2') {
        vim.chkbabyconditionsuspect = false;
        vim.generalForm.patchValue({
          baby_condition_suspect: 'NA'
        })
        vim.generalForm.value["baby_condition_suspect"] = 'NA';
        vim.generalForm.controls["baby_condition_suspect"].clearValidators();
        vim.generalForm.controls["baby_condition_suspect"].updateValueAndValidity();
      } else {
        vim.chkbabyconditionsuspect = true;
        vim.generalForm.patchValue({
          baby_condition_suspect: ''
        })
        vim.generalForm.controls["baby_condition_suspect"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_condition_suspect"].updateValueAndValidity();
      }
    }
    
    if (target.name == 'babyconditionyeseoslos') {
      if (target.value == '2') {
        vim.chkbabyconditionyeseoslos = false;
        vim.generalForm.patchValue({
          baby_condition_yes_eos_los: 'NA'
        })
        vim.generalForm.value["baby_condition_yes_eos_los"] = 'NA';
        vim.generalForm.controls["baby_condition_yes_eos_los"].clearValidators();
        vim.generalForm.controls["baby_condition_yes_eos_los"].updateValueAndValidity();
      } else {
        vim.chkbabyconditionyeseoslos = true;
        vim.generalForm.patchValue({
          baby_condition_yes_eos_los: ''
        })
        vim.generalForm.controls["baby_condition_yes_eos_los"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_condition_yes_eos_los"].updateValueAndValidity();
      }
    }

    if (target.name == 'babymeningitis') {
      if (target.value == '2') {
        vim.chkmeningitis = false;
        vim.generalForm.patchValue({
          meningitis: 'NA'
        })
        vim.generalForm.value["meningitis"] = 'NA';
        vim.generalForm.controls["meningitis"].clearValidators();
        vim.generalForm.controls["meningitis"].updateValueAndValidity();
      } else {
        vim.chkmeningitis = true;
        vim.generalForm.patchValue({
          meningitis: ''
        })
        vim.generalForm.controls["meningitis"].setValidators([Validators.required]);
        vim.generalForm.controls["meningitis"].updateValueAndValidity();
      }
    }

    if (target.name == 'babyskinpustules') {
      if (target.value == '2') {
        vim.chkskinpustules = false;
        vim.generalForm.patchValue({
          skin_pustules: 'NA'
        })
        vim.generalForm.value["skin_pustules"] = 'NA';
        vim.generalForm.controls["skin_pustules"].clearValidators();
        vim.generalForm.controls["skin_pustules"].updateValueAndValidity();
      } else {
        vim.chkskinpustules = true;
        vim.generalForm.patchValue({
          skin_pustules: ''
        })
        vim.generalForm.controls["skin_pustules"].setValidators([Validators.required]);
        vim.generalForm.controls["skin_pustules"].updateValueAndValidity();
      }
    }

    if (target.name == 'babyumblicalsepsis') {
      if (target.value == '2') {
        vim.chkumblicalsepsis = false;
        vim.generalForm.patchValue({
          umblical_sepsis: 'NA'
        })
        vim.generalForm.value["umblical_sepsis"] = 'NA';
        vim.generalForm.controls["umblical_sepsis"].clearValidators();
        vim.generalForm.controls["umblical_sepsis"].updateValueAndValidity();
      } else {
        vim.chkumblicalsepsis = true;
        vim.generalForm.patchValue({
          umblical_sepsis: ''
        })
        vim.generalForm.controls["umblical_sepsis"].setValidators([Validators.required]);
        vim.generalForm.controls["umblical_sepsis"].updateValueAndValidity();
      }
    }

    if (target.name == 'babyconditionyessuspect') {
      if (target.value == '2') {
        vim.chkconditionyessuspect = false;
        vim.generalForm.patchValue({
          baby_condition_lbw_suspect: 'NA'
        })
        vim.generalForm.value["baby_condition_lbw_suspect"] = 'NA';
        vim.generalForm.controls["baby_condition_lbw_suspect"].clearValidators();
        vim.generalForm.controls["baby_condition_lbw_suspect"].updateValueAndValidity();
      } else {
        vim.chkconditionyessuspect = true;
        vim.generalForm.patchValue({
          baby_condition_lbw_suspect: ''
        })
        vim.generalForm.controls["baby_condition_lbw_suspect"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_condition_lbw_suspect"].updateValueAndValidity();
      }
    }
    if (target.name == 'babyconditionrds') {
      if (target.value == '2') {
        vim.chkbabyconditionrds = false;
        vim.generalForm.patchValue({
          baby_condition_rds_yes_no: 'NA'
        })
        vim.generalForm.value["baby_condition_rds_yes_no"] = 'NA';
        vim.generalForm.controls["baby_condition_rds_yes_no"].clearValidators();
        vim.generalForm.controls["baby_condition_rds_yes_no"].updateValueAndValidity();
      } else {
        vim.chkbabyconditionrds = true;
        vim.generalForm.patchValue({
          baby_condition_rds_yes_no: ''
        })
        vim.generalForm.controls["baby_condition_rds_yes_no"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_condition_rds_yes_no"].updateValueAndValidity();
      }
    }

    if (target.name == 'babyconditionjaundice') {
      if (target.value == '2') {
        vim.chkconditionjaundice = false;
        vim.generalForm.patchValue({
          baby_condition_jaundice_suspect: 'NA'
        })
        vim.generalForm.value["baby_condition_jaundice_suspect"] = 'NA';
        vim.generalForm.controls["baby_condition_jaundice_suspect"].clearValidators();
        vim.generalForm.controls["baby_condition_jaundice_suspect"].updateValueAndValidity();
      } else {
        vim.chkconditionjaundice = true;
        vim.generalForm.patchValue({
          baby_condition_jaundice_suspect: ''
        })
        vim.generalForm.controls["baby_condition_jaundice_suspect"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_condition_jaundice_suspect"].updateValueAndValidity();
      }
    }
    
    if (target.name == 'babyconditionttnbsuspect') {
      if (target.value == '2') {
        vim.chkconditionttnbsuspect = false;
        vim.generalForm.patchValue({
          baby_condition_ttnb_suspect: 'NA'
        })
        vim.generalForm.value["baby_condition_ttnb_suspect"] = 'NA';
        vim.generalForm.controls["baby_condition_ttnb_suspect"].clearValidators();
        vim.generalForm.controls["baby_condition_ttnb_suspect"].updateValueAndValidity();
      } else {
        vim.chkconditionttnbsuspect = true;
        vim.generalForm.patchValue({
          baby_condition_ttnb_suspect: ''
        })
        vim.generalForm.controls["baby_condition_ttnb_suspect"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_condition_ttnb_suspect"].updateValueAndValidity();
      }
    }
    
    if (target.name == 'babylgasgaagasuspect') {
      if (target.value == '2') {
        vim.chkbabylgasgaagasuspect = false;
        vim.generalForm.patchValue({
          baby_lga_sga_aga_suspect: 'NA'
        })
        vim.generalForm.value["baby_lga_sga_aga_suspect"] = 'NA';
        vim.generalForm.controls["baby_lga_sga_aga_suspect"].clearValidators();
        vim.generalForm.controls["baby_lga_sga_aga_suspect"].updateValueAndValidity();
      } else {
        vim.chkbabylgasgaagasuspect = true;
        vim.generalForm.patchValue({
          baby_lga_sga_aga_suspect: ''
        })
        vim.generalForm.controls["baby_lga_sga_aga_suspect"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_lga_sga_aga_suspect"].updateValueAndValidity();
      }
    }
     
    if (target.name == 'babyshockagasuspect') {
      if (target.value == '2') {
        vim.chkbabyshockagasuspect = false;
        vim.generalForm.patchValue({
          baby_shock_aga_suspect: 'NA'
        })
        vim.generalForm.value["baby_shock_aga_suspect"] = 'NA';
        vim.generalForm.controls["baby_shock_aga_suspect"].clearValidators();
        vim.generalForm.controls["baby_shock_aga_suspect"].updateValueAndValidity();
      } else {
        vim.chkbabyshockagasuspect = true;
        vim.generalForm.patchValue({
          baby_shock_aga_suspect: ''
        })
        vim.generalForm.controls["baby_shock_aga_suspect"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_shock_aga_suspect"].updateValueAndValidity();
      }
    }
      
    if (target.name == 'babyconditiondextrocordiasuspect') {
      if (target.value == '2') {
        vim.chkbabyconditiondextrocordiasuspect = false;
        vim.generalForm.patchValue({
          baby_condition_dextrocordia_suspect: 'NA'
        })
        vim.generalForm.value["baby_condition_dextrocordia_suspect"] = 'NA';
        vim.generalForm.controls["baby_condition_dextrocordia_suspect"].clearValidators();
        vim.generalForm.controls["baby_condition_dextrocordia_suspect"].updateValueAndValidity();
      } else {
        vim.chkbabyconditiondextrocordiasuspect = true;
        vim.generalForm.patchValue({
          baby_condition_dextrocordia_suspect: ''
        })
        vim.generalForm.controls["baby_condition_dextrocordia_suspect"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_condition_dextrocordia_suspect"].updateValueAndValidity();
      }
    }
      
    if (target.name == 'babyconditionanemiasuspect') {
      if (target.value == '2') {
        vim.chkbabyconditionanemiasuspect = false;
        vim.generalForm.patchValue({
          baby_condition_anemia_suspect: 'NA'
        })
        vim.generalForm.value["baby_condition_anemia_suspect"] = 'NA';
        vim.generalForm.controls["baby_condition_anemia_suspect"].clearValidators();
        vim.generalForm.controls["baby_condition_anemia_suspect"].updateValueAndValidity();
      } else {
        vim.chkbabyconditionanemiasuspect = true;
        vim.generalForm.patchValue({
          baby_condition_anemia_suspect: ''
        })
        vim.generalForm.controls["baby_condition_anemia_suspect"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_condition_anemia_suspect"].updateValueAndValidity();
      }
    }
      
    if (target.name == 'babyprelimdiagnosisperinatal') {
      if (target.value == '2') {
        vim.chkprelimdiagnosisperinatal = false;
        vim.generalForm.patchValue({
          prelim_diagnosis_perinatal: 'NA'
        })
        vim.generalForm.value["prelim_diagnosis_perinatal"] = 'NA';
        vim.generalForm.controls["prelim_diagnosis_perinatal"].clearValidators();
        vim.generalForm.controls["prelim_diagnosis_perinatal"].updateValueAndValidity();
      } else {
        vim.chkprelimdiagnosisperinatal = true;
        vim.generalForm.patchValue({
          prelim_diagnosis_perinatal: ''
        })
        vim.generalForm.controls["prelim_diagnosis_perinatal"].setValidators([Validators.required]);
        vim.generalForm.controls["prelim_diagnosis_perinatal"].updateValueAndValidity();
      }
    }
      
    if (target.name == 'babyprelimdiagnosishypoglycemia') {
      if (target.value == '2') {
        vim.chkprelimdiagnosishypoglycemia = false;
        vim.generalForm.patchValue({
          prelim_diagnosis_hypoglycemia: 'NA'
        })
        vim.generalForm.value["prelim_diagnosis_hypoglycemia"] = 'NA';
        vim.generalForm.controls["prelim_diagnosis_hypoglycemia"].clearValidators();
        vim.generalForm.controls["prelim_diagnosis_hypoglycemia"].updateValueAndValidity();
      } else {
        vim.chkprelimdiagnosishypoglycemia = true;
        vim.generalForm.patchValue({
          prelim_diagnosis_hypoglycemia: ''
        })
        vim.generalForm.controls["prelim_diagnosis_hypoglycemia"].setValidators([Validators.required]);
        vim.generalForm.controls["prelim_diagnosis_hypoglycemia"].updateValueAndValidity();
      }
    }
      
    if (target.name == 'babyprelimdiagnosishypocalcemia') {
      if (target.value == '2') {
        vim.chkprelimdiagnosishypocalcemia = false;
        vim.generalForm.patchValue({
          prelim_diagnosis_hypocalcemia: 'NA'
        })
        vim.generalForm.value["prelim_diagnosis_hypocalcemia"] = 'NA';
        vim.generalForm.controls["prelim_diagnosis_hypocalcemia"].clearValidators();
        vim.generalForm.controls["prelim_diagnosis_hypocalcemia"].updateValueAndValidity();
      } else {
        vim.chkprelimdiagnosishypocalcemia = true;
        vim.generalForm.patchValue({
          prelim_diagnosis_hypocalcemia: ''
        })
        vim.generalForm.controls["prelim_diagnosis_hypocalcemia"].setValidators([Validators.required]);
        vim.generalForm.controls["prelim_diagnosis_hypocalcemia"].updateValueAndValidity();
      }
    }
      
    if (target.name == 'babyprelimdiagnosisfeedingintolerence') {
      if (target.value == '2') {
        vim.chkbabyprelimdiagnosisfeedingintolerence = false;
        vim.generalForm.patchValue({
          prelim_diagnosis_feeding_intolerence: 'NA'
        })
        vim.generalForm.value["prelim_diagnosis_feeding_intolerence"] = 'NA';
        vim.generalForm.controls["prelim_diagnosis_feeding_intolerence"].clearValidators();
        vim.generalForm.controls["prelim_diagnosis_feeding_intolerence"].updateValueAndValidity();
      } else {
        vim.chkbabyprelimdiagnosisfeedingintolerence = true;
        vim.generalForm.patchValue({
          prelim_diagnosis_feeding_intolerence: ''
        })
        vim.generalForm.controls["prelim_diagnosis_feeding_intolerence"].setValidators([Validators.required]);
        vim.generalForm.controls["prelim_diagnosis_feeding_intolerence"].updateValueAndValidity();
      }
    }
      
    if (target.name == 'babythrombocytopenia') {
      if (target.value == '2') {
        vim.chkthrombocytopenia = false;
        vim.generalForm.patchValue({
          thrombocytopenia: 'NA'
        })
        vim.generalForm.value["thrombocytopenia"] = 'NA';
        vim.generalForm.controls["thrombocytopenia"].clearValidators();
        vim.generalForm.controls["thrombocytopenia"].updateValueAndValidity();
      } else {
        vim.chkthrombocytopenia = true;
        vim.generalForm.patchValue({
          thrombocytopenia: ''
        })
        vim.generalForm.controls["thrombocytopenia"].setValidators([Validators.required]);
        vim.generalForm.controls["thrombocytopenia"].updateValueAndValidity();
      }
    }
      
    if (target.name == 'babyseizures') {
      if (target.value == '2') {
        vim.chkseizures = false;
        vim.generalForm.patchValue({
          seizures: 'NA'
        })
        vim.generalForm.value["seizures"] = 'NA';
        vim.generalForm.controls["seizures"].clearValidators();
        vim.generalForm.controls["seizures"].updateValueAndValidity();
      } else {
        vim.chkseizures = true;
        vim.generalForm.patchValue({
          seizures: ''
        })
        vim.generalForm.controls["seizures"].setValidators([Validators.required]);
        vim.generalForm.controls["seizures"].updateValueAndValidity();
      }
    }
      
    if (target.name == 'prelimdiagnosisgastroenteritis') {
      if (target.value == '2') {
        vim.chkprelimdiagnosisgastroenteritis = false;
        vim.generalForm.patchValue({
          prelim_diagnosis_gastroenteritis: 'NA'
        })
        vim.generalForm.value["prelim_diagnosis_gastroenteritis"] = 'NA';
        vim.generalForm.controls["prelim_diagnosis_gastroenteritis"].clearValidators();
        vim.generalForm.controls["prelim_diagnosis_gastroenteritis"].updateValueAndValidity();
      } else {
        vim.chkprelimdiagnosisgastroenteritis = true;
        vim.generalForm.patchValue({
          prelim_diagnosis_gastroenteritis: ''
        })
        vim.generalForm.controls["prelim_diagnosis_gastroenteritis"].setValidators([Validators.required]);
        vim.generalForm.controls["prelim_diagnosis_gastroenteritis"].updateValueAndValidity();
      }
    }
    
    if (target.name == 'babyasphyxia') {
      if (target.value == '2') {
        vim.chkasphyxia = false;
        vim.generalForm.patchValue({
          asphyxia: 'NA'
        })
        vim.generalForm.value["asphyxia"] = 'NA';
        vim.generalForm.controls["asphyxia"].clearValidators();
        vim.generalForm.controls["asphyxia"].updateValueAndValidity();
      } else {
        vim.chkasphyxia = true;
        vim.generalForm.patchValue({
          asphyxia: ''
        })
        vim.generalForm.controls["asphyxia"].setValidators([Validators.required]);
        vim.generalForm.controls["asphyxia"].updateValueAndValidity();
      }
    }
      
    if (target.name == 'babypneumonia') {
      if (target.value == '2') {
        vim.chkpneumonia = false;
        vim.generalForm.patchValue({
          pneumonia: 'NA'
        })
        vim.generalForm.value["pneumonia"] = 'NA';
        vim.generalForm.controls["pneumonia"].clearValidators();
        vim.generalForm.controls["pneumonia"].updateValueAndValidity();
      } else {
        vim.chkpneumonia = true;
        vim.generalForm.patchValue({
          pneumonia: ''
        })
        vim.generalForm.controls["pneumonia"].setValidators([Validators.required]);
        vim.generalForm.controls["pneumonia"].updateValueAndValidity();
      }
    }
      
    if (target.name == 'babysepticarthritis') {
      if (target.value == '2') {
        vim.chksepticarthritis = false;
        vim.generalForm.patchValue({
          septic_arthritis: 'NA'
        })
        vim.generalForm.value["septic_arthritis"] = 'NA';
        vim.generalForm.controls["septic_arthritis"].clearValidators();
        vim.generalForm.controls["septic_arthritis"].updateValueAndValidity();
      } else {
        vim.chksepticarthritis = true;
        vim.generalForm.patchValue({
          septic_arthritis: ''
        })
        vim.generalForm.controls["septic_arthritis"].setValidators([Validators.required]);
        vim.generalForm.controls["septic_arthritis"].updateValueAndValidity();
      }
    }
      
    if (target.name == 'babyendocarditis') {
      if (target.value == '2') {
        vim.chkendocarditis = false;
        vim.generalForm.patchValue({
          endocarditis: 'NA'
        })
        vim.generalForm.value["endocarditis"] = 'NA';
        vim.generalForm.controls["endocarditis"].clearValidators();
        vim.generalForm.controls["endocarditis"].updateValueAndValidity();
      } else {
        vim.chkendocarditis = true;
        vim.generalForm.patchValue({
          endocarditis: ''
        })
        vim.generalForm.controls["endocarditis"].setValidators([Validators.required]);
        vim.generalForm.controls["endocarditis"].updateValueAndValidity();
      }
    }
      
    if (target.name == 'babyperitonitis') {
      if (target.value == '2') {
        vim.chkperitonitis = false;
        vim.generalForm.patchValue({
          peritonitis: 'NA'
        })
        vim.generalForm.value["peritonitis"] = 'NA';
        vim.generalForm.controls["peritonitis"].clearValidators();
        vim.generalForm.controls["peritonitis"].updateValueAndValidity();
      } else {
        vim.chkperitonitis = true;
        vim.generalForm.patchValue({
          peritonitis: ''
        })
        vim.generalForm.controls["peritonitis"].setValidators([Validators.required]);
        vim.generalForm.controls["peritonitis"].updateValueAndValidity();
      }
    }
      
    if (target.name == 'babycoagulopathy') {
      if (target.value == '2') {
        vim.chkcoagulopathy = false;
        vim.generalForm.patchValue({
          coagulopathy: 'NA'
        })
        vim.generalForm.value["coagulopathy"] = 'NA';
        vim.generalForm.controls["coagulopathy"].clearValidators();
        vim.generalForm.controls["coagulopathy"].updateValueAndValidity();
      } else {
        vim.chkcoagulopathy = true;
        vim.generalForm.patchValue({
          coagulopathy: ''
        })
        vim.generalForm.controls["coagulopathy"].setValidators([Validators.required]);
        vim.generalForm.controls["coagulopathy"].updateValueAndValidity();
      }
    }
      
    if (target.name == 'babysofttissueabscess') {
      if (target.value == '2') {
        vim.chksofttissueabscess = false;
        vim.generalForm.patchValue({
          soft_tissue_abscess: 'NA'
        })
        vim.generalForm.value["soft_tissue_abscess"] = 'NA';
        vim.generalForm.controls["soft_tissue_abscess"].clearValidators();
        vim.generalForm.controls["soft_tissue_abscess"].updateValueAndValidity();
      } else {
        vim.chksofttissueabscess = true;
        vim.generalForm.patchValue({
          soft_tissue_abscess: ''
        })
        vim.generalForm.controls["soft_tissue_abscess"].setValidators([Validators.required]);
        vim.generalForm.controls["soft_tissue_abscess"].updateValueAndValidity();
      }
    }
      
    if (target.name == 'babyuti') {
      if (target.value == '2') {
        vim.chkuti = false;
        vim.generalForm.patchValue({
          uti: 'NA'
        })
        vim.generalForm.value["uti"] = 'NA';
        vim.generalForm.controls["uti"].clearValidators();
        vim.generalForm.controls["uti"].updateValueAndValidity();
      } else {
        vim.chkuti = true;
        vim.generalForm.patchValue({
          uti: ''
        })
        vim.generalForm.controls["uti"].setValidators([Validators.required]);
        vim.generalForm.controls["uti"].updateValueAndValidity();
      }
    }
      
    if (target.name == 'babypulmonaryhemorrhage') {
      if (target.value == '2') {
        vim.chkpulmonaryhemorrhage = false;
        vim.generalForm.patchValue({
          pulmonary_hemorrhage: 'NA'
        })
        vim.generalForm.value["pulmonary_hemorrhage"] = 'NA';
        vim.generalForm.controls["pulmonary_hemorrhage"].clearValidators();
        vim.generalForm.controls["pulmonary_hemorrhage"].updateValueAndValidity();
      } else {
        vim.chkpulmonaryhemorrhage = true;
        vim.generalForm.patchValue({
          pulmonary_hemorrhage: ''
        })
        vim.generalForm.controls["pulmonary_hemorrhage"].setValidators([Validators.required]);
        vim.generalForm.controls["pulmonary_hemorrhage"].updateValueAndValidity();
      }
    }
      
    if (target.name == 'babybleedingmanifestation') {
      if (target.value == '2') {
        vim.chkbleedingmanifestation = false;
        vim.generalForm.patchValue({
          bleeding_manifestation: 'NA'
        })
        vim.generalForm.value["bleeding_manifestation"] = 'NA';
        vim.generalForm.controls["bleeding_manifestation"].clearValidators();
        vim.generalForm.controls["bleeding_manifestation"].updateValueAndValidity();
      } else {
        vim.chkbleedingmanifestation = true;
        vim.generalForm.patchValue({
          bleeding_manifestation: ''
        })
        vim.generalForm.controls["bleeding_manifestation"].setValidators([Validators.required]);
        vim.generalForm.controls["bleeding_manifestation"].updateValueAndValidity();
      }
    }
      
    if (target.name == 'babycentralperipheral') {
      if (target.value == '2') {
        vim.chkcentralperipheral = false;
        vim.generalForm.patchValue({
          central_peripheral: 'NA'
        })
        vim.generalForm.value["central_peripheral"] = 'NA';
        vim.generalForm.controls["central_peripheral"].clearValidators();
        vim.generalForm.controls["central_peripheral"].updateValueAndValidity();
      } else {
        vim.chkcentralperipheral = true;
        vim.generalForm.patchValue({
          central_peripheral: ''
        })
        vim.generalForm.controls["central_peripheral"].setValidators([Validators.required]);
        vim.generalForm.controls["central_peripheral"].updateValueAndValidity();
      }
    }
      
    if (target.name == 'babyhypoxia') {
      if (target.value == '2') {
        vim.chkhypoxia = false;
        vim.generalForm.patchValue({
          hypoxia: 'NA'
        })
        vim.generalForm.value["hypoxia"] = 'NA';
        vim.generalForm.controls["hypoxia"].clearValidators();
        vim.generalForm.controls["hypoxia"].updateValueAndValidity();
      } else {
        vim.chkhypoxia = true;
        vim.generalForm.patchValue({
          hypoxia: ''
        })
        vim.generalForm.controls["hypoxia"].setValidators([Validators.required]);
        vim.generalForm.controls["hypoxia"].updateValueAndValidity();
      }
    }
      
    if (target.name == 'babymetabolicacidosis') {
      if (target.value == '2') {
        vim.chkmetabolicacidosis = false;
        vim.generalForm.patchValue({
          metabolic_acidosis: 'NA'
        })
        vim.generalForm.value["metabolic_acidosis"] = 'NA';
        vim.generalForm.controls["metabolic_acidosis"].clearValidators();
        vim.generalForm.controls["metabolic_acidosis"].updateValueAndValidity();
      } else {
        vim.chkmetabolicacidosis = true;
        vim.generalForm.patchValue({
          metabolic_acidosis: ''
        })
        vim.generalForm.controls["metabolic_acidosis"].setValidators([Validators.required]);
        vim.generalForm.controls["metabolic_acidosis"].updateValueAndValidity();
      }
    }

    if (target.name == 'babyDateAdmission') {
      if (target.value == '2') {
        vim.chkBabyDateAdmission = false;
        vim.generalForm.patchValue({
          baby_date_of_admission: 'NA'
        })
      } else {
        vim.chkBabyDateAdmission = true;
        vim.generalForm.patchValue({
          baby_date_of_admission: ''
        })
      }
    }

    if (target.name == 'babyCondOnSuspectOtherIfAny') {
      if (target.value == '2') {
        vim.chkBabyCondOnSuspectOtherIfAny = false;
        vim.generalForm.patchValue({
          baby_condition_other_if_suspect: 'NA'
        })
        vim.generalForm.value["baby_condition_other_if_suspect"] = 'NA';
        vim.generalForm.controls["baby_condition_other_if_suspect"].clearValidators();
        vim.generalForm.controls["baby_condition_other_if_suspect"].updateValueAndValidity();
      } else {
        vim.chkBabyCondOnSuspectOtherIfAny = true;
        vim.generalForm.patchValue({
          baby_condition_other_if_suspect: ''
        })
        vim.generalForm.controls["baby_condition_other_if_suspect"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_condition_other_if_suspect"].updateValueAndValidity();
      }
    }

    if (target.name == 'motherMRNo') {
      if (target.value == '2') {
        vim.motherMRNo = false;
        vim.generalForm.patchValue({
          babyMotherMedicalRecord: 'NA'
        })
        vim.generalForm.value["babyMotherMedicalRecord"] = 'NA';
        vim.generalForm.controls["babyMotherMedicalRecord"].clearValidators();
        vim.generalForm.controls["babyMotherMedicalRecord"].updateValueAndValidity();
      } else {
        vim.motherMRNo = true;
        vim.generalForm.patchValue({
          babyMotherMedicalRecord: ''
        })
        vim.generalForm.controls["babyMotherMedicalRecord"].setValidators([Validators.required]);
        vim.generalForm.controls["babyMotherMedicalRecord"].updateValueAndValidity();
      }
    }

    if (target.name == 'babyName') {
      if (target.value == '2') {
        vim.chkBabyName = false;
        vim.generalForm.patchValue({
          baby_name: 'NA'
        })
        vim.generalForm.value["baby_name"] = 'NA';
        vim.generalForm.controls["baby_name"].clearValidators();
        vim.generalForm.controls["baby_name"].updateValueAndValidity();
      } else {
        vim.chkBabyName = true;
        vim.generalForm.patchValue({
          baby_name: ''
        })
        vim.generalForm.controls["baby_name"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_name"].updateValueAndValidity();
      }
    }

    if (target.name == 'motherName') {
      if (target.value == '2') {
        vim.chkMotherName = false;
        vim.generalForm.patchValue({
          baby_mother_name: 'NA'
        })
        vim.generalForm.value["baby_mother_name"] = 'NA';
        vim.generalForm.controls["baby_mother_name"].clearValidators();
        vim.generalForm.controls["baby_mother_name"].updateValueAndValidity();
      } else {
        vim.chkMotherName = true;
        vim.generalForm.patchValue({
          baby_mother_name: ''
        })
        vim.generalForm.controls["baby_mother_name"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_mother_name"].updateValueAndValidity();
      }
    }

  }

  open(content, obj) {
    const vim = this;
    vim.submitted = false;
    if (!_.isEmpty(obj)) {
      this.isBabyEditGeneral = true;
      this.isBabyCreateGeneral = false;
      this.is_update = true;
      vim.createForm(vim.id);
      vim.updateForm(obj);
      this.updateFlag=true;
    } else {
      this.isBabyEditGeneral = true;
      this.isBabyCreateGeneral = true;
      this.is_update = false;
      vim.createForm(vim.id);
      this.showMrNumber=false;
    }
    this.setExtraValidators();
  }

  setExtraValidators(){
    if(this.login_hospital['user_type']==this.appConstant.asha_worker || this.login_hospital['user_type']==this.appConstant.phc_worker){
      this.generalForm.controls["baby_name"].setValidators([Validators.required]);
        this.generalForm.controls["baby_name"].updateValueAndValidity();
        this.generalForm.controls["baby_mother_name"].setValidators([Validators.required]);
        this.generalForm.controls["baby_mother_name"].updateValueAndValidity();
        this.generalForm.controls["birth_facility"].setValidators([Validators.required]);
        this.generalForm.controls["birth_facility"].updateValueAndValidity();
    }
  }
  close() {

  }

  reset() {
    this.createForm(null);
  }

  babyProfileFormSubmit() {
    var local_data_info = localStorage.getItem("login_hospital");
    let a = JSON.parse(local_data_info);
    this.generalForm.value['hospital_id'] = a.id;
    this.hospital_id = this.generalForm.value['hospital_id']
    // alert(this.hospital_id); return false;
    const vim = this;
    vim.getMedicalRecordNumber=this.generalForm.value['babyMedicalRecord'];
    vim.transformDate(vim.generalForm.value);
    console.log(vim.generalForm.value)
    vim.submitted = true;
    vim.findInvalidControls();
    if(this.ashaUser){
      this.setDefaultControlsForAshaWorker();
    }
    debugger;
    console.log(vim.generalForm.controls)
    if (vim.generalForm.invalid) {
      return;
    }

    if (this.generalForm.value["baby_place_of_birth_pin_code"] == '') {
      this.generalForm.value["baby_place_of_birth_pin_code"] = 'NA';
    }

    if (this.generalForm.value["baby_place_of_birth_name"] == '') {
      this.generalForm.value["baby_place_of_birth_name"] = 'NA';
    }

    if (this.generalForm.value["baby_age_of_admission"] == '') {
      this.generalForm.value["baby_age_of_admission"] = 'NA';
    }

    if (this.generalForm.value["baby_apgar_score_one_min"] == '') {
      this.generalForm.value["baby_apgar_score_one_min"] = 'NA';
    }

    if (this.generalForm.value["baby_apgar_score_five_min"] == '') {
      this.generalForm.value["baby_apgar_score_five_min"] = 'NA';
    }

    if (this.generalForm.value["baby_apgar_score_ten_min"] == '') {
      this.generalForm.value["baby_apgar_score_ten_min"] = 'NA';
    }

    if (this.generalForm.value["place_of_delivery"] == '') {
      this.generalForm.value["place_of_delivery"] = 'NA';
    }

    if (this.generalForm.value["baby_gestational_age"] == '') {
      this.generalForm.value["baby_gestational_age"] = 'NA';
    }

    if (this.generalForm.value["baby_day_of_event"] == '') {
      this.generalForm.value["baby_day_of_event"] = 'NA';
    }

    if (this.generalForm.value["baby_condition_other_if_suspect"] == '') {
      this.generalForm.value["baby_condition_other_if_suspect"] = 'NA';
    }

    if (this.generalForm.value["baby_weight_at_birth"] == '') {
      this.generalForm.value["baby_weight_at_birth"] = 'NA';
    }

    if (this.generalForm.value["baby_weight_at_admission"] == '') {
      this.generalForm.value["baby_weight_at_admission"] = 'NA';
    }

    if (this.generalForm.value["baby_birth_time_hours"] == '') {
      this.generalForm.value["baby_birth_time_hours"] = 'NA';
    }

    if (this.generalForm.value["baby_birth_time_minit"] == '') {
      this.generalForm.value["baby_birth_time_minit"] = 'NA';
    }
    if (this.generalForm.value["babyMotherMedicalRecord"] == '') {
      this.generalForm.value["babyMotherMedicalRecord"] = 'NA';
    }
    if (this.generalForm.value["birth_facility"] == '') {
      this.generalForm.value["birth_facility"] = 'NA';
    }
    
    if (this.generalForm.value["baby_day_of_event"] == '') {
      this.generalForm.value["baby_day_of_event"] = 'NA';
    }
    if (this.generalForm.value["baby_condition_yes_eos_los"] == '') {
      this.generalForm.value["baby_condition_yes_eos_los"] = 'NA';
    }
    if (this.generalForm.value["meningitis"] == '') {
      this.generalForm.value["meningitis"] = 'NA';
    }
    if (this.generalForm.value["umblical_sepsis"] == '') {
      this.generalForm.value["umblical_sepsis"] = 'NA';
    }
    if (this.generalForm.value["skin_pustules"] == '') {
      this.generalForm.value["skin_pustules"] = 'NA';
    }
    if (this.generalForm.value["baby_condition_rds_yes_no"] == '') {
      this.generalForm.value["baby_condition_rds_yes_no"] = 'NA';
    }
    if (this.generalForm.value["baby_condition_jaundice_suspect"] == '') {
      this.generalForm.value["baby_condition_jaundice_suspect"] = 'NA';
    }
    if (this.generalForm.value["baby_condition_lbw_suspect"] == '') {
      this.generalForm.value["baby_condition_lbw_suspect"] = 'NA';
    }
    if (this.generalForm.value["baby_condition_ttnb_suspect"] == '') {
      this.generalForm.value["baby_condition_ttnb_suspect"] = 'NA';
    }
    if (this.generalForm.value["baby_lga_sga_aga_suspect"] == '') {
      this.generalForm.value["baby_lga_sga_aga_suspect"] = 'NA';
    }
    if (this.generalForm.value["baby_shock_aga_suspect"] == '') {
      this.generalForm.value["baby_shock_aga_suspect"] = 'NA';
    }
    if (this.generalForm.value["baby_condition_dextrocordia_suspect"] == '') {
      this.generalForm.value["baby_condition_dextrocordia_suspect"] = 'NA';
    }
    if (this.generalForm.value["baby_condition_anemia_suspect"] == '') {
      this.generalForm.value["baby_condition_anemia_suspect"] = 'NA';
    }
    if (this.generalForm.value["prelim_diagnosis_perinatal"] == '') {
      this.generalForm.value["prelim_diagnosis_perinatal"] = 'NA';
    }
    if (this.generalForm.value["prelim_diagnosis_hypoglycemia"] == '') {
      this.generalForm.value["prelim_diagnosis_hypoglycemia"] = 'NA';
    }
    if (this.generalForm.value["prelim_diagnosis_hypocalcemia"] == '') {
      this.generalForm.value["prelim_diagnosis_hypocalcemia"] = 'NA';
    }
    if (this.generalForm.value["prelim_diagnosis_feeding_intolerence"] == '') {
      this.generalForm.value["prelim_diagnosis_feeding_intolerence"] = 'NA';
    }
    if (this.generalForm.value["thrombocytopenia"] == '') {
      this.generalForm.value["thrombocytopenia"] = 'NA';
    }
    if (this.generalForm.value["seizures"] == '') {
      this.generalForm.value["seizures"] = 'NA';
    }
    if (this.generalForm.value["prelim_diagnosis_gastroenteritis"] == '') {
      this.generalForm.value["prelim_diagnosis_gastroenteritis"] = 'NA';
    }
    if (this.generalForm.value["asphyxia"] == '') {
      this.generalForm.value["asphyxia"] = 'NA';
    }
    if (this.generalForm.value["pneumonia"] == '') {
      this.generalForm.value["pneumonia"] = 'NA';
    }
    if (this.generalForm.value["septic_arthritis"] == '') {
      this.generalForm.value["septic_arthritis"] = 'NA';
    }
    if (this.generalForm.value["endocarditis"] == '') {
      this.generalForm.value["endocarditis"] = 'NA';
    }
    if (this.generalForm.value["peritonitis"] == '') {
      this.generalForm.value["peritonitis"] = 'NA';
    }
    if (this.generalForm.value["soft_tissue_abscess"] == '') {
      this.generalForm.value["soft_tissue_abscess"] = 'NA';
    }
    if (this.generalForm.value["coagulopathy"] == '') {
      this.generalForm.value["coagulopathy"] = 'NA';
    }
    if (this.generalForm.value["uti"] == '') {
      this.generalForm.value["uti"] = 'NA';
    }
    if (this.generalForm.value["pulmonary_hemorrhage"] == '') {
      this.generalForm.value["pulmonary_hemorrhage"] = 'NA';
    }
    if (this.generalForm.value["bleeding_manifestation"] == '') {
      this.generalForm.value["bleeding_manifestation"] = 'NA';
    }
    if (this.generalForm.value["central_peripheral"] == '') {
      this.generalForm.value["central_peripheral"] = 'NA';
    }
    if (this.generalForm.value["hypoxia"] == '') {
      this.generalForm.value["hypoxia"] = 'NA';
    }
    if (this.generalForm.value["metabolic_acidosis"] == '') {
      this.generalForm.value["metabolic_acidosis"] = 'NA';
    }

    if(this.ashaUser){
      if (this.generalForm.value["baby_name"] == '') {
        this.generalForm.value["baby_name"] = 'NA';
      }
      if (this.generalForm.value["baby_mother_name"] == '') {
        this.generalForm.value["baby_mother_name"] = 'NA';
      }
    }

    this.generalForm.value["is_update"] = vim.is_update;

    vim.commonAsyn.showLoader();
    vim.generalForm.value["tab_name"] = "genral";
    vim.generalForm.value["isCreateForm"] = vim.isBabyCreateGeneral;
    if(vim.checkAshaPHCUser()){
          vim.addAshaPHCPatient();
    }else{
      vim.addHospitalPatient();
    }
  }


  setDefaultControlsForAshaWorker(){
    this.generalForm.patchValue({
      baby_birth_date:this.generalForm['value']['baby_birth_date'],
      baby_date_of_admission:this.generalForm['value']['baby_date_of_admission'],
      prelim_diagnosis_gastroenteritis:'NA',
    prelim_diagnosis_feeding_intolerence:'NA',
    prelim_diagnosis_hypocalcemia:'NA',
    prelim_diagnosis_perinatal:'NA',
    baby_condition_other_if_suspect:'NA',
    baby_day_of_event:'NA',
    baby_condition_suspect:'NA',

    baby_condition_lbw_suspect:'NA',
    baby_condition_anemia_suspect:'NA',
    baby_condition_dextrocordia_suspect:'NA',
    baby_shock_aga_suspect:'NA',
    // baby_condition_sga_suspect:'NA',
    // baby_condition_aga_suspect:'NA',


    // baby_condition_lga_suspect:'NA',
    baby_condition_ttnb_suspect:'NA',
    baby_condition_jaundice_suspect:'NA',
    //baby_gender:'NA',
    baby_condition_rds_yes_no:'NA',
    baby_condition_yes_eos_los:'NA',
    baby_apgar_score_ten_min:'NA',

    baby_apgar_score_five_min:'NA',
    baby_apgar_score_one_min:'NA',
    baby_place_of_birth_pin_code:'NA',
    place_of_delivery:'NA',
    record_type:'NA',

    prelim_diagnosis_hypoglycemia:'NA',
    meningitis:'NA',
    asphyxia:'NA',
    septic_arthritis:'NA',
    endocarditis:'NA',
    peritonitis:'NA',
    soft_tissue_abscess:'NA',
    coagulopathy:'NA',
    uti:'NA',
    umblical_sepsis:'NA',
    bleeding_manifestation:'NA',
    central_peripheral:'NA',
    hypoxia:'NA',
    metabolic_acidosis:'NA',
    skin_pustules:'NA',
    seizures:'NA',
    pulmonary_hemorrhage:'NA',
    thrombocytopenia:'NA',
    baby_lga_sga_aga_suspect:'NA',
    pneumonia:'NA'
    })
      this.generalForm.controls["baby_place_of_birth_pin_code"].clearValidators();
     this.generalForm.controls["baby_place_of_birth_pin_code"].updateValueAndValidity();
    // this.generalForm.get('baby_birth_date').setValue(this.generalForm['value']['baby_birth_date']);
    // this.generalForm.get('baby_date_of_admission').setValue(this.generalForm['value']['baby_date_of_admission']);
    // this.generalForm.get('prelim_diagnosis_gastroenteritis').setValue('NA');
    // this.generalForm.get('prelim_diagnosis_feeding_intolerence').setValue('NA');
    // this.generalForm.get('prelim_diagnosis_hypocalcemia').setValue('NA');
    // this.generalForm.get('prelim_diagnosis_perinatal').setValue('NA');
    // this.generalForm.get('baby_condition_other_if_suspect').setValue('NA');
    // this.generalForm.get('baby_day_of_event').setValue('NA');
    // this.generalForm.get('baby_condition_suspect').setValue('NA');

    // this.generalForm.get('baby_condition_lbw_suspect').setValue('NA');
    // this.generalForm.get('baby_condition_anemia_suspect').setValue('NA');
    // this.generalForm.get('baby_condition_dextrocordia_suspect').setValue('NA');
    // this.generalForm.get('baby_shock_aga_suspect').setValue('NA');
    // this.generalForm.get('baby_condition_sga_suspect').setValue('NA');
    // this.generalForm.get('baby_condition_aga_suspect').setValue('NA');


    // this.generalForm.get('baby_condition_lga_suspect').setValue('NA');
    // this.generalForm.get('baby_condition_ttnb_suspect').setValue('NA');
    // this.generalForm.get('baby_condition_jaundice_suspect').setValue('NA');
    // this.generalForm.get('baby_gender').setValue('NA');
    // this.generalForm.get('baby_condition_rds_yes_no').setValue('NA');
    // this.generalForm.get('baby_condition_yes_eos_los').setValue('NA');
    // this.generalForm.get('baby_apgar_score_ten_min').setValue('NA');

    // this.generalForm.get('baby_apgar_score_five_min').setValue('NA');
    // this.generalForm.get('baby_apgar_score_one_min').setValue('NA');
    // this.generalForm.get('baby_place_of_birth_pin_code').setValue('NA');
    // this.generalForm.get('place_of_delivery').setValue('NA');
    // this.generalForm.get('record_type').setValue('NA');
    // this.generalForm.controls["baby_place_of_birth_pin_code"].clearValidators();
    // this.generalForm.controls["baby_place_of_birth_pin_code"].updateValueAndValidity();
    // this.generalForm.get('baby_place_of_birth_pin_code').setValue('NA');
    // this.generalForm.get('prelim_diagnosis_hypoglycemia').setValue('NA');
    
    

  }
  /**
   *
   * @param response
   * @param api_type
   * @method: success
   * @purpose :-  it is a common helper
   */
  success(response, api_type) {
    const vim = this;
    if (api_type == "BabyProfileFormSubmit") {
      if (vim.isSuccess(response)) {
        vim.responseArray = [];
        this.page = 1;
        vim.toastr.success(
          "",
          "Data Inserted Succesfully"
        );
        vim.is_api_call = true;
        vim.id = response['response'].study_id;
        vim.dataServiceObj["study_id"] = response["response"].study_id;
        vim.dataServiceObj["baby_medical_record_number"] = vim.getMedicalRecordNumber;
        vim.dataService.setOption(vim.dataServiceObj);
        vim.showMrNumber=true;
        vim.get_general(vim.dataServiceObj.study_id, this.hospital_id, vim.page);
      } else {
        if (vim.isAlreadyExist(response)) {
          vim.toastr.warning("Already Exist!!", response["message"]);
        } else {
          vim.errorToasty(response);
        }
      }
    } else if (api_type == "get_general") {

      if (vim.isSuccess(response)) {

        if (this.page == 1 && response['status']==200) {

          vim.responseArray = [];
          vim.responseArray = response["response"];
          if(this.ashaUser){
            vim.readingDataService.setAshaWorkerActiveStatus('baby-profile');
            }

        } else {
          if (response["status"] == 404) {
            // vim.responseArray = [];
            vim.is_api_call = false;
            vim.open(this.content,{});
            // localStorage.setItem('reading','R1');
          }
          else if (response["response"].length > 0) {
            vim.temp_study_id = response["response"][0].study_id;
            if (vim.temp_study_id == vim.id) {
            } else {
              vim.responseArray = [];
            }

            for (var i = 0; i < response["response"].length; i++) {
              vim.responseArray.push(response["response"][i]);
              vim.temp_study_id = vim.id;
            }
          }
        }
        vim.commonAsyn.isHide();
      } else {
        vim.responseArray = [];
        vim.commonAsyn.isHide();
        if (vim.isAlreadyExist(response)) {
        } else {
        }
      }
    }
  }

  changeOptions(event) {
    if (this.generalForm.controls["baby_gestational_age"].value > 36) {
      this.pretermArr = ['No', 'NA'];
      this.generalForm.controls["baby_preterm"].setValidators([Validators.required]);
      this.generalForm.controls["baby_preterm"].updateValueAndValidity();
      this.generalForm.controls["baby_preterm"].setValue(this.pretermArr[0]);
    } if(this.generalForm.controls["baby_gestational_age"].value < 37) {
      this.pretermArr = ['Yes', 'NA'];
      this.generalForm.controls["baby_preterm"].setValidators([Validators.required]);
      this.generalForm.controls["baby_preterm"].updateValueAndValidity();
      this.generalForm.controls["baby_preterm"].setValue(this.pretermArr[0]);
    } 
    if (this.generalForm.controls["baby_gestational_age"].value == '') {
      this.pretermArr = ['Yes', 'No', 'NA'];
      this.generalForm.controls["baby_preterm"].setValidators([Validators.required]);
      this.generalForm.controls["baby_preterm"].updateValueAndValidity();
      this.generalForm.controls["baby_preterm"].setValue('');
    }
  }


  /**
   *
   * @param error
   * @param api_type
   * @purpose :-  This is error handler method is called.
   * @method: errorHandler
   */
  errorHandler(error, api_type) {
    const vim = this;
    vim.commonAsyn.isHide();
    if (api_type == "BabyProfileFormSubmit") {
      vim.errorToasty(error);
    }
  }

  /**
   *
   * @param response
   * @method: it is a common herlper for check the status is 200 or not
   */
  isSuccess(response) {
    const vim = this;
    if (
      response.hasOwnProperty("status") &&
      response["status"] === vim.success_status
    ) {
      return true;
    } else if (response["status"] === 404) {
      return true;
    }
    return false;
  }
  /**
   *
   * @param response
   * @method :- isAlreadyExist
   * @purpose :- check if User Already Exist.
   */
  isAlreadyExist(response) {
    const vim = this;
    if (
      response.hasOwnProperty("status") &&
      response["status"] === vim.already_exist_status
    ) {
      return true;
    }
    return false;
  }
  /**
   * @method :- errorToasty
   */
  errorToasty(error) {
    const vim = this;
    vim.commonAsyn.isHide();
    if (error.hasOwnProperty("message")) {
      vim.toastr.error("Error!", error["message"]);
    } else {
      vim.toastr.error("Error!", "Somethink wrong!!!..");
    }
  }

  get_general(id, hospital_id, page) {
    const vim = this;
    if (vim.temp_study_id == vim.id) {

    } else {
      vim.page = 1;
      vim.temp_study_id = vim.id;
      // vim.responseArray = [];
    }
    const newdata = vim.common_api.get_tabs_general("patient/get_general", id, hospital_id, page);
    newdata.subscribe(
      response => {
        if (response["response"].length > 0) {
          vim.dataServiceObj["baby_date_of_admission"] = response["response"][0].baby_date_of_admission;
        vim.dataService.setOption(vim.dataServiceObj);
        }
        vim.success(response, "get_general");
      },
      error => {
        console.error("errro", error);
      }
    );
  }

  changeDropdown(dropdownVal, dropdownId) {
    var vim = this;

    if (dropdownId == 'babyWeightAtBirthId') {
      if (dropdownVal == 'NA') {
        vim.chkWeightAtBirth = false;
        vim.generalForm.patchValue({
          baby_weight_at_birth: 'NA'
        });

        vim.generalForm.value["baby_weight_at_birth"] = 'NA';
        vim.generalForm.controls["baby_weight_at_birth"].clearValidators();
        vim.generalForm.controls["baby_weight_at_birth"].updateValueAndValidity();
      } else {
        vim.chkWeightAtBirth = true;
        vim.generalForm.patchValue({
          baby_weight_at_birth: ''
        })
        vim.generalForm.controls["baby_weight_at_birth"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_weight_at_birth"].updateValueAndValidity();
      }
    }

    if (dropdownId == 'baby_weight_at_admission') {
      if (dropdownVal == 'NA') {
        vim.chkWeightAtAdmission = false;
        vim.generalForm.patchValue({
          baby_weight_at_admission: 'NA'
        });
        vim.generalForm.value["baby_weight_at_admission"] = 'NA';

        vim.generalForm.controls["baby_weight_at_admission"].clearValidators();
        vim.generalForm.controls["baby_weight_at_admission"].updateValueAndValidity();
      } else {
        vim.chkWeightAtAdmission = true;
        vim.generalForm.patchValue({
          baby_weight_at_admission: ''
        })
        vim.generalForm.controls["baby_weight_at_admission"].setValidators([Validators.required]);
        vim.generalForm.controls["baby_weight_at_admission"].updateValueAndValidity();
      }
    }
  }

  updateGeneralForm(){
    var local_data_info = localStorage.getItem("login_hospital");
    let a = JSON.parse(local_data_info);
    this.generalForm.value['hospital_id'] = a.id;
    this.hospital_id = this.generalForm.value['hospital_id']
    //alert(this.hospital_id); return false;
    const vim = this;
    vim.getMedicalRecordNumber=this.generalForm.value['babyMedicalRecord'];
    vim.transformDate(vim.generalForm.value);
    vim.submitted = true;
    vim.findInvalidControls();
    if (vim.generalForm.invalid) {
      return;
    }

    if (this.generalForm.value["baby_place_of_birth_pin_code"] == '') {
      this.generalForm.value["baby_place_of_birth_pin_code"] = 'NA';
    }

    if (this.generalForm.value["baby_place_of_birth_name"] == '') {
      this.generalForm.value["baby_place_of_birth_name"] = 'NA';
    }

    if (this.generalForm.value["baby_age_of_admission"] == '') {
      this.generalForm.value["baby_age_of_admission"] = 'NA';
    }

    if (this.generalForm.value["baby_apgar_score_one_min"] == '') {
      this.generalForm.value["baby_apgar_score_one_min"] = 'NA';
    }

    if (this.generalForm.value["baby_apgar_score_five_min"] == '') {
      this.generalForm.value["baby_apgar_score_five_min"] = 'NA';
    }

    if (this.generalForm.value["baby_apgar_score_ten_min"] == '') {
      this.generalForm.value["baby_apgar_score_ten_min"] = 'NA';
    }
    
    if (this.generalForm.value["place_of_delivery"] == '') {
      this.generalForm.value["place_of_delivery"] = 'NA';
    }

    if (this.generalForm.value["baby_gestational_age"] == '') {
      this.generalForm.value["baby_gestational_age"] = 'NA';
    }

    if (this.generalForm.value["baby_day_of_event"] == '') {
      this.generalForm.value["baby_day_of_event"] = 'NA';
    }

    if (this.generalForm.value["baby_condition_other_if_suspect"] == '') {
      this.generalForm.value["baby_condition_other_if_suspect"] = 'NA';
    }

    if (this.generalForm.value["baby_weight_at_birth"] == '') {
      this.generalForm.value["baby_weight_at_birth"] = 'NA';
    }

    if (this.generalForm.value["baby_weight_at_admission"] == '') {
      this.generalForm.value["baby_weight_at_admission"] = 'NA';
    }

    if (this.generalForm.value["baby_birth_time_hours"] == '') {
      this.generalForm.value["baby_birth_time_hours"] = 'NA';
    }

    if (this.generalForm.value["baby_birth_time_minit"] == '') {
      this.generalForm.value["baby_birth_time_minit"] = 'NA';
    }

    if (this.generalForm.value["babyMotherMedicalRecord"] == '') {
      this.generalForm.value["babyMotherMedicalRecord"] = 'NA';
    }

    if (this.generalForm.value["baby_day_of_event"] == '') {
      this.generalForm.value["baby_day_of_event"] = 'NA';
    }
    
    if (this.generalForm.value["baby_condition_yes_eos_los"] == '') {
      this.generalForm.value["baby_condition_yes_eos_los"] = 'NA';
    }
    
    if (this.generalForm.value["meningitis"] == '') {
      this.generalForm.value["meningitis"] = 'NA';
    }

    if (this.generalForm.value["umblical_sepsis"] == '') {
      this.generalForm.value["umblical_sepsis"] = 'NA';
    }

    if (this.generalForm.value["skin_pustules"] == '') {
      this.generalForm.value["skin_pustules"] = 'NA';
    }

    if (this.generalForm.value["baby_condition_rds_yes_no"] == '') {
      this.generalForm.value["baby_condition_rds_yes_no"] = 'NA';
    }

    if (this.generalForm.value["baby_condition_jaundice_suspect"] == '') {
      this.generalForm.value["baby_condition_jaundice_suspect"] = 'NA';
    }

    if (this.generalForm.value["baby_condition_lbw_suspect"] == '') {
      this.generalForm.value["baby_condition_lbw_suspect"] = 'NA';
    }

    if (this.generalForm.value["baby_condition_ttnb_suspect"] == '') {
      this.generalForm.value["baby_condition_ttnb_suspect"] = 'NA';
    }

    if (this.generalForm.value["baby_lga_sga_aga_suspect"] == '') {
      this.generalForm.value["baby_lga_sga_aga_suspect"] = 'NA';
    }

    if (this.generalForm.value["baby_shock_aga_suspect"] == '') {
      this.generalForm.value["baby_shock_aga_suspect"] = 'NA';
    }

    if (this.generalForm.value["baby_condition_dextrocordia_suspect"] == '') {
      this.generalForm.value["baby_condition_dextrocordia_suspect"] = 'NA';
    }

    if (this.generalForm.value["baby_condition_anemia_suspect"] == '') {
      this.generalForm.value["baby_condition_anemia_suspect"] = 'NA';
    }

    if (this.generalForm.value["prelim_diagnosis_perinatal"] == '') {
      this.generalForm.value["prelim_diagnosis_perinatal"] = 'NA';
    }

    if (this.generalForm.value["prelim_diagnosis_hypoglycemia"] == '') {
      this.generalForm.value["prelim_diagnosis_hypoglycemia"] = 'NA';
    }

    if (this.generalForm.value["prelim_diagnosis_hypocalcemia"] == '') {
      this.generalForm.value["prelim_diagnosis_hypocalcemia"] = 'NA';
    }

    if (this.generalForm.value["prelim_diagnosis_feeding_intolerence"] == '') {
      this.generalForm.value["prelim_diagnosis_feeding_intolerence"] = 'NA';
    }

    if (this.generalForm.value["thrombocytopenia"] == '') {
      this.generalForm.value["thrombocytopenia"] = 'NA';
    }

    if (this.generalForm.value["seizures"] == '') {
      this.generalForm.value["seizures"] = 'NA';
    }

    if (this.generalForm.value["prelim_diagnosis_gastroenteritis"] == '') {
      this.generalForm.value["prelim_diagnosis_gastroenteritis"] = 'NA';
    }

    if (this.generalForm.value["asphyxia"] == '') {
      this.generalForm.value["asphyxia"] = 'NA';
    }

    if (this.generalForm.value["pneumonia"] == '') {
      this.generalForm.value["pneumonia"] = 'NA';
    }

    if (this.generalForm.value["septic_arthritis"] == '') {
      this.generalForm.value["septic_arthritis"] = 'NA';
    }

    if (this.generalForm.value["endocarditis"] == '') {
      this.generalForm.value["endocarditis"] = 'NA';
    }

    if (this.generalForm.value["peritonitis"] == '') {
      this.generalForm.value["peritonitis"] = 'NA';
    }

    if (this.generalForm.value["soft_tissue_abscess"] == '') {
      this.generalForm.value["soft_tissue_abscess"] = 'NA';
    }
    
    if (this.generalForm.value["coagulopathy"] == '') {
      this.generalForm.value["coagulopathy"] = 'NA';
    }

    if (this.generalForm.value["uti"] == '') {
      this.generalForm.value["uti"] = 'NA';
    }

    if (this.generalForm.value["pulmonary_hemorrhage"] == '') {
      this.generalForm.value["pulmonary_hemorrhage"] = 'NA';
    }
    
    if (this.generalForm.value["bleeding_manifestation"] == '') {
      this.generalForm.value["bleeding_manifestation"] = 'NA';
    }

    if (this.generalForm.value["central_peripheral"] == '') {
      this.generalForm.value["central_peripheral"] = 'NA';
    }

    if (this.generalForm.value["hypoxia"] == '') {
      this.generalForm.value["hypoxia"] = 'NA';
    }

    if (this.generalForm.value["metabolic_acidosis"] == '') {
      this.generalForm.value["metabolic_acidosis"] = 'NA';
    }

    this.generalForm.value["is_update"] = vim.is_update;

    vim.commonAsyn.showLoader();
    vim.generalForm.value["tab_name"] = "genral";
    vim.generalForm.value["isCreateForm"] = vim.isBabyCreateGeneral;
      
    
        this.common_api.updateBabyProfile(this.studyId,this.generalForm['value'],this.loggedInUserId).subscribe(result=>{
         
          if(result['status']!=200){
              this.toastr.error('',result['message']);
          }
          else{
            this.toastr.success('',result['message']);
            this.updateFlag=false;
            this.isBabyEditGeneral = false;
            this.get_general(vim.dataServiceObj.study_id,  vim.login_hospital['id'], vim.page);
          }
        })
    
  }

  checkAshaPHCUser(){
    if(this.login_hospital['user_type']== this.appConstant.asha_worker || this.login_hospital['user_type']== this.appConstant.phc_worker){
      return true;
    }else{
      return false;
    }
  }

  calculateAgeAtAdmission(){
    debugger;
    if(this.generalForm['value']['baby_birth_date']!="NA" && this.generalForm['value']['baby_birth_date']!="" && this.generalForm['value']['baby_date_of_admission']!="NA" && this.generalForm['value']['baby_date_of_admission']!="" ){
      this.convertDateFormat(this.generalForm['value']);
      var date1=new Date(this.generalForm['value']['baby_birth_date']);
      var date2=new Date(this.generalForm['value']['baby_date_of_admission']);
      if(date2<date1){
              this.toastr.error("Date of admission cannot be less than Date of Birth");
              this.generalForm['value']['baby_date_of_admission']='';
              this.generalForm.patchValue({
                baby_date_of_admission: '',
                baby_age_of_admission:''
              })
              return ;
      }else{
      var timeDifference = Math.abs(date1.getTime() - date2.getTime()) / 1000;
         var days = Math.floor(timeDifference / 86400);
         this.generalForm.patchValue({
          baby_age_of_admission: days
        })
    }
  }

  }

  addAshaPHCPatient(){
    this.createPatient['hospital_type_id']=this.login_hospital['user_type_id'];
    this.createPatient['baby_medical_record_number']=this.generalForm.value['babyMedicalRecord'];
    this.createPatient['baby_mother_medical_record_number']=this.generalForm.value['babyMotherMedicalRecord'];
    this.createPatient['baby_name']=this.generalForm.value['baby_name'];
    this.createPatient['baby_mother_name']=this.generalForm.value['baby_mother_name'];
    this.createPatient['hospital_id']=this.login_hospital['hospital_id'];
    this.createPatient['hospitalType']=this.login_hospital['user_type_id'];

    this.common_api.createAshaPatient(this.createPatient).subscribe(response=>{
      if(this.helper.success(response)){
        this.generalForm['value']['study_id']=response['response']['id'];
        this.readingDataService.setAshaWorkerActiveStatus('babyProfile');
        this.addHospitalPatient();

      }else{
        this.commonAsyn.isHide();
        this.helper.errorHandler(response);
      }
    })
  }

  addHospitalPatient(){
    let vim=this;
    const newUser = vim.common_api.patient_general_info_updated(
      vim.generalForm.value,vim.loggedInUserId
    );
    // const newUser = vim.common_api.patient_general_info_updated(
    //   vim.generalForm.value
    // );
    newUser.subscribe(
      response => {
        if(response['status']!=200){
          vim.toastr.error('',response["message"])
          vim.commonAsyn.isHide();
        }
        else{
        vim.reset();
        vim.success(response, "BabyProfileFormSubmit");
        vim.isBabyEditGeneral = false;
        vim.isBabyCreateGeneral = false;
        vim.readingDataService.setComponentFlag('mother-profile')
        vim.readingDataService.showBabyProfileForm("message");
        vim.readingDataService.setActiveTab("mother-profile")
        vim.router.navigate(["dashboard/mother-profile"]);
        }
      },
      error => {
        console.error("errro", error);
      }
    );
  }

  convertDateFormat(formValue){
    if(typeof formValue['baby_birth_date'] =='string'){
      formValue['baby_birth_date']= this.datePipe.transform(formValue['baby_birth_date'], 'dd/MM/yyyy');
    }
    if(typeof formValue['baby_date_of_admission']=='string'){
      formValue['baby_date_of_admission']= this.datePipe.transform(formValue['baby_date_of_admission'], 'dd/MM/yyyy');
    }
  }

 birthDateChanged(){
    this.generalForm.controls['baby_date_of_admission'].setValue('');
  }
}

